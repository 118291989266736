import { Time } from '@augusthealth/models/com/august/protos/date'
import { isNaN } from 'lodash'
import { useContext, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { GroupedOptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  buildExactTimeOptionsGroup,
  buildShiftOptionsGroup,
  TimeSelect,
} from '@shared/components/Selects/TimeSelect'
import { VerticalRoundedBar } from '@shared/components/VerticalRoundedBar'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { ShiftId } from '@shared/types/shift'
import { getOrElse } from '@shared/utils/loading'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'
import { getNumberOfTimeInputsToShow } from './helpers'
import {
  RoutineOrderFormData,
  RoutineOrderFormTiming,
  UNSELECTED_TIMING_OPTION,
} from './RoutineOrderForm'

type Props = {
  index: number
  readOnly: boolean
}

export const RoutineTimesOfDay = ({
  index: scheduleIndex,
  readOnly,
}: Props) => {
  const {
    settings: { use24HourClock },
    shifts,
  } = useContext(CurrentFacilityContext)
  const { control, watch, getValues } = useFormContext<RoutineOrderFormData>()

  const timeAndShiftOptions: GroupedOptionTypeBase<Time | ShiftId>[] = [
    buildShiftOptionsGroup(getOrElse(shifts, []), { use24HourClock }),
    buildExactTimeOptionsGroup({ use24HourClock }),
  ]

  const { fields, replace } = useFieldArray({
    name: `schedules.${scheduleIndex}.timeOfDay`,
    control,
  })

  const periodChoice = watch(`schedules.${scheduleIndex}.periodChoice`)
  const frequencyCount = watch(`schedules.${scheduleIndex}.frequency`)
  const periodUnit = watch(`schedules.${scheduleIndex}.periodUnit`)
  const period = watch(`schedules.${scheduleIndex}.period`)

  const numberOfTimesToShow = getNumberOfTimeInputsToShow({
    frequencyCount,
    periodChoice,
    periodUnit,
    period,
  })

  useEffect(() => {
    const currentValues =
      getValues(`schedules.${scheduleIndex}.timeOfDay`) || []

    if (isNaN(numberOfTimesToShow) || numberOfTimesToShow === 0) {
      replace([UNSELECTED_TIMING_OPTION])
    } else if (numberOfTimesToShow > currentValues.length) {
      replace([
        ...currentValues,
        ...Array<RoutineOrderFormTiming>(
          numberOfTimesToShow - currentValues.length
        ).fill(UNSELECTED_TIMING_OPTION),
      ])
    } else if (numberOfTimesToShow < currentValues.length) {
      replace(currentValues.slice(0, numberOfTimesToShow))
    }
  }, [numberOfTimesToShow])

  if (!frequencyCount) {
    return null
  }

  return (
    <div className={styles.time} data-testid={'time-selects'}>
      <VerticalRoundedBar />
      <div className={styles.timeInputContainer}>
        {fields.map((field, timeIndex) => {
          return (
            <div key={field.id} className={'flex h-fit flex-row items-center'}>
              <Controller
                control={control}
                name={`schedules.${scheduleIndex}.timeOfDay.${timeIndex}`}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const timeSelectId = `schedule-${scheduleIndex + 1}-time-${timeIndex + 1}`
                  return (
                    <TimeSelect
                      label={`Time Select ${timeIndex + 1}`}
                      id={timeSelectId}
                      name={timeSelectId}
                      options={timeAndShiftOptions}
                      onChange={onChange}
                      disabled={readOnly}
                      value={value}
                      defaultValue={UNSELECTED_TIMING_OPTION}
                      showErrorBorder={!!error}
                    />
                  )
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
