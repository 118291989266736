import { Control, Controller } from 'react-hook-form'
import { components, SingleValueProps } from 'react-select'
import Icon from '@shared/components/Icon'
import { Label } from '@shared/components/Labels'
import { XLDropdownIndicator } from '@shared/components/Selects/Components'
import StyledSelect, {
  OptionTypeBase,
  SelectComponent,
} from '@shared/components/Selects/StyledSelect'
import { MedOrderFormData } from '@shared/types/medication_order'
import { VitalType } from '@shared/types/vital'
import notEmpty from '@shared/utils/notEmpty'
import { tw } from '@shared/utils/tailwind'
import {
  VitalEnumToVitalsType,
  VITALS_INPUT_PROPS_MAP,
  VitalsInputData,
} from '@shared/utils/vitals'
import { MedicationSearchStyles } from '@app/components/Residents/Medications/MedicationSearch/helpers'

const VITAL_OPTIONS: OptionTypeBase<
  VitalType,
  VitalsInputData<MedOrderFormData>
>[] = Object.values(VitalType)
  .filter(
    (vital) =>
      vital !== VitalType.UNRECOGNIZED &&
      vital !== VitalType.VITAL_TYPE_UNSPECIFIED
  )
  .map((vital) => {
    const vitalType = vital && VitalEnumToVitalsType(vital)
    const vitalProps: VitalsInputData<MedOrderFormData> | null =
      vitalType && VITALS_INPUT_PROPS_MAP(vitalType)

    if (!vitalProps) {
      return null
    }
    return {
      label: vitalProps.label,
      value: vital,
      data: vitalProps,
    }
  })
  .filter(notEmpty)

const SingleValueComponent = ({
  children,
  ...props
}: SingleValueProps<
  OptionTypeBase<VitalType, VitalsInputData<MedOrderFormData>>
>) => {
  return (
    // @ts-expect-error
    <components.SingleValue {...props}>
      {props.data.label}
    </components.SingleValue>
  )
}

type Props = {
  control: Control<MedOrderFormData>
  disabled: boolean
}
export const VitalsDropdown = ({ control, disabled }: Props) => {
  return (
    <Controller
      control={control}
      name={'vitalType'}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <Label htmlFor="vitalType" visuallyHidden>
              Vital Type
            </Label>
            <StyledSelect
              id="vitalType"
              instanceId="vitalType"
              openMenuOnFocus={true}
              placeholder="Select vital..."
              name="vitalType"
              value={VITAL_OPTIONS.find((opt) => opt?.value === value) ?? null}
              options={VITAL_OPTIONS}
              isClearable
              isDisabled={disabled}
              styles={MedicationSearchStyles(!!error)}
              components={{
                DropdownIndicator: XLDropdownIndicator as SelectComponent,
                SingleValue: SingleValueComponent as SelectComponent,
              }}
              onChange={(e: OptionTypeBase<VitalType> | null) => {
                if (e) {
                  onChange(e.value)
                } else {
                  onChange(null)
                }
              }}
              formatOptionLabel={(
                option: OptionTypeBase<VitalType, Record<string, string>>
              ) => {
                return (
                  <span className={tw`flex flex-row items-center gap-3`}>
                    <Icon
                      name={option.data?.iconClassName as string}
                      className={tw`text-gray-08`}
                    />
                    <span className={tw`font-medium text-secondary-04`}>
                      {option.label}
                    </span>
                  </span>
                )
              }}
            />
          </>
        )
      }}
    />
  )
}
