import { useEffect } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { useFacilities } from '@app/hooks/useFacilities'

type Props = {
  prepopulateWithDefaults?: boolean
  orgId?: string
  facilityLabel?: string
  selectClassName?: string
  selectedFacility: OptionTypeBase<Facility> | null
  onFacilitySelect: (org: OptionTypeBase<Facility>) => void
}

export const BaseFacilitySelect = ({
  prepopulateWithDefaults = false,
  orgId,
  selectClassName,
  onFacilitySelect,
  facilityLabel,

  selectedFacility,
}: Props) => {
  const { facilities } = useFacilities({ orgId }, [orgId])

  useEffect(() => {
    if (facilities.tag === 'Complete' && prepopulateWithDefaults) {
      const firstFacility = facilities.value[0]

      if (firstFacility) {
        const facilityToSave = {
          label: firstFacility.name!,
          value: firstFacility,
        }
        onFacilitySelect(facilityToSave)
      }
    }
  }, [facilities, prepopulateWithDefaults])

  return (
    <>
      {facilityLabel && (
        <LabelAboveInput htmlFor={'facilities'}>
          {facilityLabel}
        </LabelAboveInput>
      )}
      <StyledSelect
        isClearable
        placeholder="Select Facility..."
        isDisabled={!orgId || facilities?.tag === 'Loading'}
        options={
          facilities?.tag === 'Complete'
            ? facilities.value.map((f) => {
                return { label: f.name!, value: f }
              })
            : []
        }
        className={selectClassName}
        name={'facilities'}
        id={'facilities'}
        instanceId={'facilities'}
        onChange={(o: OptionTypeBase<Facility>) => {
          onFacilitySelect(o)
        }}
        value={selectedFacility}
      />
    </>
  )
}
