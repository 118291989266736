import { useEffect, useState } from 'react'
import { AsyncResult } from '@shared/utils/loading'
import { getFormImage } from '@app/api/form'

export default function useFormImage({
  signableFormId,
  key,
}: {
  signableFormId: string
  key: string
}) {
  const [imageData, setImageData] = useState<AsyncResult<string, any>>({
    tag: 'Loading',
  })

  useEffect(() => {
    getFormImage({
      signableFormId,
      key,
    })
      .then((image) => {
        setImageData({ tag: 'Complete', value: image })
      })
      .catch((error) => {
        setImageData({ tag: 'Error', value: error })
      })
  }, [signableFormId, key])

  return { imageData }
}
