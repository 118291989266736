import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { Facility } from '@shared/types/facility'
import { DeepNull } from '@shared/types/utilities'

const orgFacilitySettingsUrl = `${environment.baseUrl}/orgFacilitySettings`

export async function fetchSettingsByKey<T = OrgFacilitySettings>(
  orgId: string,
  facilityId: string,
  settingsType: SettingsType
): Promise<T[]> {
  const response = await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/settings/${settingsType}/byKey`,
  })

  return response.data
}

export type OrgFacilitySettingsHash = Record<string, OrgFacilitySettings[]>

type TaskFacilitySettingsResponse = {
  activeFacilities: Facility[]
  organizationId: string
  scheduledTaskDefinitions: {
    facilitySettings: OrgFacilitySettingsHash
    orgSettings: OrgFacilitySettings[]
    orgStateSettings: OrgFacilitySettingsHash
  }
  taskDefinitions: {
    inheritedFacilitySettings: OrgFacilitySettingsHash
    orgSettings: OrgFacilitySettings[]
    orgStateSettings: OrgFacilitySettingsHash
  }
}

export async function fetchTaskFacilitySettings(
  orgId: string
): Promise<TaskFacilitySettingsResponse> {
  const response = await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/taskFacilitySettings`,
  })

  return response.data
}

export async function upsertSettings(
  orgFacilitySettings: OrgFacilitySettings
): Promise<any> {
  return requestJson({
    url: orgFacilitySettingsUrl,
    method: 'POST',
    body: JSON.stringify(orgFacilitySettings),
  })
}

export function deleteOrgFacilitySetting(
  orgFacilitySettings: OrgFacilitySettings
): Promise<any> {
  return requestJson({
    url: orgFacilitySettingsUrl,
    method: 'DELETE',
    body: JSON.stringify(orgFacilitySettings),
  })
}

export async function fetchGlobalSettings({
  settingsType,
}: {
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}`,
    method: 'GET',
  })

  return res.data
}

export async function fetchStateSettings({
  state,
  settingsType,
}: {
  state: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&state=${state}`,
    method: 'GET',
  })

  return res.data
}

export async function fetchOrgSettings({
  orgId,
  settingsType,
}: {
  orgId: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}`,
    method: 'GET',
  })

  return res.data
}

export async function fetchOrgStateSettings({
  orgId,
  state,
  settingsType,
}: {
  orgId: string
  state: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}&state=${state}`,
    method: 'GET',
  })

  return res.data
}

export async function fetchFacilitySettings({
  orgId,
  facilityId,
  settingsType,
}: {
  orgId: string
  facilityId: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}&facilityId=${facilityId}`,
    method: 'GET',
  })

  return res.data
}

export const createFacilityLoCSettings = async (
  orgFacilitySettings: DeepNull<OrgFacilitySettings>
) => {
  const params = new URLSearchParams({
    settingsType: SettingsType.SETTINGS_TYPE_LEVEL_OF_CARE_SETTINGS,
  })
  if (orgFacilitySettings.orgId) {
    params.append('orgId', orgFacilitySettings.orgId)
  }
  if (orgFacilitySettings.facilityId) {
    params.append('facilityId', orgFacilitySettings.facilityId)
  }
  const res = await requestJson({
    method: 'POST',
    body: JSON.stringify(orgFacilitySettings),
    url: `${orgFacilitySettingsUrl}?${params}`,
  })

  return res.data
}
