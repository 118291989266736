import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Controller, useFormContext } from 'react-hook-form'
import { LabelAboveInput } from '@shared/components/Labels'
import { useHasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { getRoomNumber, isDischargedResident } from '@shared/utils/person'
import PrefilledRoomBed from '@app/components/RoomBedSelect/PrefilledSelect'

export default function Room({ person }: { person: Person }) {
  const { control } = useFormContext()
  const canEditRoom = useHasPermissionForPerson({
    permissions: [GroupPermission.GROUP_PERMISSION_OCCUPANCY_UPDATE],
    person,
  })
  let placeholder = 'Not assigned'
  if (!isDischargedResident(person)) {
    placeholder = getRoomNumber(person) ?? 'Not assigned'
  }

  return (
    <Controller
      control={control}
      name="bedId"
      render={({ field: { onChange, value } }) => (
        <div className="mb-[32px]">
          <LabelAboveInput htmlFor="bedId">Room / Bed</LabelAboveInput>
          {canEditRoom && (
            <PrefilledRoomBed
              orgId={person.orgId!}
              facilityId={person.facilityId!}
              menuPlacement="top"
              name="bedId"
              onChange={(opt: { value: string }) => onChange(opt?.value)}
              value={value}
            />
          )}
          {!canEditRoom && (
            <StyledSelect isDisabled={true} placeholder={placeholder} />
          )}
        </div>
      )}
    />
  )
}
