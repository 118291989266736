import { PersonMatcher } from '@augusthealth/models/com/august/protos/permission'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SetRequired } from 'type-fest'
import { fetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import SearchBox from '@shared/components/SearchBox'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { Page, pageToPath } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { getFacilityHash } from '@shared/utils/facilities'
import { getFullName } from '@shared/utils/humanName'
import styles from './styles.module.css'
import DropdownOption from './DropdownOption'
import { OptionProps } from './types'

interface Props {
  facilities: Facility[]
  closeModal: () => void
  currentPerson: Person
  personMatchers: PersonMatcher[]
  currentPage: Page
}

export default function ResidentSwitcher({
  facilities,
  closeModal,
  currentPerson,
  personMatchers,
  currentPage,
}: Props) {
  const history = useHistory()
  const facilitiesHash = getFacilityHash(facilities)
  const [isLoading, setIsLoading] = useState(true)
  const [people, setPeople] = useState<Person[]>([])
  const [selectedResident, setSelectedResident] = useState<OptionProps>({
    value: currentPerson,
    label: getFullName(currentPerson.name![0]),
    facility:
      (currentPerson.facilityId && facilitiesHash[currentPerson.facilityId]) ||
      undefined,
  })
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const peoplePromises = personMatchers.map((role) =>
      fetchPerson({
        facilityId: role.facilityId!,
        orgId: role.organizationId!,
        personId: role.personId!,
      })
    )

    void Promise.all(peoplePromises).then((results) => {
      setPeople(results)
      setIsLoading(false)
    })
  }, [])

  const residentOptions = people.reduce((opts: OptionProps[], person) => {
    const label = getFullName(person.name![0])
    const facility =
      (person.facilityId && facilitiesHash[person.facilityId]) || undefined
    const fName = facility && facility.name

    if (
      !searchText ||
      label.toLowerCase().includes(searchText.toLowerCase()) ||
      fName?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      opts.push({
        value: person,
        label,
        facility,
      })
    }

    return opts
  }, [])

  return (
    <div className={styles.container}>
      {isLoading && <SimpleSpinner />}
      {!isLoading && (
        <AnimatedPopup containerClassName={styles.modal} title={null}>
          <div className={styles.title}>Change Resident</div>
          <div className={styles.body}>
            <SearchBox
              onChange={setSearchText}
              holderClassName={styles.searchBar}
              placeholder="Resident name or community..."
              value={searchText}
            />
            <StyledSelect
              components={{ Option: DropdownOption }}
              menuIsOpen={true}
              onChange={setSelectedResident}
              options={residentOptions}
              menuPortalTarget={null}
              styles={{
                control: () => ({
                  display: 'none',
                }),
                menu: (provided) => ({
                  ...provided,
                  border: '1px solid var(--form-input-border)',
                  boxShadow: 'none',
                  position: 'static',
                }),
              }}
              value={residentOptions.find(
                (r) => r.value.id === selectedResident.value.id
              )}
            />
            <footer className={styles.footer}>
              <AsyncIconButton
                buttonStyle="secondary-outline"
                className="mr-[16px]"
                onClick={closeModal}
              >
                Cancel
              </AsyncIconButton>
              <AsyncIconButton
                disabled={
                  !selectedResident ||
                  selectedResident.value.id === currentPerson.id
                }
                buttonStyle="primary-fill"
                onClick={() => {
                  closeModal()

                  history.push(
                    pageToPath({
                      page: currentPage,
                      forPerson: selectedResident!.value as SetRequired<
                        Person,
                        'id' | 'orgId' | 'facilityId'
                      >,
                    })
                  )
                }}
              >
                Select
              </AsyncIconButton>
            </footer>
          </div>
        </AnimatedPopup>
      )}
    </div>
  )
}
