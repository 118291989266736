import { RoutineOptions } from '@augusthealth/models/com/august/protos/api/routine_settings'
import { useCallback, useEffect, useState } from 'react'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { Person } from '@shared/types/person'
import { RoutineOrder } from '@shared/types/routine_order'
import { AsyncResult, Loading } from '@shared/utils/loading'
import { getRoutineOrders } from '@app/api/routineOrders'
import { fetchRoutineOptions } from '../api/routines'

export default function useRoutines(
  { person }: { person: Person },
  deps: React.DependencyList = []
) {
  const [routineOrders, setRoutineOrders] = useState<
    AsyncResult<RoutineOrder[], unknown>
  >({ tag: 'Loading' })

  const refreshRoutineOrders = useCallback(async () => {
    const response = await getRoutineOrders(person)
    if (response.tag === 'Error') {
      ErrorMonitoring.capture({ error: response })
    }
    setRoutineOrders(response)
  }, [person.id])

  useEffect(() => {
    void getRoutineOrders(person).then((response) => {
      if (response.tag === 'Error') {
        ErrorMonitoring.capture({ error: response })
      }
      setRoutineOrders(response)
    })
  }, [person.id, ...deps])

  return {
    routineOrders,
    refreshRoutineOrders,
  }
}

export function useRoutineOptions(
  { person }: { person: Person },
  deps: React.DependencyList = []
) {
  const [routineOptions, setRoutineOptions] = useState<
    Loading<RoutineOptions[]>
  >({
    tag: 'Loading',
  })

  const refreshRoutineOptions = useCallback(async () => {
    const newRoutines = await fetchRoutineOptions({
      orgId: person.orgId!,
      id: person.facilityId!,
    })

    setRoutineOptions({ tag: 'Complete', value: newRoutines })
  }, [person.id])

  useEffect(() => {
    void refreshRoutineOptions()
  }, [person.id, ...deps])

  return { routineOptions, refreshRoutineOptions }
}
