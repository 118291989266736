import { AdmissionsInformation_AdmissionType } from '@shared/types/admissions'
import { Contact } from '@shared/types/contact'
import { Gender } from '@shared/types/gender'
import { HumanName } from '@shared/types/human_name'
import { LevelOfCare } from '@shared/types/level_of_care'
import { MetaData, MetaDataWithGroup } from '@shared/types/meta_data'
import { ResidentStatus } from '@shared/types/person'
import { UserAccount } from './user'

export type BillingCategoryData = {
  orgId: string
  facilityId?: string
  name: string
}

/**
 * A user-defined label for grouping BillingFees
 * Can be created at an org or facility level
 */
export type BillingCategory = {
  data: BillingCategoryData
  meta: MetaData
}

export enum BillingFrequency {
  ONE_TIME = 'ONE_TIME',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export type BillingFeeData = {
  orgId: string
  facilityId?: string
  categoryId: string
  name: string
  amountCents: number
  frequency: BillingFrequency
  glCode: string
}

/**
 * A fee that may be charged to a resident
 * User created, but with a required GL-code which implies that in the future these could be imported from another system
 * May be one-time or recurring
 * Can be created at an org or facility level
 */
export type BillingFee = {
  data: BillingFeeData
  meta: MetaData
  category: BillingCategory
}

export type BillingFeesWithParentCategory = {
  category: BillingCategory
  items: BillingFee[]
}

/**
 * Describes both a ChargeRequest and a RecurringCharge
 */
export type BillingChargeData = {
  personId: string
  itemId: string // BillingFee id
  name: string
  amountCents: number
  note?: string
  startDate: string
  endDate?: string
}

/**
 * See `createChargeOrTransaction` for usage
 */
export type AddChargeRequest = BillingChargeData & {
  quantity: number
}

/**
 * Defines a scheduled recurring charge that is attached to a resident
 */
export type RecurringCharge = {
  data: BillingChargeData
  meta: MetaDataWithGroup
  item: BillingFee
}

export type RoomDetails = {
  roomNumber: string
  bedNumber?: string
}

/**
 * Populates BillingPersonNavbar component of Resident page
 */
export type ResidentBillingSummary = {
  name: HumanName
  admissionType: AdmissionsInformation_AdmissionType
  levelOfCare?: LevelOfCare
  roomDetails?: RoomDetails
  moveInDate?: string
  financialStartDate?: string
  moveOutDate?: string
  financialEndDate?: string
  payers: Contact[]
  responsibleParty?: Contact
  statementBalanceCents: number
  totalBalanceCents: number
}

/**
 * Populates residents page table
 */
export interface ResidentListEntry {
  personId: string
  roomDetails?: RoomDetails
  profilePictureUrl?: string
  name: HumanName[]
  gender: Gender
  residentStatus: ResidentStatus
  statementBalanceCents: number
  totalBalanceCents: number
  lastInvoice?: Statement
  pendingBillingEvents: number
}

export type DraftStatementsRequest = {
  people: string[]
  startDate?: string
  endDate?: string
  notes?: string
}

export enum StatementStatus {
  NEEDS_WORK = 'NEEDS_WORK', // Currently no frontend workflow to support this status
  PENDING = 'PENDING',
  PAID = 'PAID',
  DUE = 'DUE',
  APPROVED = 'APPROVED', // Currently no frontend workflow to support this status
  ERROR = 'ERROR',
}

export type StatementData = {
  personId: string
  startDate: string
  endDate: string
  issueDate?: string
  dueDate?: string
  status: StatementStatus
  notes?: string
  rollOverBalanceCents?: number
}

/**
 * Used for a residents most recent statement
 */
export type Statement = {
  data: StatementData
  meta: MetaData
}

export type PersonSummary = {
  id: string
  name: HumanName
  bed?: string
  profilePhotoUrl?: string
}

export type StatementResponse = {
  id: string
  invoiceData: StatementData
  invoiceAmountCents: number
  person: PersonSummary
}

export type StatementItemData = {
  personId: string
  billingItemId: string
  billingChargeId?: string
  invoiceId?: string
  description: string
  quantity: number
  amountCents: number
  startDate: string
  endDate?: string
  postDate: string
}

/**
 * Line item in a statement
 * This should match the PDF view rendered by StatementViewer, but `StatementItem`s are not used explicitly
 */
export type StatementItem = {
  data: StatementItemData
  meta: MetaData
}

export enum StatementActionType {
  MarkedApproved = 'MarkedApproved',
  MarkedNeedsWork = 'MarkedNeedsWork',
  InvoiceGenerated = 'InvoiceGenerated',
  InvoiceSent = 'InvoiceSent',
  InvoiceError = 'InvoiceError',
  PaymentReceived = 'PaymentReceived',
}

type BaseStatementActionData = {
  occurredAt: string
  userId: string
}

export type ApprovalData = {
  note?: string
}

export type NeedsWorkData = {
  note: string
}

export type StatementErrorData = {
  message: string
}

/* Dimitry: there is more to this type,
 * but we don't need any of the other fields yet */
export type PaymentReceivedData = {
  paymentData: {
    amountCents: number
  }
  remainingBalanceCents: number
}

export type StatementActionData =
  | (BaseStatementActionData & {
      eventType: StatementActionType.InvoiceGenerated
    })
  | (BaseStatementActionData & {
      eventType: StatementActionType.MarkedApproved
      data: ApprovalData
    })
  | (BaseStatementActionData & {
      eventType: StatementActionType.MarkedNeedsWork
      data: NeedsWorkData
    })
  | (BaseStatementActionData & {
      eventType: StatementActionType.PaymentReceived
      data: PaymentReceivedData
    })
  | (BaseStatementActionData & {
      eventType: StatementActionType.InvoiceError
      data: StatementErrorData
    })
  | (BaseStatementActionData & {
      eventType: StatementActionType.InvoiceSent
    })

export type StatementAction = {
  userName: HumanName
  eventData: StatementActionData
}

/**
 * Used for the StatementViewer
 */
export type DetailedStatement = {
  data: StatementData
  meta: MetaData
  events: StatementAction[]
  invoiceAmountCents: number
  invoiceItems: StatementItem[]
}

export enum ResidentEventImpactingBillingType {
  RESIDENT_STATUS_CHANGED = 'RESIDENT_STATUS_CHANGED',
  ROOM_CHANGED = 'ROOM_CHANGED',
  ADMISSION_TYPE_CHANGED = 'ADMISSION_TYPE_CHANGED',
  LEVEL_OF_CARE_CHANGED = 'LEVEL_OF_CARE_CHANGED',
}

export enum ResidentEventImpactingBillingStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export type AbstractResidentEventImpactingBillingData = {
  eventType: ResidentEventImpactingBillingType
  personId: string
  status: ResidentEventImpactingBillingStatus
}

export type ResidentStatusChanged = {
  previousStatus: ResidentStatus
  newStatus:
    | ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
    | ResidentStatus.RESIDENT_STATUS_DISCHARGED
}

export type RoomDescription = {
  bedId: string
  roomNumber: string
  bedNumber?: string
  capacity: number
}

export type RoomChanged = {
  previousRoom?: RoomDescription
  newRoom: RoomDescription
}

export type AdmissionTypeChanged = {
  currentAdmissionType: AdmissionsInformation_AdmissionType
  previousAdmissionType?: AdmissionsInformation_AdmissionType
}

/**
 * The top level `LevelOfCareChanged` type is a case-class on the backend
 * But the inner types for `currentLevelOfCare` and `previousLevelOfCare` are not.
 * So I faithfully modeled the value/score as optional here.
 */
export type LevelOfCareChanged = {
  currentLevelOfCare: { value?: number; score?: number; levelName?: string }
  previousLevelOfCare?: { value?: number; score?: number; levelName?: string }
}

export type ResidentEventImpactingBillingData =
  | (AbstractResidentEventImpactingBillingData & {
      eventType: ResidentEventImpactingBillingType.RESIDENT_STATUS_CHANGED
      data: ResidentStatusChanged
    })
  | (AbstractResidentEventImpactingBillingData & {
      eventType: ResidentEventImpactingBillingType.ROOM_CHANGED
      data: RoomChanged
    })
  | (AbstractResidentEventImpactingBillingData & {
      eventType: ResidentEventImpactingBillingType.ADMISSION_TYPE_CHANGED
      data: AdmissionTypeChanged
    })
  | (AbstractResidentEventImpactingBillingData & {
      eventType: ResidentEventImpactingBillingType.LEVEL_OF_CARE_CHANGED
      data: LevelOfCareChanged
    })

/**
 * Events that may affect how the resident is being billed
 */
export type ResidentEventImpactingBilling = {
  data: ResidentEventImpactingBillingData
  meta: MetaData
}

/**
 * Used for display and filtering purposes
 */
export enum BillingEventType {
  CREDIT = 'CREDIT',
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
  STATEMENT = 'STATEMENT',
  PAYMENT = 'PAYMENT',
}

/**
 * Describes what actions are available
 */
export enum BillingEventIdType {
  BILLING_CHARGE = 'BILLING_CHARGE',
  INVOICE = 'INVOICE',
  INVOICE_ITEM = 'INVOICE_ITEM',
}

/**
 * Financial events.
 * Includes statment changes and transactions
 * A `pending` event is part of an issued statement
 */
export type BillingEvent = {
  amountCents?: number
  balanceCents: number
  createdAt: string
  description: string
  id: string
  idType: BillingEventIdType
  pending: boolean
  serviceStartDate: string
  serviceEndDate: string
  transactionType: BillingEventType
}

export type AccountingPeriodClose = {
  data: {
    facilityId: string
    closedBefore: string
  }
  meta: MetaData
}

/**
 * Used for manual payment entry
 * Currently only CHECK is used
 */
export enum PaymentMethod {
  CASH = 'CASH',
  CHECK = 'CHECK',
  CREDIT_CARD = 'CREDIT_CARD',
  WIRE = 'WIRE',
  DEBIT_CARD = 'DEBIT_CARD',
}

export interface ManualPaymentEntry {
  personId: string
  paymentDate: string
  amountCents: number
  checkNumber?: string
}

export interface ManualPaymentRequest {
  depositDate: string
  externalId: string
  paymentMethod: PaymentMethod
  payments: ManualPaymentEntry[]
}

type LedgerExportData = {
  id: string
  facilityId: string
  endDate: string
  createdBy: string
  createdAt: string
}

export type LedgerExport = {
  data: LedgerExportData
  user: UserAccount
}
