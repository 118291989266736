import { every, some } from 'lodash'
import { UserFormData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { UserAccount } from '@shared/types/user'
import { CreateUpdateResponse } from '@shared/utils/task'
import { isDirector } from '@shared/utils/user'
import {
  createOrganizationUser,
  updateOrganizationUser,
} from '@app/api/organizations'
import {
  createFacilityUserWithMultipleApiCalls,
  setPassword,
  updateFacilityUserWithMultipleApiCalls,
} from '@app/api/users'
import { convertUserFormDataToUser } from './helpers'

export const save = async ({
  userFormData: userFormDataWithPassword,
  loginUser,
  originalUser,
  orgId,
  users,
  setUserPopupData,
}: {
  userFormData: UserFormData
  loginUser: UserAccount
  originalUser: UserAccount | null
  users: UserAccount[]
  orgId: string
  setUserPopupData: (data: any) => void
}) => {
  const { password, ...userFormDataRaw } = userFormDataWithPassword

  const userFormData = {
    ...userFormDataRaw,
  }

  const user = await upsertUser({
    userFormData,
    loginUser,
    originalUser,
    orgId,
    users,
  })

  if (user) {
    setUserPopupData({ user, users })

    if (password) {
      await setPassword({
        userId: user.id!,
        orgId,
        password,
      })
    }
  }
}

async function upsertUser({
  userFormData,
  loginUser,
  originalUser,
  orgId,
  users,
}: {
  userFormData: UserFormData
  loginUser: UserAccount
  originalUser: UserAccount | null
  users: UserAccount[]
  orgId: string
}) {
  const loginAsDirector = isDirector({ user: loginUser!, orgId })
  const userToSave = convertUserFormDataToUser({
    orgId,
    originalUser: originalUser,
    userFormData,
  })

  const userAlreadyExists = users.find((u) => {
    return (
      u.email === userToSave.email &&
      u.preferredUsername === userToSave.preferredUsername &&
      every(userToSave.groups, (g) => some(u.groups, g))
    )
  })

  if (originalUser === null && userAlreadyExists) {
    return Promise.resolve()
  }

  let promise: Promise<
    CreateUpdateResponse | { id: string | number | undefined }
  >
  if (loginAsDirector) {
    promise =
      originalUser === null
        ? createFacilityUserWithMultipleApiCalls({
            loginUser,
            orgId,
            userToCreate: userToSave,
          })
        : updateFacilityUserWithMultipleApiCalls({
            loginUser: loginUser!,
            orgId,
            updatedUser: userToSave,
          })
  } else {
    promise =
      originalUser === null
        ? createOrganizationUser(orgId, userToSave)
        : updateOrganizationUser(orgId, originalUser.id!, userToSave)
  }

  let userId = originalUser?.id
  const response = await promise

  if ('id' in response && response.id !== undefined) {
    userId = response.id as string
  } else if ('data' in response && response.data?.id !== undefined) {
    userId = response.data?.id as string
  }

  return {
    ...userToSave,
    id: userId,
  }
}
