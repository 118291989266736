import {
  MedicationApprovalStatus,
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import { mapFormMedToMedOrder } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export const mapNewFormMedToMedOrder = ({
  formData,
  personId,
}: {
  formData: MedOrderFormData
  personId: string
}): MedicationOrder => {
  const updatedWithForm = mapFormMedToMedOrder({
    formData,
    medOrder: {
      medicationStatement: {
        medication: {},
      },
      approval: {},
    },
  }) as MedicationOrder

  updatedWithForm.personId = personId
  updatedWithForm.approval!.status =
    MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_APPROVED

  return updatedWithForm
}
