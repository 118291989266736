import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useContext, useEffect, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  SETTINGS_LEVEL_HIERARCHY as hierarchy,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import {
  expandStateAbbreviation,
  StateAbbreviation,
  stateOptions,
} from '@shared/utils/state'
import { taskTitle } from '@shared/utils/task'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'
import styles from './styles.module.css'
import {
  CardLevel,
  getRelevantState,
  suppressSingleTask,
  suppressTaskForMultipleStates,
} from './helpers'

export default function SuppressModal({
  cardLevel,
  taskSettings,
  onClose,
  org,
  facility,
}: {
  cardLevel: CardLevel
  taskSettings: OrgFacilitySettings
  onClose: () => Promise<void>
  org?: Organization
  facility: Facility
}) {
  const { setError } = useContext(GlobalContext)
  const [statesToSuppress, setStatesToSuppress] = useState<
    OptionTypeBase<StateAbbreviation>[]
  >([])
  const [defaultStateSuppression, setDefaultStateSuppression] =
    useState<OptionTypeBase<StateAbbreviation>>()
  const [desiredSuppression, setDesiredSuppression] =
    useState<OptionTypeBase<SettingsLevel>>()

  const [defaultSuppression, setDefaultSuppression] =
    useState<OptionTypeBase<SettingsLevel> | null>()

  const assignmentLevel: SettingsLevel = getSettingsLevel(taskSettings)
  const currentAssignmentIndex = hierarchy.indexOf(assignmentLevel)
  const taskName = taskTitle(taskSettings.settings!.taskDefinition!.template!)

  const hierarchyOptions = hierarchy.map((value, index) => {
    return {
      label: value,
      value: value,
      isDisabled:
        currentAssignmentIndex <= index ||
        value === SettingsLevel.ORG_STATE_LEVEL,
    }
  })

  useEffect(() => {
    const defaultSuppressionValue =
      cardLevel.tag === 'Facility' &&
      assignmentLevel === SettingsLevel.ORG_LEVEL
        ? hierarchyOptions[0]
        : null

    if (defaultSuppressionValue) {
      setDefaultSuppression(defaultSuppressionValue)
      setDesiredSuppression(defaultSuppressionValue)
    } else {
      setDefaultSuppression(null)
    }

    const matchingState = getRelevantState({ facility, taskSettings })
    if (matchingState) {
      const defaultState: OptionTypeBase<StateAbbreviation> = {
        label: expandStateAbbreviation(matchingState) || matchingState,
        value: matchingState as StateAbbreviation,
      }
      setDefaultStateSuppression(defaultState)
      setStatesToSuppress([defaultState])
    }
  }, [])

  const onSubmit = async () => {
    try {
      if (desiredSuppression!.value === SettingsLevel.STATE_LEVEL) {
        await suppressTaskForMultipleStates({
          statesToSuppress: statesToSuppress.map((state) => state.value),
          taskSettings: taskSettings,
        })
      } else {
        await suppressSingleTask({
          desiredSuppression: desiredSuppression!.value,
          taskSettings,
          cardLevel,
        })
      }
      await onClose()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AnimatedPopup title={`Suppress ${taskName}`}>
      <div className={'mb-[32px]'}>
        <LabelAboveInput
          htmlFor={'suppressionLevel'}
          subLabel={{ tag: 'Required', hasError: false }}
        >
          Suppression Level
        </LabelAboveInput>
        {defaultSuppression !== undefined && (
          <StyledSelect
            name="suppressionLevel"
            maxMenuHeight={200}
            menuPlacement={'auto'}
            defaultValue={defaultSuppression}
            options={hierarchyOptions}
            onChange={setDesiredSuppression}
          />
        )}
        {desiredSuppression &&
          desiredSuppression.value === SettingsLevel.STATE_LEVEL && (
            <div className={styles.suppressionDetail}>
              <LabelAboveInput
                htmlFor={'states'}
                subLabel={{ tag: 'Required', hasError: false }}
              >
                States
              </LabelAboveInput>
              <StyledMultiSelect
                name={'states'}
                options={stateOptions}
                onChange={setStatesToSuppress}
                defaultValue={defaultStateSuppression}
              />
              {statesToSuppress.length > 0 && (
                <div className={styles.suppressionDetail}>
                  Suppress {taskName} for the following states:{' '}
                  {statesToSuppress.map((state) => state.label).join(', ')}?
                </div>
              )}
            </div>
          )}
        {desiredSuppression &&
          desiredSuppression.value === SettingsLevel.FACILITY_LEVEL && (
            <div className={styles.suppressionDetail}>
              Suppress {taskName} for {facility.name}?
            </div>
          )}
        {org &&
          desiredSuppression &&
          desiredSuppression.value === SettingsLevel.ORG_LEVEL && (
            <div className={styles.suppressionDetail}>
              Suppress {taskName} for {org!.name}?
            </div>
          )}
      </div>
      <AnimatedPopupFormFooter
        yesBtn={{
          label: 'Confirm',
          props: {
            onClick: () => void onSubmit(),
            disabled:
              !desiredSuppression ||
              (desiredSuppression.value === SettingsLevel.STATE_LEVEL &&
                statesToSuppress.length === 0),
          },
        }}
        noBtn={{
          action: () => void onClose(),
        }}
        formState={{ isSubmitting: false }}
      />
    </AnimatedPopup>
  )
}
