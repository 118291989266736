import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { fetchPeople } from '@shared/api/person'
import { PersonData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { StyledMultiSelect } from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person, ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { getFacilityHash } from '@shared/utils/facilities'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'
import DropdownOption from '@app/components/RPView/ResidentSwitcher/DropdownOption'
import { OptionProps } from '@app/components/RPView/ResidentSwitcher/types'
import FacilitiesContext from '@app/contexts/FacilitiesContext'

export default function PersonDropdown({
  control,
  errors,
  facilityIds,
  orgId,
  loginUser,
}: {
  control: Control
  errors: FieldErrors<UserAccount>
  facilityIds?: string[]
  orgId: string
  loginUser: UserAccount
}) {
  const { facilities = [] } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const [peopleData, setPeopleData] = useState<Loading<Person[]>>({
    tag: 'Loading',
  })
  const facilityHash = getFacilityHash(facilities)

  useEffect(() => {
    const promises = facilities?.reduce((list: Promise<Person[]>[], f) => {
      const hasPermission = hasPermissionForFacility({
        user: loginUser,
        facility: { id: f.id, orgId: f.orgId },
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_READ],
      })

      if (f.id && hasPermission) {
        list.push(
          fetchPeople({
            facilityId: f.id,
            orgId,
            fields: ['facilityId', 'id', 'name', 'residentStatus'],
          })
        )
      }

      return list
    }, [])

    if (promises?.length) {
      Promise.all(promises)
        .then((pMatrix) => {
          setPeopleData({
            tag: 'Complete',
            value: ([] as Person[]).concat(...pMatrix),
          })
        })
        .catch(setError)
    }
  }, [facilityIds?.join(), orgId])

  const options: OptionProps[] = getOrElse(peopleData, []).reduce(
    (list: OptionProps[], p) => {
      const { facilityId, id, name, residentStatus } = p

      if (
        id &&
        name &&
        facilityIds?.some((fId) => fId === facilityId) &&
        (residentStatus === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT ||
          residentStatus === ResidentStatus.RESIDENT_STATUS_PROSPECT)
      ) {
        list.push({
          facility: (facilityId && facilityHash[facilityId]) || undefined,
          label: getFirstAndLastName(p.name),
          value: { facilityId, id },
        })
      }

      return list
    },
    []
  )

  return (
    <Controller
      name={'residents'}
      control={control}
      render={({ field: { onChange, value } }) => {
        const selectedValue =
          (value &&
            options.filter((opt) =>
              value.some((v: PersonData) => v.id === opt?.value.id)
            )) ||
          [] // Default to empty array so reset on Facility change will work

        return (
          <div className="mb-[32px]">
            <LabelAboveInput
              htmlFor={'residents'}
              subLabel={requiredLabel(errors['residents'])}
            >
              Move-in / Resident
            </LabelAboveInput>
            <StyledMultiSelect
              components={{ Option: DropdownOption }}
              isDisabled={peopleData.tag === 'Loading'}
              inputId="person-dropdown"
              onChange={(opts: { value: PersonData }[]) => {
                onChange(opts?.length ? opts.map((o) => o.value) : null)
              }}
              options={options}
              placeholder="First or last name..."
              value={selectedValue}
            />
          </div>
        )
      }}
      rules={{ required: true }}
    />
  )
}
