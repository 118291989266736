import { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { listFacilities } from '@shared/api/facility'
import { fetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import {
  LabelAboveInput,
  optionalLabel,
  requiredWhenError,
} from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { fromDateMessageToDate } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import ConfirmModal from '@app/components/ConfirmModal'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'
import {
  dischargeReasonOptions,
  getDefaultValues,
  updatePerson,
} from './helpers'
import ConfirmHUD from './ShareableUIs/ConfirmHUD'
import { MoveOutFormData } from './types'

interface Props {
  person: Person
  doneFn: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MoveOutDetails({ person, doneFn }: Props) {
  const [showMoveOutConfirmation, setShowMoveOutConfirmation] = useState<{
    show: boolean
    data: MoveOutFormData
  }>({ show: false, data: getDefaultValues() })
  const [showMoveOutComplete, setShowMoveOutComplete] = useState<boolean>(false)
  const { setPerson } = useContext(PersonContext)
  const { setError } = useContext(GlobalContext)
  const { setFacilities } = useContext(FacilitiesContext)
  const todaysDate = new Date()
  const { formState, register, handleSubmit, control } =
    useForm<MoveOutFormData>({
      defaultValues: getDefaultValues(),
    })

  const closeForm = (updatedPerson: Person) => {
    if (updatedPerson) {
      const { orgId, facilityId, id: personId } = updatedPerson
      fetchPerson({
        orgId: orgId!,
        facilityId: facilityId!,
        personId: personId!,
      })
        .then(setPerson)
        .catch(setError)
      // Update Prospect / Resident counters in Sidebar
      listFacilities(orgId!)
        .then((res) => setFacilities(res))
        .catch(setError)
    }
  }

  const onSubmit = (data: MoveOutFormData) =>
    setShowMoveOutConfirmation({ show: true, data })

  const confirmSubmit = async (formData: MoveOutFormData) => {
    try {
      const { data: updatedPerson } = await updatePerson(person, formData)
      closeForm(updatedPerson)
      setShowMoveOutComplete(true)
    } catch (e) {
      setError(e)
    } finally {
      setShowMoveOutConfirmation({ show: false, data: getDefaultValues() })
    }
  }

  const moveInDate = person.admissionsInformation?.startDate
  const financialStartDate = person.admissionsInformation?.financialStartDate
  const formContent = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="reason"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <div className="mb-[32px]">
            <LabelAboveInput
              htmlFor="reason"
              subLabel={requiredWhenError(invalid)}
            >
              Reason
            </LabelAboveInput>
            <StyledSelect
              name="reason"
              onChange={onChange}
              options={dischargeReasonOptions}
              value={value}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="moveOutDate"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <div className="mb-[32px]">
            <LabelAboveInput
              subLabel={requiredWhenError(invalid)}
              htmlFor="moveOutDate"
            >
              Move-out Date
            </LabelAboveInput>
            <DatePicker
              customInput={<CalendarInput />}
              selected={value}
              onChange={onChange}
              minDate={
                moveInDate ? fromDateMessageToDate(moveInDate) : undefined
              }
              maxDate={todaysDate}
              portalId={'root'}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="financialEndDate"
        render={({ field: { onChange, value } }) => (
          <div className="mb-[32px]">
            <LabelAboveInput htmlFor="financialEndDate">
              Financial effective end date
            </LabelAboveInput>
            <DatePicker
              customInput={<CalendarInput />}
              selected={value}
              onChange={onChange}
              portalId={'root'}
              minDate={
                financialStartDate
                  ? fromDateMessageToDate(financialStartDate)
                  : undefined
              }
            />
          </div>
        )}
      />
      <div className="mb-[32px] mt-[32px]">
        <LabelAboveInput subLabel={optionalLabel} htmlFor="notes">
          Notes
        </LabelAboveInput>
        <BasicTextarea
          data-cy="discharge-notes"
          {...register('notes')}
          placeholder="Add additional details here..."
        />
      </div>
      <AnimatedPopupFormFooter
        formState={formState}
        noBtn={{ action: () => doneFn(false) }}
        yesBtn={{
          label: 'Continue',
          props: {
            id: 'continue-move-out-resident',
          },
        }}
      />
    </form>
  )

  if (showMoveOutConfirmation.show) {
    const personName =
      person && person.name ? getFullName(person.name[0]) : 'this resident'
    return (
      <ConfirmModal
        title={`Do you want to move out ${personName}?`}
        content={
          <div className={styles.confirmModalContent}>
            They will then appear under Moved-Out residents. You can still
            update the resident chart and billing dates.
          </div>
        }
        confirmButtonConfig={{
          children: 'Yes, move out',
          onClick: () => confirmSubmit(showMoveOutConfirmation.data),
        }}
        denyButtonConfig={{
          onClick: () => {
            doneFn(false)
            setShowMoveOutConfirmation({
              show: false,
              data: getDefaultValues(),
            })
          },
        }}
      />
    )
  } else if (showMoveOutComplete) {
    return (
      <ConfirmHUD
        iconClassName="fa-solid fa-house-person-leave fa-4x"
        onExpire={() => {
          setShowMoveOutComplete(false)
          doneFn(true)
        }}
        person={person}
        title="Resident Moved-out"
      />
    )
  }
  return <AnimatedPopup title="Move-Out Resident">{formContent}</AnimatedPopup>
}
