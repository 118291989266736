import { format } from 'date-fns'
import { capitalize, sortBy } from 'lodash'
import pluralize from 'pluralize'
import { match, P } from 'ts-pattern'
import { Time } from '@shared/types/date'
import {
  DayOfWeek,
  EventTiming,
  TimeAndEventTiming,
  Timing,
  UnitOfTime,
} from '@shared/types/timing'
import { formatTime } from '@shared/utils/date'
import notEmpty from '@shared/utils/notEmpty'
import { compareTimeAsc, fromTimetoTimeString } from '@shared/utils/time'
import { ScheduleValue, TimeGroup } from './scheduleValue'

export const dayOfWeekOptions = [
  { label: 'M', value: DayOfWeek.DAY_OF_WEEK_MONDAY },
  { label: 'T', value: DayOfWeek.DAY_OF_WEEK_TUESDAY },
  { label: 'W', value: DayOfWeek.DAY_OF_WEEK_WEDNESDAY },
  { label: 'T', value: DayOfWeek.DAY_OF_WEEK_THURSDAY },
  { label: 'F', value: DayOfWeek.DAY_OF_WEEK_FRIDAY },
  { label: 'S', value: DayOfWeek.DAY_OF_WEEK_SATURDAY },
  { label: 'S', value: DayOfWeek.DAY_OF_WEEK_SUNDAY },
]

export type RepeatedOrSpecificDays =
  | { tag: 'Every'; count: number; period?: UnitOfTime }
  | { tag: 'Specific'; days: { label: string; value: string }[] }

export function isInAM(value: ScheduleValue) {
  return isAt(value, 'Morning') || isAt(value, 'AM')
}

export function isInPM(value: ScheduleValue) {
  return isAt(value, 'Afternoon') || isAt(value, 'PM')
}

export function isInNS(value: ScheduleValue) {
  return isAt(value, 'Night') || isAt(value, 'HS') || isAt(value, 'NOC')
}

/**
 * Checks if value is not a 'NoValue' and if it matches any of the passed
 * routine time args
 *
 * @param value
 * @param routineTime
 */
export function isAt(
  value: ScheduleValue,
  routineTime: TimeGroup | 'Morning' | 'Afternoon' | 'Night' | 'NoValue'
) {
  return (
    value !== 'NoValue' &&
    (('name' in value && value.name === routineTime) ||
      ('timeGroup' in value && value.timeGroup === routineTime))
  )
}

/**
 * Turns a Timing object that has a period & periodUnit into a string such as
 * 'Every week', 'Every 2 days', 'Every 3 weeks', etc.
 *
 * @param timing
 */
export function timingToEvery(timing: Timing) {
  const { period, periodUnit } = timing

  if (period === undefined || periodUnit === undefined) {
    return undefined
  }

  const unit = periodUnit.replace(/^UNIT_OF_TIME_/, '').toLowerCase()

  if (period > 1) {
    return `Every ${period} ${unit}s`
  }

  return `Every ${unit}`
}

/**
 * Turns a Timing object into a list of days of the week such as:
 * ['Monday', 'Wednesday', 'Friday']
 *
 * @param timing
 */
export function timingToDays(timing: Timing) {
  return (timing.dayOfWeek ?? []).map((dayOfWeekEnum) =>
    capitalize(dayOfWeekEnum.replace(/^DAY_OF_WEEK_/, ''))
  )
}

/**
 * Turns a Timing object into an hour representation such as:
 * 9:30AM
 */
export function timingToTimeString(timing: Timing) {
  return (timing.timeOfDay ?? [])
    .map((t) => t.time)
    .filter(notEmpty)
    .map((t) => fromTimetoTimeString(t))
}

type OrderedEventTiming = { name: string; icon: string; order: number }

export function timingToShiftName(timing: Timing) {
  const timesOfDay = timing.timeOfDay ?? []

  return sortBy(
    timesOfDay.reduce<OrderedEventTiming[]>((accum, el) => {
      const eventTiming = el.when

      if (eventTiming !== undefined) {
        const result = sortedTiming(eventTiming)

        if (result !== undefined) {
          return [...accum, result]
        }
      }

      return accum
    }, []),
    'order'
  )
}

/**
 * Turns an event timing value into an OrderedEventTiming type.
 * Note that both 'Afternoon' and 'Evening' turn into 'Afternoon Shift'
 * This is because we have 3 possible shifts but 4 possible event timing values.
 * For Routines, we don't use EventTiming.EVENT_TIMING_AFT
 * @param eventTiming
 */
function sortedTiming(
  eventTiming: EventTiming
): OrderedEventTiming | undefined {
  switch (eventTiming) {
    case EventTiming.EVENT_TIMING_MORN:
      return {
        name: 'Morning Shift',
        icon: 'fa-sunrise',
        order: 0,
      }
    case EventTiming.EVENT_TIMING_AFT:
      return {
        name: 'Afternoon Shift',
        icon: 'fa-sun',
        order: 1,
      }
    case EventTiming.EVENT_TIMING_EVE:
      return {
        name: 'Afternoon Shift',
        icon: 'fa-sun',
        order: 2,
      }
    case EventTiming.EVENT_TIMING_NIGHT:
      return {
        name: 'Night Shift',
        icon: 'fa-moon-stars',
        order: 3,
      }
    default:
      return undefined
  }
}

export function toScheduleValue(timing: Timing): ScheduleValue[] {
  return (timing.timeOfDay ?? [])
    .map((v) => {
      let name: 'Evening' | 'Morning' | 'Afternoon' | 'Night' | undefined
      let time: string | undefined

      if (v.when) {
        switch (v.when) {
          case EventTiming.EVENT_TIMING_EVE:
            name = 'Afternoon'
            break
          case EventTiming.EVENT_TIMING_MORN:
            name = 'Morning'
            break
          case EventTiming.EVENT_TIMING_AFT:
            name = 'Afternoon'
            break
          case EventTiming.EVENT_TIMING_NIGHT:
            name = 'Night'
            break
        }
      }

      if (v.time) {
        time = fromTimetoTimeString(v.time)
      }

      if (!!name && !time) {
        let timeGroup: TimeGroup
        switch (name) {
          case 'Evening':
            timeGroup = 'PM'
            break
          case 'Morning':
            timeGroup = 'AM'
            break
          case 'Afternoon':
            timeGroup = 'PM'
            break
          case 'Night':
            timeGroup = 'NOC'
            break
        }

        return {
          quantity: '',
          timeGroup,
        }
      }

      if (time && name) {
        return {
          time,
          name,
          quantity: '',
        }
      }

      return undefined
    })
    .filter(notEmpty)
}

type ValidDaysOfWeek = Exclude<
  DayOfWeek,
  DayOfWeek.DAY_OF_WEEK_UNSPECIFIED | DayOfWeek.UNRECOGNIZED
>
export const DAY_OF_WEEK_TO_ABBR_DISPLAY_TEXT: {
  [key in ValidDaysOfWeek]: string
} = {
  [DayOfWeek.DAY_OF_WEEK_MONDAY]: 'Mon',
  [DayOfWeek.DAY_OF_WEEK_TUESDAY]: 'Tues',
  [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: 'Wed',
  [DayOfWeek.DAY_OF_WEEK_THURSDAY]: 'Thu',
  [DayOfWeek.DAY_OF_WEEK_FRIDAY]: 'Fri',
  [DayOfWeek.DAY_OF_WEEK_SATURDAY]: 'Sat',
  [DayOfWeek.DAY_OF_WEEK_SUNDAY]: 'Sun',
}

type SchedulingUnitsOfTime = Exclude<
  UnitOfTime,
  | UnitOfTime.UNRECOGNIZED
  | UnitOfTime.UNIT_OF_TIME_UNSPECIFIED
  | UnitOfTime.UNIT_OF_TIME_SECOND
  | UnitOfTime.UNIT_OF_TIME_MINUTE
>
export const SINGLE_UNIT_TO_DISPLAY_TEXT: {
  [key in SchedulingUnitsOfTime]: string
} = {
  [UnitOfTime.UNIT_OF_TIME_DAY]: 'Daily',
  [UnitOfTime.UNIT_OF_TIME_HOUR]: 'Hourly',
  [UnitOfTime.UNIT_OF_TIME_WEEK]: 'Weekly',
  [UnitOfTime.UNIT_OF_TIME_MONTH]: 'Monthly',
  [UnitOfTime.UNIT_OF_TIME_YEAR]: 'Yearly',
}

export const getGeneralEventTimingFromSpecificTime = (
  time?: Time
): EventTiming => {
  if (!time || time.hour === undefined) {
    return EventTiming.EVENT_TIMING_UNSPECIFIED
  }

  const hour = time.hour

  if (hour >= 4 && hour <= 11) {
    return EventTiming.EVENT_TIMING_MORN
  }

  if (hour >= 12 && hour <= 16) {
    return EventTiming.EVENT_TIMING_AFT
  }

  if (hour >= 17 && hour <= 20) {
    return EventTiming.EVENT_TIMING_EVE
  }

  if (hour < 4 || hour > 20) {
    return EventTiming.EVENT_TIMING_NIGHT
  }

  return EventTiming.EVENT_TIMING_UNSPECIFIED
}

export const MED_PASS_TO_EVENT_TIMING = {
  ['AM']: EventTiming.EVENT_TIMING_MORN,
  ['NN']: EventTiming.EVENT_TIMING_AFT,
  ['PM']: EventTiming.EVENT_TIMING_EVE,
  ['HS']: EventTiming.EVENT_TIMING_NIGHT,
}

type ValidEventTiming = Exclude<
  EventTiming,
  EventTiming.EVENT_TIMING_UNSPECIFIED | EventTiming.UNRECOGNIZED
>
export const EVENT_TIMING_TO_MED_PASS_STRING: {
  [key in ValidEventTiming]: string
} = {
  [EventTiming.EVENT_TIMING_MORN]: 'AM',
  [EventTiming.EVENT_TIMING_AFT]: 'NN',
  [EventTiming.EVENT_TIMING_EVE]: 'PM',
  [EventTiming.EVENT_TIMING_NIGHT]: 'HS',
}

export const EVENT_TIMING_OPTIONS = [
  {
    label: `Morning (${
      EVENT_TIMING_TO_MED_PASS_STRING[EventTiming.EVENT_TIMING_MORN]
    })`,
    value: EventTiming.EVENT_TIMING_MORN,
  },
  {
    label: `Afternoon (${
      EVENT_TIMING_TO_MED_PASS_STRING[EventTiming.EVENT_TIMING_AFT]
    })`,
    value: EventTiming.EVENT_TIMING_AFT,
  },
  {
    label: `Evening (${
      EVENT_TIMING_TO_MED_PASS_STRING[EventTiming.EVENT_TIMING_EVE]
    })`,
    value: EventTiming.EVENT_TIMING_EVE,
  },
  {
    label: `Night (${
      EVENT_TIMING_TO_MED_PASS_STRING[EventTiming.EVENT_TIMING_NIGHT]
    })`,
    value: EventTiming.EVENT_TIMING_NIGHT,
  },
]

export const formatDayOfMonth = (day: number): string => {
  const date = new Date().setMonth(0, day)
  return format(date, 'do')
}

export const formatDayOfWeekAbbreviated = (
  dayOfWeek: DayOfWeek
): string | null => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return DAY_OF_WEEK_TO_ABBR_DISPLAY_TEXT[dayOfWeek] ?? null
}

export const formatTimeOfDayTime = ({
  time,
  use24HourClock,
}: {
  time: Time
  use24HourClock: boolean
}): string => {
  return formatTime(time, {
    use24HourClock,
  }) as string
}

export const formatTimeOfDayEventTiming = (
  when: EventTiming
): string | null => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return EVENT_TIMING_TO_MED_PASS_STRING[when] ?? null
}

const DAYS_OF_WEEK_ORDER = {
  [DayOfWeek.DAY_OF_WEEK_MONDAY]: 0,
  [DayOfWeek.DAY_OF_WEEK_TUESDAY]: 1,
  [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: 2,
  [DayOfWeek.DAY_OF_WEEK_THURSDAY]: 3,
  [DayOfWeek.DAY_OF_WEEK_FRIDAY]: 4,
  [DayOfWeek.DAY_OF_WEEK_SATURDAY]: 5,
  [DayOfWeek.DAY_OF_WEEK_SUNDAY]: 6,
}

/**
 * @returns timing days of week sorted by DAYS_OF_WEEK_ORDER
 */
export const getDaysOfWeek = (timing?: Timing): DayOfWeek[] => {
  return (
    timing?.dayOfWeek?.sort(
      (a, b) => DAYS_OF_WEEK_ORDER[a] - DAYS_OF_WEEK_ORDER[b]
    ) ?? []
  )
}

/**
 * @returns timing days of month sorted by day number
 */
export const getDaysOfMonth = (timing?: Timing): number[] => {
  return timing?.dayOfMonth?.sort((a, b) => a - b) ?? []
}

const EVENT_TIMING_ORDER = {
  [EventTiming.EVENT_TIMING_MORN]: 0,
  [EventTiming.EVENT_TIMING_AFT]: 1,
  [EventTiming.EVENT_TIMING_EVE]: 2,
  [EventTiming.EVENT_TIMING_NIGHT]: 3,
}

const notUndefined = P.when((a) => a !== undefined)
const isTime = { time: { hour: P.number, minute: P.number } }
const isShift = {
  shiftId: P.string,
  shift: {
    period: {
      startTime: {
        hour: P.number,
        minute: P.number,
      },
    },
  },
}
const isWhen = { when: notUndefined }

/**
 * Compare two time of day timings for sorting
 */
export function compareTimeAndEventTiming(
  a: TimeAndEventTiming,
  b: TimeAndEventTiming
): number {
  return match([a, b])
    .with([isTime, isTime], ([aTime, bTime]) =>
      compareTimeAsc(aTime.time, bTime.time)
    )
    .with([isShift, isShift], ([aShift, bShift]) =>
      compareTimeAsc(
        aShift.shift.period.startTime,
        bShift.shift.period.startTime
      )
    )
    .with(
      [isWhen, isWhen],
      ([aWhen, bWhen]) =>
        EVENT_TIMING_ORDER[aWhen.when] - EVENT_TIMING_ORDER[bWhen.when]
    )
    .with([isTime, isWhen], () => -1)
    .with([isWhen, isTime], () => 1)
    .with([isTime, isShift], () => -1)
    .with([isShift, isTime], () => 1)
    .with([isShift, isWhen], () => -1)
    .with([isWhen, isShift], () => 1)
    .otherwise(() => 0)
}

/**
 * @returns timing times of day sorted by compareTimeAndEventTiming
 */
export const getTimesOfDay = (timing?: Timing): TimeAndEventTiming[] => {
  return timing?.timeOfDay?.sort(compareTimeAndEventTiming) ?? []
}

export const getFormattedPeriodAndFrequencyDisplayData = (
  timing?: Timing,
  options?: { allowUnspecifiedFrequency: boolean }
): string | null => {
  if (!timing) {
    return null
  }

  const { period, periodUnit, frequency, oneTimeOnly } = timing

  if (!period && !periodUnit) {
    return null
  }

  let periodOfTimeDisplay: string | undefined = periodUnit
    ? (SINGLE_UNIT_TO_DISPLAY_TEXT[periodUnit as UnitOfTime] as string)
    : undefined

  if (period && period >= 2 && periodOfTimeDisplay) {
    const readablePeriodUnit =
      periodUnit?.replace(/^UNIT_OF_TIME_/, '').toLowerCase() ?? ''

    periodOfTimeDisplay = `Every ${pluralize(readablePeriodUnit, period, true)}`
  }

  if (!frequency || (frequency && isNaN(frequency))) {
    if (options?.allowUnspecifiedFrequency) {
      return periodOfTimeDisplay || null
    } else {
      return null
    }
  }

  if (oneTimeOnly) {
    return 'One Time Only'
  }

  let frequencyDisplay = pluralize('time', frequency, true)

  if (frequency === 1) {
    frequencyDisplay = 'Once'
  } else if (frequency === 2) {
    frequencyDisplay = 'Twice'
  }

  return `${frequencyDisplay} ${periodOfTimeDisplay ?? ''}`
}
