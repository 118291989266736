import { BlockedEmail } from '@augusthealth/models/com/august/protos/api/blocked_email'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { getFacilityIdsFromGroups } from '@shared/utils/permisson'
import { CreateUpdateResponse } from '@shared/utils/task'
import { mergeFacilityUsers } from '@shared/utils/user'

export async function getUserByEmail(email: string): Promise<UserAccount> {
  const response = await requestJson({
    url: `${environment.baseUrl}/users/email/${email}`,
  })

  return response.data
}

export async function checkBlocklist(email: string): Promise<BlockedEmail> {
  const response = await requestJson({
    url: `${environment.baseUrl}/emails/blocked/${email}`,
  })

  return response.data
}

export async function removeFromBlocklist(
  email: string
): Promise<BlockedEmail> {
  const response = await requestJson({
    url: `${environment.baseUrl}/emails/blocked/${email}`,
    method: 'DELETE',
  })

  return response.data
}

type FacilityIds = Required<Pick<Facility, 'orgId' | 'id'>>

function apiFacilityUserUrl(facility: FacilityIds) {
  const { id: facilityId, orgId } = facility
  return `${apiFacilityUrl(orgId, facilityId)}/users`
}

export async function setPassword({
  userId,
  password,
  orgId,
}: {
  userId: string
  password: string
  orgId: string
}): Promise<UserAccount> {
  const responseJson = await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/users/${userId}/setPassword`,
    method: 'POST',
    body: JSON.stringify(password),
  })

  return responseJson.data
}

export async function isUsernameTaken({
  preferredUsername,
}: {
  preferredUsername: string
}) {
  const responseJson = await requestJson({
    url: new URL('/users/aliasExists', environment.baseUrl).toString(),
    method: 'POST',
    body: JSON.stringify({
      preferredUsername,
    }),
  })

  return responseJson.data
}

export async function getFacilityUsers({
  facility,
}: {
  facility: FacilityIds
}): Promise<UserAccount[]> {
  const responseJson = await requestJson({
    url: `${apiFacilityUserUrl(facility)}?limit=9999`,
  })

  return responseJson.data
}

// Fetch All Facility Users who the login user has access to
// and merge their groups base on their user ID
export async function fetchAllFacilityUsers({
  loginUser,
  orgId,
}: {
  loginUser: UserAccount
  orgId: string
}): Promise<UserAccount[]> {
  if (!loginUser.groups) {
    return Promise.resolve([])
  }

  const facilityIds = getFacilityIdsFromGroups({
    groups: loginUser.groups,
    orgId,
    permission: GroupPermission.GROUP_PERMISSION_FACILITY_USER_READ,
  })
  const promises = facilityIds.map((fId) =>
    getFacilityUsers({ facility: { orgId, id: fId } })
  )

  return Promise.all(promises).then((listInList: UserAccount[][]) =>
    mergeFacilityUsers(listInList)
  )
}

async function createFacilityUser({
  facility,
  user,
}: {
  facility: FacilityIds
  user: Omit<UserAccount, 'isActive'>
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: apiFacilityUserUrl(facility),
    method: 'POST',
    body: JSON.stringify(user),
  })

  return response.data
}

async function updateFacilityUser({
  facility,
  user,
}: {
  facility: FacilityIds
  user: Omit<UserAccount, 'isActive'>
}): Promise<UserAccount> {
  const response = await requestJson({
    url: `${apiFacilityUserUrl(facility)}/${user.id}`,
    method: 'PUT',
    body: JSON.stringify(user),
  })

  return response.data
}

// Create a facility user and add extra groups
export async function createFacilityUserWithMultipleApiCalls({
  orgId,
  userToCreate: user,
  loginUser,
}: {
  orgId: string
  userToCreate: Omit<UserAccount, 'isActive'>
  loginUser: UserAccount
}): Promise<CreateUpdateResponse> {
  const facilityIdsOfGroups = getFacilityIdsFromGroups({
    groups: user.groups!,
    orgId,
  })
  const [firstId, ...otherIds] = facilityIdsOfGroups
  const userToCreate = { ...user }
  userToCreate.groups = userToCreate.groups!.filter(
    (g) => g.personMatcher?.facilityId === firstId
  )

  // Create a new user with first facility group
  const { id: newUserId } = await createFacilityUser({
    facility: { id: firstId, orgId },
    user: userToCreate,
  })

  // Fetch newly-created user
  const allFacilityUsers = await fetchAllFacilityUsers({
    loginUser,
    orgId,
  })
  const newUser = allFacilityUsers.find((u) => u.id === `${newUserId}`)

  // Add more facility groups to the new user
  const promises = otherIds.map((fId) => {
    const userToUpdate = { ...newUser, ...user }
    userToUpdate.groups = userToUpdate.groups!.filter(
      (g) => g.personMatcher?.facilityId === fId
    )
    return updateFacilityUser({
      facility: { id: fId, orgId },
      user: userToUpdate,
    })
  })

  return Promise.all(promises).then(() => ({
    data: { id: newUserId },
    meta: { hello: 'Created' },
  }))
}

// Update user and remove or add extra groups based on login user's available groups
export async function updateFacilityUserWithMultipleApiCalls({
  loginUser,
  orgId,
  updatedUser,
}: {
  loginUser: UserAccount
  orgId: string
  updatedUser: Omit<UserAccount, 'isActive'>
}): Promise<CreateUpdateResponse> {
  const facilityIdsToUpdate = getFacilityIdsFromGroups({
    groups: loginUser.groups!,
    orgId,
    permission: GroupPermission.GROUP_PERMISSION_FACILITY_USER_UPDATE,
  })
  const promises = facilityIdsToUpdate.map((fId) => {
    const userToUpdate = { ...updatedUser }
    userToUpdate.groups = userToUpdate.groups!.filter(
      (g) => g.personMatcher?.facilityId === fId
    )
    return updateFacilityUser({
      facility: { id: fId, orgId },
      user: userToUpdate,
    })
  })

  return Promise.all(promises).then(() => ({ meta: { hello: 'Updated' } }))
}

// Remove all groups one per one based on login user's available groups
export async function removeAllGroupsFromFacilityUser({
  loginUser,
  orgId,
  updatedUser,
}: {
  loginUser: UserAccount
  orgId: string
  updatedUser: UserAccount
}): Promise<CreateUpdateResponse> {
  const facilityIdsToUpdate = getFacilityIdsFromGroups({
    groups: loginUser.groups!,
    orgId,
    permission: GroupPermission.GROUP_PERMISSION_FACILITY_USER_UPDATE,
  })
  const promises = facilityIdsToUpdate.map((fId) => {
    const userToUpdate = { ...updatedUser }
    userToUpdate.groups = []

    return updateFacilityUser({
      facility: { id: fId, orgId },
      user: userToUpdate,
    })
  })

  return Promise.all(promises).then(() => ({ meta: { hello: 'Updated' } }))
}

export async function fetchInternalUsers(): Promise<UserAccount[]> {
  const response = await requestJson({
    url: `${environment.baseUrl}/internalUsers`,
  })

  return response.data
}
