import { ReactNode, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { fetchPerson } from '@shared/api/person'
import {
  OptionTypeBase,
  SelectComponent,
  SingleValue,
} from '@shared/components/Selects/StyledSelect'
import {
  PRIMARY_RELATIONSHIP_LIST,
  RELATIONSHIPS_BY_CATEGORY_MAP,
  ROLE_RELATIONSHIP_LIST,
  SUB_RELATIONSHIP_LIST,
} from '@shared/constants/contactRelationship'
import GlobalContext from '@shared/contexts/GlobalContext'
import { contactsPathForPerson } from '@shared/legacy_routes'
import {
  Contact,
  Contact_ContactRelationship as Relationship,
} from '@shared/types/contact'
import { Person } from '@shared/types/person'
import { isEmptyObject } from '@shared/utils/common'
import { relationshipName } from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { isResident } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import { RelationshipCategory } from '@app/components/AddPersonPopup/RPVerifyInformation/helpers'
import SelectInGenerator from '@app/components/generators/SelectInGenerator'
import PersonContext from '@app/contexts/PersonContext'
import { AddContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'
import { ContactFormData } from '@app/pages/Contacts/ContactModal/types'
import ClearIndicator from './ClearIndicator'
import ContactDropdownOption, { OptionData } from './ContactDropdownOption'
import {
  getDefaultRelationships,
  getRelationshipLabel,
  getRelationshipTypeFromName,
} from './helpers'
import ReadOnlyAndDeletableOption from './ReadOnlyAndDeletableOption'

export interface ContactDropdownProps {
  title?: string
  name: string
  subTitle?: string
  person: Person
  value?: Contact
  onUpdate: (data: any, name: string) => void
  readOnly?: boolean
  unique?: boolean
  relationshipsToExclude?: Relationship | Relationship[]
  relationshipsToInclude?: Relationship | Relationship[]
  onRemove?: (contact: Contact) => void
  inList?: boolean
  contactsToExclude?: Contact[]
  placeholder?: string
  useStandardUpdate?: boolean
  description?: string
  alertMessage?: string
  onlyAllowToAdd?: boolean
  includeContactsPageLink?: boolean
  onAddContact?: (a: number | string) => void
  reloadData: () => void
  required?: boolean
}

export default function ContactDropdown(props: ContactDropdownProps) {
  const {
    title,
    name: dropdownName,
    subTitle,
    value,
    onUpdate,
    readOnly = false,
    unique = false,
    relationshipsToExclude,
    relationshipsToInclude,
    onRemove,
    inList,
    contactsToExclude, // Don't show already selected options
    placeholder: defaultPlaceholder = 'Select contact',
    useStandardUpdate,
    description,
    alertMessage,
    onlyAllowToAdd,
    includeContactsPageLink = false,
    onAddContact,
    reloadData,
    person,
    required,
  } = props
  const history = useHistory()
  const includes: Relationship[] | undefined =
    typeof relationshipsToInclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToInclude]
      : relationshipsToInclude
  const excludes: Relationship[] | undefined =
    typeof relationshipsToExclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToExclude]
      : relationshipsToExclude
  const { setError } = useContext(GlobalContext)
  const { setPerson } = useContext(PersonContext)
  const { id: personId, orgId, facilityId, contact = [] } = person
  // Only option available is Add New Contact, exculde existing contacts
  // when onlyAllowToAdd is specified
  let contactFiltered = onlyAllowToAdd ? [] : contact
  if (Array.isArray(includes)) {
    contactFiltered = contactFiltered.filter(
      (c) => c.relationship && c.relationship.some((r) => includes.includes(r))
    )
  }

  if (Array.isArray(excludes)) {
    contactFiltered = contactFiltered.filter(
      (c) => c.relationship && !c.relationship.some((r) => excludes.includes(r))
    )
  }

  if (!readOnly && Array.isArray(contactsToExclude)) {
    contactFiltered = contactFiltered.filter(
      (c1) =>
        c1.name && !contactsToExclude.some((c2) => c2.id && c1.id === c2.id)
    )
  }

  // Add contact popup related code
  const [openAddContactPopup, setOpenAddContactPopup] = useState(false)
  const [defaultRelationships, setDefaultRelationships] =
    useState<Relationship[]>()

  const showAddContactPopup = (relationType?: Relationship) => {
    if (relationType) {
      setDefaultRelationships(getDefaultRelationships(relationType))
    }
    setOpenAddContactPopup(true)
  }

  const addContactPopupOnSave = (res: {
    id: number | string
  }): Promise<void> | void => {
    // After adding a contact we need to refresh the person before updating the data
    // or the new person won't be an option in the dropdown
    if (orgId && personId && facilityId) {
      return fetchPerson({ orgId, facilityId, personId })
        .then((p) => {
          setPerson(p)
          if (typeof onAddContact === 'function') {
            onAddContact(res.id)
          } else {
            // By default after adding a contact with a specific role, we simply reload
            // the form to update the data
            reloadData()
          }
        })
        .catch(setError)
    }
  }

  // Selected Item component in list with read-only or deletable
  if (inList && !isEmptyObject(value)) {
    return (
      <ReadOnlyAndDeletableOption
        name={dropdownName}
        data={value}
        onRemove={onRemove}
      />
    )
  }

  const selectOptions: OptionTypeBase<string, OptionData>[] =
    contactFiltered.map((contact) => {
      const { id, name = {}, relationship } = contact
      const fullName = getFullName(name)

      return {
        label: fullName,
        subLabel: relationship ? getRelationshipLabel(relationship) : undefined,
        value: id!,
        data: contact,
        isDisabled: readOnly,
      }
    })

  selectOptions.push(
    {
      label: 'Add new contact...',
      value: '',
      data: 'ADD_LINK',
    },
    {
      label: `Edit ${isResident(person) ? 'resident' : 'move-in'} contacts...`,
      value: '',
      data: 'EDIT_LINK',
    }
  )

  const update = (cId: string, name: string, data: OptionData) => {
    if (data === 'ADD_LINK') {
      showAddContactPopup(getRelationshipTypeFromName(dropdownName))
      return
    } else if (data === 'EDIT_LINK') {
      history.push(contactsPathForPerson(person as Required<Person>))
      return
    }

    if (useStandardUpdate) {
      onUpdate(data, name)
      return
    }
    const relationshipType = getRelationshipTypeFromName(name)
    if (relationshipType) {
      if (unique) {
        onUpdate(data, `${name}[0]`) // Responsible Person and Primary Physician
      } else {
        // In ContactDropdownList, only allow to add new relationship
        // Remove is handle inside ContactDropdownList
        const contactIndex = contact.findIndex((c) => c.id === cId)!
        if (contactIndex > -1 && contact[contactIndex]) {
          const currentContact = contact[contactIndex]
          if (currentContact.relationship) {
            currentContact.relationship.push(relationshipType)
          } else {
            currentContact.relationship = [relationshipType]
          }

          onUpdate(currentContact, `contact[${contactIndex}]`)
        }
      }
    }
  }

  let addContactPopup: ReactNode | undefined
  if (openAddContactPopup) {
    const subCategoryRelationship = defaultRelationships?.find((r) =>
      SUB_RELATIONSHIP_LIST.includes(r)
    )
    const defaultFormValues: Partial<ContactFormData> = {
      primaryRelationship: (defaultRelationships?.find((r) =>
        PRIMARY_RELATIONSHIP_LIST.includes(r)
      ) || Relationship.CONTACT_RELATIONSHIP_PERSONAL) as RelationshipCategory,
      secondaryRelationship: subCategoryRelationship
        ? {
            label: relationshipName(subCategoryRelationship as Relationship),
            value: subCategoryRelationship as Relationship,
          }
        : undefined,
      roles: defaultRelationships?.filter((r) =>
        ROLE_RELATIONSHIP_LIST.includes(r)
      ),
    }
    addContactPopup = (
      <AddContactModal
        defaultFormValues={defaultFormValues}
        person={person}
        onClose={async (res) => {
          if (res) {
            await addContactPopupOnSave(res)
          }
          setOpenAddContactPopup(false)
        }}
      />
    )
  }

  const dropdown = (
    <>
      <SelectInGenerator
        title={title}
        subTitle={subTitle}
        description={description}
        name={dropdownName}
        options={selectOptions}
        isClearable={!required}
        value={value?.id}
        isDisabled={readOnly}
        onUpdate={update}
        placeholder={
          selectOptions.length > 1 ? defaultPlaceholder : 'Add new contact'
        }
        alertMessage={alertMessage}
        components={{
          ClearIndicator: ClearIndicator as SelectComponent,
          Option: ContactDropdownOption as SelectComponent,
          SingleValue,
        }}
      />
      {openAddContactPopup && addContactPopup}
    </>
  )

  if (includeContactsPageLink) {
    // TO VERIFY: maybe move this to RpContactDropdown since it only be used in there.
    return (
      <div className={tw`relative`}>
        {dropdown}
        <Link
          className={tw`absolute bottom-[8px] right-[36px]`}
          to={contactsPathForPerson(person as Required<Person>)}
        >
          Edit
        </Link>
      </div>
    )
  }

  return dropdown
}
