import { BillingEvent } from '@shared/types/billing'
import {
  BillingModalType,
  isPendingOneTimeCharge,
  isPendingRecurringCharge,
  transactionCanBeDeleted,
  transactionCanBeUpdated,
} from '@shared/utils/billing'
import { tw } from '@shared/utils/tailwind'
import { DownloadMenu, MenuItem } from '@app/components/DownloadMenu'

export type Props = {
  transaction: BillingEvent
  openTransactionModal: (
    transaction: BillingEvent,
    modalType: BillingModalType
  ) => void
}

export default function ActionMenu({
  openTransactionModal,
  transaction,
}: Props) {
  const isPendingOneTime = isPendingOneTimeCharge(transaction)
  const isPendingRecurring = isPendingRecurringCharge(transaction)
  const canUpdated = transactionCanBeUpdated(transaction)
  const menuItems: MenuItem[] = []

  if (isPendingRecurring) {
    menuItems.push(
      {
        id: 'edit-recurring-option',
        title: 'Update Recurring Charge',
        onClick: () => openTransactionModal(transaction, 'EDIT_CHARGE'),
        isDisabled: !canUpdated,
        isVisible: true,
        iconName: 'pen',
      },
      {
        id: 'end-recurring-option',
        title: 'End Recurring Charge',
        onClick: () => openTransactionModal(transaction, 'END_CHARGE'),
        isDisabled: !canUpdated,
        isVisible: true,
        iconName: 'ban',
      }
    )
  }

  if (isPendingOneTime || isPendingRecurring) {
    menuItems.push({
      id: isPendingOneTime
        ? 'delete-transaction-option'
        : 'cancel-charge-option',
      title: isPendingOneTime
        ? 'Delete transaction'
        : 'Cancel recurring charge',
      onClick: () =>
        openTransactionModal(transaction, 'DELETE_CHARGE_OR_CREDIT'),
      isDisabled: !transactionCanBeDeleted(transaction),
      isVisible: true,
      iconName: 'trash',
    })
  }

  return (
    <DownloadMenu
      menuItems={menuItems}
      menuProps={{
        buttonProps: {
          cta: '',
          initialIcon: 'fa-solid fa-ellipsis-vertical',
          classNames: tw`text-gray-05`,
          buttonSize: 'small',
        },
      }}
      hideCaret
    />
  )
}
