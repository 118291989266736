import { Redirect, Switch } from 'react-router-dom'
import { UserAccount } from '@shared/types/user'
import {
  belongsToMultipleOrgs,
  hasBillingRole,
  hasDirectorRole,
  hasExternalRole,
  hasFacilityAdminRole,
  hasFacilitySalesAndMarketingRole,
  hasFacilityStaffPlusEmarRole,
  hasFacilityStaffRole,
  hasFrontDeskRole,
  hasMedTechRole,
  hasMedTechSupervisorRole,
  hasNurseRole,
  hasOrgAdminRole,
  hasOrganizationUserManagementRole,
  hasPharmacistRole,
  hasResponsiblePersonRole,
  hasSocialWorkerRole,
  isAnalyst,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'
import {
  externalSignerRoute,
  facilitiesRoute,
  facilityRoute,
  orgUserManagementRoute,
  personDetailsRoute,
  prospectRoute,
} from './groupBasedRouting'

export default function RootPathRouter({ user }: { user: UserAccount }) {
  return (
    <Switch>
      {(isToolsUser(user) || isAnalyst(user)) && <Redirect to="/tools" />}
      {(isSuperUser(user) || belongsToMultipleOrgs(user)) && (
        <Redirect to={'/orgs'} />
      )}
      {hasOrgAdminRole(user) && <Redirect to={facilitiesRoute(user)} />}
      {hasDirectorRole(user) && <Redirect to={facilityRoute(user)} />}
      {(hasFacilityAdminRole(user) ||
        hasFacilitySalesAndMarketingRole(user)) && (
        <Redirect to={facilityRoute(user)} />
      )}
      {(hasFacilityStaffPlusEmarRole(user) || hasFacilityStaffRole(user)) && (
        <Redirect to={facilityRoute(user)} />
      )}
      {hasSocialWorkerRole(user) && <Redirect to={personDetailsRoute(user)} />}
      {hasResponsiblePersonRole(user) && <Redirect to={prospectRoute(user)} />}
      {hasBillingRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasPharmacistRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasNurseRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasMedTechSupervisorRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasMedTechRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasFrontDeskRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasExternalRole(user) && <Redirect to={externalSignerRoute(user)} />}
      {hasOrganizationUserManagementRole(user) && (
        <Redirect to={orgUserManagementRoute(user)} />
      )}
    </Switch>
  )
}
