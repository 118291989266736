import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import { AugustError } from '@shared/utils/error'
import { getTemplate } from '@app/api/form'
import { getRequiredFields as getRequiredFieldsFromDisplay } from '../Prospects/Forms/CustomForm/helpers'
import { getRequiredFieldsByTaskType } from '../Prospects/tasksConfig'

export async function getRequiredFields(
  orgId: string,
  facilityId: string,
  taskTemplateInfo: TaskTemplateInfo
) {
  const { taskType } = taskTemplateInfo
  const defaultRequiredFields: string[] = getRequiredFieldsByTaskType(taskType!)

  try {
    const form = await getTemplate({
      orgId,
      facilityId,
      dataType: taskTemplateInfo.dataType!,
      customType: taskTemplateInfo.customType,
    })

    if (form.data.display) {
      return getRequiredFieldsFromDisplay(form.data.display)
    } else {
      return defaultRequiredFields
    }
  } catch (e) {
    const error = e as AugustError
    if (error.status && error.status === 404) {
      return defaultRequiredFields
    } else {
      throw error
    }
  }
}
