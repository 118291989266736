import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import { PersonStats } from '@shared/types/person_stats'
import { isEmptyObject } from '@shared/utils/common'
import { QueuedVital, VitalsFormData } from '@shared/utils/vitals'
import { createVitalNote, updateVitalNote } from '@app/api/incidents'
import { createVitals, mergePatchVitals } from '@app/api/vitals'

export const createOrUpdateVitals = async ({
  data,
  sortedPeopleStats,
  visiblePersonId,
  currentVitals,
  setCurrentVitals,
}: {
  data: VitalsFormData
  sortedPeopleStats: PersonStats[]
  visiblePersonId: string | undefined
  currentVitals: QueuedVital[]
  setCurrentVitals: React.Dispatch<React.SetStateAction<QueuedVital[]>>
}): Promise<{ id?: string | number }> => {
  const vitalsData = { ...data, date: undefined, time: undefined }
  if (isEmptyObject(vitalsData)) {
    return Promise.resolve({})
  }
  const vitals = formDataToVitalsPayload(data)

  if (isEmptyObject(vitals.panel)) {
    return Promise.resolve({})
  }

  const person = sortedPeopleStats.find(
    (ps) => ps.person!.id === visiblePersonId
  )!.person
  const currentVitalQueue =
    currentVitals.find((cv) => cv.personId === visiblePersonId)?.queue ??
    Promise.resolve({ id: undefined, noteId: undefined })

  const { id, noteId } = await currentVitalQueue

  // TODO: Clean this up. I've commented it rather than refactoring for now.
  let newPromise: Promise<{ id?: string | number; noteId?: string }>
  if (id) {
    // Vitals have been saved, note has not been created, but there is note text
    if (noteId === undefined && data.note) {
      const noteText = data.note
      newPromise = mergePatchVitals(person, {
        ...vitals,
        id: id.toString(),
      }).then(async (vital) => {
        const newNote = await createVitalNote({
          noteText,
          person,
          vital,
        })

        return {
          id: vital.id,
          noteId: newNote.id.toString(),
        }
      })
      // Either no note text, or existing note that needs updating
    } else {
      newPromise = mergePatchVitals(person, {
        ...vitals,
        id: id.toString(),
      }).then(async (vital) => {
        if (data.note) {
          await updateVitalNote({
            noteText: data.note!,
            person,
            incidentId: noteId!,
          })
        }

        return {
          id: vital.id,
          noteId,
        }
      })
    }
    // No existing vitals, maybe a note to create.
  } else {
    newPromise = createVitals(person, vitals).then(async ({ id }) => {
      if (data.note) {
        const newNote = await createVitalNote({
          noteText: data.note,
          person,
          vital: { ...vitals, id: id.toString() },
        })
        return {
          id,
          noteId: newNote.id.toString(),
        }
      } else {
        return {
          id,
          noteId: undefined,
        }
      }
    })
  }

  setCurrentVitals((prev) => {
    return prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return {
          ...vitals,
          personId: person.id,
          status: v.status,
          queue: newPromise,
          note: data.note,
        } as QueuedVital
      } else {
        return v
      }
    })
  })

  return newPromise
}

export function modifyVitalFor(
  visiblePersonId: string,
  modifyVital: (current: QueuedVital) => QueuedVital
): (prev: QueuedVital[]) => QueuedVital[] {
  return (prev) =>
    prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return modifyVital(v)
      } else {
        return v
      }
    })
}
