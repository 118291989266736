import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import StyledSelect, {
  baseSelectControlStyles,
} from '@shared/components/Selects/StyledSelect'
import { twx } from '@shared/utils/tailwind'
import {
  DateError,
  DateOfBirthProps,
  MONTH_OPTIONS,
  SelectOption,
} from './interfaces'
import {
  allowNumberInputOnly,
  getDefaultBirthday,
  getInvalidBirthdayError,
} from './utils'

export default function DateOfBirth({
  allowEmpty = false,
  value,
  onUpdate,
  setError,
  name,
  acceptDateObject = false,
  sendUpdateOnError = false,
  updateOnEachKeyUp = false,
  menuPlacement = 'bottom',
}: DateOfBirthProps) {
  const mountedRef = useRef<boolean>(false)
  const fieldsetRef = useRef(null)
  const [birthday, setBirthday] = useState<DateMessage>(
    getDefaultBirthday(value)
  )
  const [dateError, setDateError] = useState<DateError | undefined>()

  useEffect(() => {
    setBirthday(getDefaultBirthday(value))
  }, [value])

  useEffect(() => {
    if (mountedRef.current) {
      // Only run allowEmpty change, not initial load
      const error = getInvalidBirthdayError(birthday, allowEmpty)
      setDateError(error)
    }
  }, [allowEmpty])

  useEffect(() => {
    mountedRef.current = true
  }, [])

  useEffect(() => {
    const fieldset = fieldsetRef?.current as HTMLFieldSetElement | null
    if (fieldset && fieldset.elements.length) {
      // Use fieldset['select'] instead of fieldset.select to avoid typescript warning
      // FieldSet doesn't have native .select event so provide a customized one
      // Useful when automatically focus on UI on Submit when required DateOfBirth not found
      fieldset['select'] = () => {
        const firstInput = fieldset.elements[0] as HTMLInputElement
        firstInput.focus()
        firstInput.select()
      }
    }
  }, [fieldsetRef])

  const update = (bDay: DateMessage) => {
    const error = getInvalidBirthdayError(bDay, allowEmpty)
    setDateError(error)
    setBirthday(bDay)

    if (error) {
      setError && setError(error)
      if (sendUpdateOnError) {
        onUpdate(bDay)
      }
      return
    } else {
      setError && setError()
    }

    const { year, month, day } = bDay

    // generated forms pass in the name prop
    if (acceptDateObject) {
      onUpdate(bDay)
    } else if (year !== undefined && month !== undefined && day !== undefined) {
      onUpdate(new Date(year, month - 1, day))
    }
  }

  const handleChange = (
    event:
      | SelectOption
      | ChangeEvent<HTMLInputElement>
      | KeyboardEvent<HTMLInputElement>,
    field: 'month' | 'day' | 'year'
  ) => {
    let value
    // react-select holds the value directly on the event
    if ('value' in event) value = event.value
    if ('target' in event) {
      const target = event.target as HTMLInputElement
      value = target.value
    }
    // allow numerics only, and whitespaces
    // value will be a number from Month dropdown and text input for Day and Year
    if (!isNaN(+value)) {
      if (typeof value === 'string' && value.trim() === '') {
        update({ ...birthday, [field]: undefined })
      } else {
        // make sure the input strings are numbers
        update({ ...birthday, [field]: +value })
      }
    }
  }

  const selectedMonth =
    birthday.month !== undefined
      ? MONTH_OPTIONS.find((m) => m.value === birthday.month)
      : undefined

  let hasYearError = false
  let hasDayError = false
  if (dateError) {
    hasYearError = Boolean(dateError.year || dateError.misc)
    hasDayError = Boolean(dateError.day || dateError.misc)
  }

  const dayClassNames = twx('w-[75px] ml-[16px]', {
    ['border-urgent-color']: hasDayError,
  })

  const yearClassNames = twx('w-[100px] ml-[16px]', {
    ['border-urgent-color']: hasYearError,
  })

  return (
    <fieldset name={name} ref={fieldsetRef}>
      <legend className="visually-hidden">Date of Birth</legend>
      <section className={twx('flex w-[60%] flex-row')}>
        <StyledSelect
          name="birthday-month"
          id="birthday-month"
          placeholder="Month"
          value={selectedMonth}
          options={MONTH_OPTIONS}
          openMenuOnFocus={true}
          instanceId="month"
          onChange={(event: SelectOption) => {
            handleChange(event, 'month')
          }}
          menuPlacement={menuPlacement}
          styles={customStyles}
        />
        <label htmlFor="birthday-month" className="visually-hidden">
          Month
        </label>
        <BasicInput
          className={dayClassNames}
          placeholder="Day"
          name="birthday-day"
          data-testid="birthday-day"
          defaultValue={birthday.day || ''}
          onKeyDown={allowNumberInputOnly}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (updateOnEachKeyUp || event.key === 'Enter')
              handleChange(event, 'day')
          }}
          onBlur={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, 'day')
          }}
        />
        <label htmlFor="birthday-day" className="visually-hidden">
          Day
        </label>
        <BasicInput
          className={yearClassNames}
          placeholder="Year"
          name="birthday-year"
          data-testid="birthday-year"
          defaultValue={birthday.year || ''}
          onKeyDown={allowNumberInputOnly}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (updateOnEachKeyUp || event.key === 'Enter')
              handleChange(event, 'year')
          }}
          onBlur={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, 'year')
          }}
        />
        <label htmlFor="birthday-year" className="visually-hidden">
          Year
        </label>
      </section>
    </fieldset>
  )
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles('medium', state),
    width: '145px',
  }),
}
