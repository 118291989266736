import {
  DosageType,
  MedicationStatement,
} from '@augusthealth/models/com/august/protos/medication_statement'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import {
  DIY_SCHEDULE_TYPE_OPTIONS,
  isUnknownDosageType,
  SPLIT_SCHEDULE_TYPE_OPTIONS,
  TAPER_SCHEDULE_TYPE_OPTIONS,
  UnknownDosageType,
} from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import {
  buildNewDose,
  getMedOrderDosageType,
  handleChangingDoseType,
  mapFormMedToMedOrder,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { DoseLevelInstructions } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DoseLevelInstructions'
import {
  isPrnDosageType,
  isRoutineDosageType,
  isSlidingScaleDosageType,
  isTaperDosageType,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import { PRNForm } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/PRNSchedule/PRNForm'
import { RoutineForm } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/RoutineSchedule/RoutineForm'
import {
  FooterMode,
  getFooterMode,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/helpers'
import { ScheduleFooter } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/ScheduleFooter'
import { ScheduleHeader } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleHeader'
import { SchedulePartWrapper } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/SchedulePartWrapper'
import { SlidingScaleForm } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/SlidingScaleSchedule/SlidingScaleForm'
import { updateTaperDoseDurations } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'
import { TaperForm } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/TaperForm'

type Props = {
  canAddOrRemoveDoses: boolean
  readOnly: boolean
  order: Partial<MedicationOrder>
  isNewOrder: boolean
}

export const MedicationOrderDoseSchedules = (props: Props) => {
  const { readOnly, order, canAddOrRemoveDoses, isNewOrder } = props
  const methods = useFormContext<MedOrderFormData>()
  const { formState, watch, control } = methods

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'doses',
  })

  const medOrderFormData = watch()

  const wholeOrderDoseType = getMedOrderDosageType(
    mapFormMedToMedOrder({
      formData: medOrderFormData,
      medOrder: order,
    }).medicationStatement as MedicationStatement
  )
  const numberOfDoses = medOrderFormData.doses?.length ?? 1
  const userSetDosageType = medOrderFormData.doses?.[0]?.userSetDosageType
  const firstDoseType: Exclude<DosageType, UnknownDosageType> =
    isUnknownDosageType(userSetDosageType)
      ? DosageType.DOSAGE_TYPE_ROUTINE
      : userSetDosageType

  const updateDoseType = (newDoseType: DosageType, index: number) => {
    return handleChangingDoseType({
      newDoseType,
      index,
      methods,
      isNewOrder,
    })
  }

  const removeDose = (doseIndex: number) => {
    remove(doseIndex)

    if (isTaperDosageType(firstDoseType)) {
      updateTaperDoseDurations(methods)
    }
  }

  const addDose = () => {
    append(
      buildNewDose({
        formData: medOrderFormData,
        userSetDosageType: isTaperDosageType(firstDoseType)
          ? DosageType.DOSAGE_TYPE_TAPER
          : DosageType.DOSAGE_TYPE_ROUTINE,
      })
    )

    if (isTaperDosageType(firstDoseType)) {
      updateTaperDoseDurations(methods)
    }
  }

  const footerMode: FooterMode = getFooterMode({
    medDoseType: wholeOrderDoseType,
    methods,
  })

  return (
    <div className={twx('[&>*:not(:first-child)]:mt-4')}>
      {fields.map((schedulePart, index) => {
        const key = `dose-${schedulePart.tempId}`
        const requiredDocumentationName =
          `doses.${index}.requiredInjectionOrApplicationSitePrompt` as const
        const requiredDocumentationError =
          formState.errors?.doses?.[index]
            ?.requiredInjectionOrApplicationSitePrompt ?? null
        const selectedType = watch(`doses.${index}.userSetDosageType`)

        const onRemove = canAddOrRemoveDoses
          ? () => removeDose(index)
          : undefined

        const doseTypeOptions =
          index === 0
            ? DIY_SCHEDULE_TYPE_OPTIONS
            : isTaperDosageType(firstDoseType)
              ? TAPER_SCHEDULE_TYPE_OPTIONS
              : SPLIT_SCHEDULE_TYPE_OPTIONS

        const nonTaperNoteName =
          numberOfDoses > 1 ? (`doses.${index}.note` as const) : null

        return (
          <SchedulePartWrapper key={key}>
            <ScheduleHeader
              readOnly={readOnly}
              onRemove={onRemove}
              options={doseTypeOptions}
              order={order}
              onChangeDoseType={updateDoseType}
              forceSelection={selectedType}
              index={index}
            />
            <DoseLevelInstructions doseIndex={index} readOnly={readOnly} />
            {isRoutineDosageType(selectedType) && (
              <>
                <RoutineForm doseIndex={index} readOnly={readOnly} key={key} />
                <ScheduleFooter
                  readOnly={readOnly}
                  mode={{
                    tag:
                      numberOfDoses > 1
                        ? `partial-${DosageType.DOSAGE_TYPE_SPLIT}`
                        : `partial-${DosageType.DOSAGE_TYPE_ROUTINE}`,
                    doseIndex: index,
                    noteName: nonTaperNoteName,
                    requiredDocumentationName,
                    requiredDocumentationError,
                  }}
                />
              </>
            )}
            {isPrnDosageType(selectedType) && (
              <>
                <PRNForm doseIndex={index} readOnly={readOnly} key={key} />
                <ScheduleFooter
                  readOnly={readOnly}
                  mode={{
                    tag:
                      numberOfDoses > 1
                        ? `partial-${DosageType.DOSAGE_TYPE_SPLIT}`
                        : `partial-${DosageType.DOSAGE_TYPE_PRN}`,
                    doseIndex: index,
                    noteName: nonTaperNoteName,
                    requiredDocumentationName,
                    requiredDocumentationError,
                  }}
                />
              </>
            )}
            {isTaperDosageType(selectedType) && (
              <TaperForm
                numberOfDoses={numberOfDoses}
                doseIndex={index}
                readOnly={readOnly}
                key={key}
                diySchedule={true}
              />
            )}
            {isSlidingScaleDosageType(selectedType) && (
              <>
                <SlidingScaleForm readOnly={readOnly} doseIndex={index} />
                <ScheduleFooter
                  readOnly={readOnly}
                  mode={{
                    tag:
                      numberOfDoses > 1
                        ? `partial-${DosageType.DOSAGE_TYPE_SPLIT}`
                        : `partial-${DosageType.DOSAGE_TYPE_SLIDING_SCALE}`,
                    doseIndex: index,
                    noteName: nonTaperNoteName,
                    requiredDocumentationName,
                    requiredDocumentationError,
                  }}
                />
              </>
            )}
          </SchedulePartWrapper>
        )
      })}
      {canAddOrRemoveDoses && (
        <AsyncIconButton
          buttonStyle={'primary-outline'}
          initialIcon={'fa-regular fa-circle-plus'}
          width={'100%'}
          className={'mt-[16px]'}
          onClick={addDose}
        >
          Add Another Part
        </AsyncIconButton>
      )}
      <ScheduleFooter readOnly={readOnly} mode={footerMode} />
    </div>
  )
}
