import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { StateMachineType } from '@augusthealth/models/com/august/protos/task'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { DataType } from '@shared/types/snapshot'
import notEmpty from '@shared/utils/notEmpty'
import { getTemplate } from '@app/api/form'
import { CustomFormContextProvider } from '@app/components/Prospects/Forms/CustomForm/CustomFormContext'
import FormLayout from '../FormLayout'
import { FormPageProps } from '../FormLayout/type'
import { convertToPageGeneratorItem } from './helpers'

interface CustomFormProps extends FormPageProps {
  defaultForm: JSX.Element
  dataType: DataType
  customType?: string
  stateMachineType?: StateMachineType
}

export default function CustomForm(props: CustomFormProps) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { match, dataType, customType } = props
  const { params } = match

  const [loading, setLoading] = useState<boolean>(false)
  const [pdfTemplate, setPdfTemplate] = useState<SignableForm>({})

  useEffect(() => {
    setLoading(true)
    getTemplate({
      orgId: params.orgId,
      facilityId: params.facilityId,
      dataType,
      customType,
    })
      .then((res) => setPdfTemplate(res.data))
      .catch((err) => setError({ ...err }))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return null
  }

  if (pdfTemplate.display) {
    const display = pdfTemplate.display
    const freeTextFields = (pdfTemplate.template?.preFilledFields || [])
      .map((f) => f.customField)
      .filter(notEmpty)
    const pages = convertToPageGeneratorItem(
      display,
      freeTextFields,
      dataType,
      customType,
      user
    )
    return (
      <CustomFormContextProvider value={pdfTemplate}>
        <FormLayout
          {...props}
          title={display.title!}
          configuration={pages}
          returnUpdatedAttributeOnly
        />
      </CustomFormContextProvider>
    )
  } else {
    return props.defaultForm
  }
}
