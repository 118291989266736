import { CustomDetailsField } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import {
  AppraisalSettings_AppraisalCategory as AppraisalCategory,
  AppraisalSettings_AppraisalCategoryDetail as AppraisalDetail,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { intersection } from 'lodash'
import { useEffect, useState } from 'react'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { InlineFormSelect } from '@shared/components/Selects/StyledSelect'
import notEmpty from '@shared/utils/notEmpty'
import { getDetailsPath } from '@shared/utils/residentAssessment'
import { AssessmentChange } from '../types'
import { DetailWithDropdown, formatScore } from './utils'

export default function DropdownDetail({
  category,
  detailData,
  disabled,
  onChange,
  allSelectedDetails,
  selectedCustomDetails,
  selfUpdating = false,
}: {
  category: AppraisalCategory
  detailData: DetailWithDropdown
  disabled: boolean
  onChange: (change: AssessmentChange) => void
  allSelectedDetails: string[]
  selectedCustomDetails: CustomDetailsField[]
  selfUpdating?: boolean
}) {
  const { detailMetadata } = detailData.value
  const options = detailData.value.options.map((o) => ({
    label: o.description!,
    value: o,
  }))
  const hasEnumOptions = options.some(
    (o) => o.value.customDetails === undefined
  )

  const customOptionIDs = detailData.value.options
    .map((o) => o.id)
    .filter(notEmpty)
  const optionEnumValues = hasEnumOptions
    ? detailData.value.options
        .map((v) => v[getDetailsPath(category)])
        .filter(notEmpty)
    : []

  const selectedCustomOption = intersection(
    customOptionIDs,
    selectedCustomDetails.map((cd) => cd.id)
  )[0]
  const selectedEnumOption = intersection(
    optionEnumValues,
    allSelectedDetails
  )[0]
  const selectedOption = options.find((o) => {
    if (o.value.id) {
      return o.value.id === selectedCustomOption
    } else {
      return o.value[getDetailsPath(category)] === selectedEnumOption
    }
  })

  const [checked, setChecked] = useState(!!selectedOption)
  const [currentValue, setCurrentValue] = useState(selectedOption ?? options[0])

  useEffect(() => {
    if (selfUpdating) {
      if (selectedOption) {
        setChecked(true)
        setCurrentValue(selectedOption)
      } else {
        setChecked(false)
      }
    }
  }, [selectedCustomDetails])

  return (
    <div className={'flex items-center'} style={{ marginBottom: '9px' }}>
      <BasicCheckbox
        data-testid={'detail-dropdown'}
        disabled={disabled}
        checked={checked}
        onChange={() => {
          if (!checked) {
            onChange({
              tag: 'AddUniversal',
              detail: detailData,
              chosen: currentValue.value,
              category,
            })
          } else {
            onChange({
              tag: 'RemoveUniversal',
              detail: detailData,
              category,
            })
          }

          setChecked(!checked)
        }}
        labelClassName={'mb-0 mr-[8px]'}
      >
        {detailMetadata.description}
      </BasicCheckbox>
      <InlineFormSelect
        isDisabled={!checked || disabled}
        options={options}
        defaultValue={currentValue}
        onChange={(newValue: { label: string; value: AppraisalDetail }) => {
          setCurrentValue(newValue)
          onChange({
            tag: 'AddUniversal',
            detail: detailData,
            chosen: newValue.value,
            category,
          })
        }}
      />
      <span className={'ml-[8px] font-medium leading-[16px]'}>
        {formatScore(currentValue.value)}
      </span>
    </div>
  )
}
