import { GroupBase, StylesConfig } from 'react-select'
import {
  baseSelectControlStyles,
  errorBorderControlStyles,
  selectStyles,
} from '@shared/components/Selects/StyledSelect'

export const ErrorDropdownStyleConfig = ({
  error,
  width,
}: {
  error: boolean
  width?: string
}): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  const widthStyle = width ?? 'fit-content'
  return {
    ...selectStyles(),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      ...errorBorderControlStyles(error, state),
      minWidth: '96px',
      width: widthStyle,
    }),
    menu: (provided) => ({
      ...provided,
      width: widthStyle,
    }),
  }
}

export const BadgeSelectStyleConfig = ({
  inputName,
}: {
  inputName: string
}): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  const labelElement = document.getElementById(`label-${inputName}`)
  const labelElementWidth = labelElement
    ? labelElement.getBoundingClientRect().width + 8
    : 20
  return {
    ...selectStyles(),
    container: (provided) => ({
      ...provided,
      overscrollBehavior: 'contain',
    }),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      borderRadius: '0',
      minWidth: '96px',
      width: '100%',
      height: '100%',
      borderWidth: '0px',
      boxShadow: 'none',
      outline: 'none',
      '&:hover': {
        borderWidth: '0px',
        borderColor: 'transparent',
        boxShadow: 'none',
      },
    }),
    menu: (provided) => ({
      ...provided,
      overscrollBehavior: 'contain',
      width: `calc(100% + ${labelElementWidth}px)`,
      right: '0',
    }),
  }
}
