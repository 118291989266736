import { SignableFormRequest } from '@augusthealth/models/com/august/protos/api/esign'
import { SetRequired } from 'type-fest'
import {
  fetchBlobUrlAndContentType,
  request,
  requestJson,
} from '@shared/api/request'
import environment from '@shared/environment'
import {
  FormDisplay,
  PdfProj,
  SignableForm,
  SignableFormAssignment,
  SignableFormMetaData,
  SignableFormWithAssignments,
} from '@shared/types/signable_form'
import { DataType, SignerRole } from '@shared/types/snapshot'
import { DeepNull } from '@shared/types/utilities'
import { StateAbbreviation } from '@shared/utils/state'

const formUrl = `${environment.baseUrl}/forms`

type CreateForm = {
  name: string
  description?: string
  file: File
  projectionType: PdfProj
  copyFromSignableFormId?: string
  roles: SignerRole[]
  roleDescriptions: string[]
}

export function createForm(props: CreateForm) {
  const {
    name,
    description,
    file,
    copyFromSignableFormId,
    roles,
    projectionType,
    roleDescriptions,
  } = props
  const body: FormData = new FormData()
  const data: SignableFormRequest = {
    name,
    projectionType,
    description,
    copyFromSignableFormId,
    embeddedTemplateRequest: {
      signers: roles.map((role, roleIndex) => {
        return { role, roleIndex, description: roleDescriptions[roleIndex] }
      }),
    },
  }

  body.append('file', file)
  body.append('data', JSON.stringify(data))

  return request({
    url: formUrl,
    method: 'POST',
    body,
  })
}

export function getForm(formId: string): Promise<SignableForm> {
  return requestJson({ url: `${formUrl}/${formId}` }).then((res) => res.data)
}

export async function getFormWithAssignments(
  limit: number
): Promise<SignableFormWithAssignments[]> {
  const response = await requestJson({
    url: `${environment.baseUrl}/formsWithAssignments?limit=${limit}`,
  })

  return response.data
}

export function getHelloSignInfo({ id }) {
  return requestJson({ url: `${formUrl}/${id}/url` })
}

export function deleteForm({ id }) {
  return requestJson({ url: `${formUrl}/${id}`, method: 'DELETE' })
}

export function getFormPdfPreviewUrl({ id, orgId, facilityId, personId }) {
  return `${formUrl}/${id}/${orgId}/${facilityId}/${personId}.pdf`
}

export function getFormPdfUrl({
  orgId,
  facilityId,
  personId,
  dataType,
  customType,
}: {
  orgId: string
  facilityId: string
  personId: string
  dataType: DataType
  customType?: string
}) {
  const params = customType
    ? `?customType=${encodeURIComponent(customType)}`
    : ''
  return `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/forms/${dataType}.pdf${params}`
}

export function publishForm({
  id,
}: {
  id: string
}): Promise<SignableFormWithAssignments> {
  return requestJson({
    url: `${formUrl}/${id}/publish`,
    method: 'PUT',
  }).then((res) => res.data)
}

type SetFormDisplay = {
  id: string
  body: FormDisplay
}

export function setFormDisplay({ id, body }: SetFormDisplay) {
  return requestJson({
    url: `${formUrl}/${id}/display`,
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

interface TemplateProps {
  orgId: string
  facilityId: string
  dataType: DataType
  customType?: string
}
export function getTemplate(
  props: TemplateProps
): Promise<ResponseData<SignableForm>> {
  const { orgId, facilityId, dataType, customType } = props
  return requestJson({
    url: `${
      environment.baseUrl
    }/organizations/${orgId}/facilities/${facilityId}/forms/${dataType}/template${
      customType ? `?customType=${encodeURIComponent(customType)}` : ''
    }`,
  })
}

interface ResponseData<T> {
  data: T
  meta?: any
}

interface AssignmentProps {
  facilityId?: string
  dataType: DataType
  customType?: string
  orgId?: string
  signableFormId: string
  state?: string
}

export type FormAssignmentApiUrl = Omit<
  SetRequired<SignableFormAssignment, 'dataType'>,
  'signableFormId' | 'id'
>
function getFormAssignmentApiUrl(props: FormAssignmentApiUrl) {
  const { orgId, facilityId, dataType, customType, state } = props
  const url = `${environment.baseUrl}/form_assignments/${dataType}`
  const queryStringList: string[] = []
  if (orgId) {
    queryStringList.push(`orgId=${orgId}`)
  }
  if (facilityId) {
    queryStringList.push(`facilityId=${facilityId}`)
  }
  if (customType) {
    queryStringList.push(`customType=${encodeURIComponent(customType)}`)
  }
  if (state) {
    queryStringList.push(`state=${state}`)
  }
  const queryString = queryStringList.join('&')
  if (url) {
    return `${url}?${queryString}`
  }

  return url
}

export function createFormAssignment(props: AssignmentProps) {
  const { orgId, facilityId, signableFormId, customType, dataType, state } =
    props
  const body = {
    signable_form_id: signableFormId,
    org_id: orgId,
    facility_id: facilityId,
    custom_type: customType,
    state,
  }

  return requestJson({
    url: getFormAssignmentApiUrl({ dataType }),
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function updateFormAssignment(
  assignment: SetRequired<
    SignableFormAssignment,
    'id' | 'signableFormId' | 'dataType'
  >
) {
  return requestJson({
    url: getFormAssignmentApiUrl(assignment),
    method: 'PUT',
    body: JSON.stringify(assignment),
  })
}

export function deleteFormAssignment(props: FormAssignmentApiUrl) {
  return requestJson({ url: getFormAssignmentApiUrl(props), method: 'DELETE' })
}

/**
 * This will load all assignments for an organization - NOT the most specific ones!
 * It returns global, state, state/org, and facility level assignments that are
 * relevant for the given orgId.
 * @param orgId
 */
export async function getAssignmentsByOrg(
  orgId: string
): Promise<SignableFormAssignment[]> {
  const response = await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/form_assignments?limit=99999`,
  })

  return response.data
}

export async function getGlobalAssignments(): Promise<
  SignableFormAssignment[]
> {
  const response = await requestJson({
    url: `${environment.baseUrl}/form_assignments?limit=99999`,
  })

  return response.data
}

export async function getAssignmentsForState(
  stateAbbreviation: StateAbbreviation
): Promise<SignableFormAssignment[]> {
  const response = await requestJson({
    url: `${environment.baseUrl}/form_assignments?limit=99999&state=${stateAbbreviation}`,
  })

  return response.data
}

export async function patchForm({
  id,
  patch,
}: {
  id: string
  patch: DeepNull<SignableFormMetaData>
}): Promise<SignableFormMetaData> {
  const response = await requestJson({
    url: `${formUrl}/${id}`,
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify(patch),
  })

  return response.data
}

export async function getFormImage({
  signableFormId,
  key,
}: {
  signableFormId: string
  key: string
}): Promise<string> {
  const image = await fetchBlobUrlAndContentType({
    url: `${formUrl}/${signableFormId}/images/${key}`,
  })

  return image.url
}
