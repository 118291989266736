import Inputmask from 'inputmask'
import { useEffect, useRef } from 'react'
import TextInputWithIcon, {
  Props,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import styles from './styles.module.css'
import { convertCentsToDollars, convertDollarsToCents } from './utils'

// Used in AdmissionsAgreement form via the form generator
export function MoneyInputOld({ inputProps, holderClassName }: Props) {
  const { className, placeholder = '0.00' } = inputProps
  const classNameList = [styles.money]
  if (className) {
    classNameList.push(className)
  }

  const props = {
    type: 'number',
    step: 0.01,
    ...inputProps,
    placeholder,
    className: classNameList.join(' '),
  }

  return (
    <TextInputWithIcon
      holderClassName={holderClassName}
      inputProps={props}
      iconClassName="fas fa-fw fa-usd-square"
    />
  )
}

export function MoneyInput({
  inputProps,
  holderClassName,
  onChange,
  saveAsNegative,
  allowNegativeNumbers,
  ...rest
}: Props & {
  onChange?: (value: number | undefined) => void
  saveAsNegative?: boolean
  allowNegativeNumbers?: boolean
}) {
  const internalRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (internalRef.current) {
      Inputmask({
        alias: 'currency',
        placeholder: '0',
        numericInput: true,
        allowMinus: allowNegativeNumbers,
        onBeforeMask: (v) => {
          return convertCentsToDollars({ value: v })
        },
        shortcuts: null,
      }).mask(internalRef.current)
    }
  }, [])

  const handleChange = (e) => {
    const dollarvalue = e.target.value
    const cents = convertDollarsToCents({
      value: dollarvalue.replaceAll(',', ''),
      saveAsNegative,
    })
    if (onChange) {
      onChange(cents)
    }
  }
  return (
    <TextInputWithIcon
      inputProps={{
        ...rest,
        ...inputProps,
        onChange: handleChange,
        ref: internalRef,
        placeholder: '0.00',
      }}
      holderClassName={holderClassName}
      iconClassName="fas fa-fw fa-usd-square"
    />
  )
}
