import { useHistory } from 'react-router-dom'
import Icon from '@shared/components/Icon'
import { billingStatementPreviewRoute } from '@shared/routes'
import { BillingEvent, BillingEventIdType } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import {
  BillingModalType,
  formatCurrencyForBilling,
  transactionCanBeDeleted,
  transactionCanBeUpdated,
} from '@shared/utils/billing'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { convertISODateStringsToLabel } from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'
import ActionMenu from './ActionMenu'

export default function TransactionRow({
  personIds,
  openTransactionModal,
  transaction,
}: {
  personIds: RequiredPersonIds
  openTransactionModal: (
    transaction: BillingEvent,
    modalType: BillingModalType
  ) => void
  transaction: BillingEvent
}) {
  const history = useHistory()
  const {
    id,
    serviceStartDate,
    serviceEndDate,
    description,
    transactionType,
    amountCents,
    balanceCents,
    pending,
    idType,
  } = transaction
  const { orgId, facilityId, id: personId } = personIds
  const canEdit =
    transactionCanBeDeleted(transaction) || transactionCanBeUpdated(transaction)
  const isInvoice = idType === BillingEventIdType.INVOICE

  // Don't apply pendingClass to TD containing ActionMenu so dropdown can be displayed correctly
  const pendingClass = twx({ 'opacity-50': pending })

  return (
    <TrWithHoverEffect
      disableHoverEffect={!isInvoice}
      onClick={
        isInvoice
          ? () => {
              history.push(
                billingStatementPreviewRoute(orgId, facilityId, personId, id)
              )
            }
          : undefined
      }
    >
      <TdWithExtraPadding>
        <div className={tw`whitespace-nowrap`}>
          <Icon
            name={isInvoice ? 'file-invoice-dollar' : 'circle-dollar'}
            className={twx('mr-[8px] text-gray-08', {
              'text-primary': isInvoice,
            })}
            variant={isInvoice ? 'solid' : 'regular'}
          />
          <span className={twx(pendingClass)}>
            {convertISODateStringsToLabel([serviceStartDate, serviceEndDate])}
          </span>
        </div>
      </TdWithExtraPadding>
      <TdWithExtraPadding className={pendingClass}>
        {description}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={pendingClass}>
        {convertEnumValueToLabel(transactionType)}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={pendingClass}>
        {amountCents ? formatCurrencyForBilling(amountCents) : ''}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={pendingClass}>
        {formatCurrencyForBilling(balanceCents)}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={tw`text-center`}>
        {isInvoice && (
          <span
            className={tw`leading-[12px] text-gray-07 underline hover:font-semibold hover:text-gray-07`}
          >
            view
          </span>
        )}
        {canEdit && (
          <ActionMenu
            transaction={transaction}
            openTransactionModal={openTransactionModal}
          />
        )}
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
