import { useContext, useState } from 'react'
import { getFacilityBillingStatementsUrl } from '@shared/api/billing'
import { download } from '@shared/api/legacy'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ResidentListEntry } from '@shared/types/billing'
import { FacilityIds } from '@shared/types/facility'
import { canDraftStatement, isSuccessfullyIssued } from '@shared/utils/billing'
import { getOrElse, Loading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import { tw } from '@shared/utils/tailwind'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import CloseBooksModal from '@app/pages/Facilities/Billing/Modals/CloseBooksModal'
import DraftBulkStatementsModal from '@app/pages/Facilities/Billing/Modals/InvoiceModals/DraftBulkStatementsModal'
import IssueInvoicesModal from '@app/pages/Facilities/Billing/Modals/InvoiceModals/IssueStatementsModal'
import RecordPayments from '@app/pages/Facilities/Billing/Modals/RecordPayments'
import {
  getSearchedResidents,
  getSortedResidents,
  ResidentsColumnName,
} from '../helpers'
import BillingResidentRow from './BillingResidentRow'

enum ModalType {
  DRAFT_STATEMENTS = 'Draft statements',
  SEND_STATEMENTS = 'Send statements',
  RECORD_PAYMENTS = 'Record payments',
  PRINT_STATEMENTS = 'Print statements',
  CLOSE_BOOKS = 'Close the books',
}

const headers = [
  {
    label: ResidentsColumnName.RESIDENT,
    className: tw`w-[30%]`,
  },
  {
    label: ResidentsColumnName.ROOM,
    className: tw`w-[10%]`,
  },
  {
    label: ResidentsColumnName.UPDATES,
    className: tw`w-[10%]`,
  },
  { label: ResidentsColumnName.CURRENT_BALANCE },
  { label: ResidentsColumnName.STATEMENT_BALANCE },
  { label: ResidentsColumnName.STATEMENT_STATUS },
]

function isIssueable(resident: ResidentListEntry) {
  return resident.lastInvoice && !isSuccessfullyIssued(resident.lastInvoice)
}

function iconForModalType(modalType: ModalType) {
  switch (modalType) {
    case ModalType.DRAFT_STATEMENTS:
      return 'file-invoice-dollar'
    case ModalType.SEND_STATEMENTS:
      return 'envelope'
    case ModalType.PRINT_STATEMENTS:
      return 'print'
    case ModalType.RECORD_PAYMENTS:
      return 'credit-card-front'
    case ModalType.CLOSE_BOOKS:
      return 'box-archive'
  }
}

export default function ResidentsTable({
  facilityIds,
  residents,
  reloadResidentList,
}: {
  facilityIds: FacilityIds
  residents: Loading<ResidentListEntry[]>
  reloadResidentList: (hudMsg?: string) => Promise<void>
}) {
  const { orgId, id: facilityId } = facilityIds
  const { setError } = useContext(GlobalContext)
  const billingResidents = getOrElse(residents, [])
  const canDraft = billingResidents.some(canDraftStatement)
  const [invoiceInModal, setInvoiceInModal] = useState<ModalType | undefined>(
    undefined
  )

  const residentsWithIssuableStatements = getOrElse(residents, []).filter(
    isIssueable
  )
  const issuableStatements = residentsWithIssuableStatements
    .map((r) => r.lastInvoice)
    .filter(notEmpty)

  const billingUpdates = residentsWithIssuableStatements.filter(
    (r) => r.pendingBillingEvents > 0
  ).length

  function downloadFacilityStatements() {
    download({
      fileUrl: getFacilityBillingStatementsUrl(facilityIds),
      target: '_blank',
    }).catch(setError)
  }

  function onDropdownClick(mType: ModalType) {
    if (mType === ModalType.PRINT_STATEMENTS) {
      downloadFacilityStatements()
    } else {
      setInvoiceInModal(mType)
    }
  }

  function disableDropdown(mType: ModalType) {
    return mType === ModalType.DRAFT_STATEMENTS && !canDraft
  }

  const getDropdownMenuOption = (mType: ModalType) => {
    return {
      id: `action-menu-item-${mType}`,
      title: mType as string,
      onClick: () => onDropdownClick(mType),
      isVisible: true,
      isDisabled: disableDropdown(mType),
      iconName: iconForModalType(mType),
    }
  }

  return (
    <>
      <WithTable
        title="Residents"
        rows={residents}
        headers={headers}
        defaultSelectedColumn={ResidentsColumnName.RESIDENT}
        sortingFn={getSortedResidents}
        downloadMenuProps={{
          menuItems: [
            getDropdownMenuOption(ModalType.DRAFT_STATEMENTS),
            getDropdownMenuOption(ModalType.SEND_STATEMENTS),
            getDropdownMenuOption(ModalType.PRINT_STATEMENTS),
            getDropdownMenuOption(ModalType.RECORD_PAYMENTS),
            getDropdownMenuOption(ModalType.CLOSE_BOOKS),
          ],
          buttonProps: {
            initialIcon: 'receipt',
            cta: 'Action',
          },
        }}
        searchBoxProps={{
          searchFn: getSearchedResidents,
          searchPlaceholder: 'Search residents...',
        }}
      >
        {(sortedRows: ResidentListEntry[]) => (
          <>
            {sortedRows.map((resident, index) => {
              return (
                <BillingResidentRow
                  key={`resident-${index}`}
                  facilityIds={facilityIds}
                  resident={resident}
                />
              )
            })}
          </>
        )}
      </WithTable>
      {invoiceInModal === ModalType.DRAFT_STATEMENTS && (
        <DraftBulkStatementsModal
          residents={getOrElse(residents, [])}
          closeFn={() => setInvoiceInModal(undefined)}
          doneFn={reloadResidentList}
          facilityId={facilityId}
          orgId={orgId}
        />
      )}
      {invoiceInModal === ModalType.SEND_STATEMENTS && (
        <IssueInvoicesModal
          closeFn={() => setInvoiceInModal(undefined)}
          doneFn={reloadResidentList}
          facilityId={facilityId}
          orgId={orgId}
          issuableStatements={issuableStatements}
          billingUpdates={billingUpdates}
        />
      )}
      {invoiceInModal === ModalType.CLOSE_BOOKS && (
        <CloseBooksModal
          closeFn={() => setInvoiceInModal(undefined)}
          doneFn={reloadResidentList}
          facilityIds={{ orgId, id: facilityId }}
        />
      )}
      {invoiceInModal === ModalType.RECORD_PAYMENTS && (
        <RecordPayments
          onFinishPayment={reloadResidentList}
          onClose={() => setInvoiceInModal(undefined)}
          residents={billingResidents}
        />
      )}
    </>
  )
}
