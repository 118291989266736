import MedOrderPills from '@shared/components/MedOrderPills'
import TimingBadges from '@shared/components/TimingBadges'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  getDosageInstructions,
  getVitalInstructions,
} from '@shared/utils/medicationOrder'
import {
  getMedicationRoute,
  getMedicationTitleAndSubTitle,
  isPending,
  isVitalOrder,
} from '@shared/utils/medicationStatement'
import { tw, twx } from '@shared/utils/tailwind'
import { OrderStatusPill } from '@app/components/Residents/Medications/Orders/OrderStatusPill'
import { getMedOrderDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import {
  DurationBadge,
  QuantityAndDosageBadge,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderBadges'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { usePharmacyMessageDiffs } from '@app/hooks/usePharmacyMessageDiffs'

type Props = {
  order: MedicationOrder
  renderMode: 'list' | 'order'
  discontinued?: boolean
}
export const OrderHeader = ({
  order,
  renderMode,
  discontinued = false,
}: Props) => {
  const {
    settings: { use24HourClock },
  } = useCurrentFacility()
  const isVital = isVitalOrder(order)

  const { messageDiffs } = usePharmacyMessageDiffs(
    order,
    renderMode === 'list' && isPending(order) ? order.orderGroupId : undefined
  )
  const orderToShow =
    messageDiffs.tag === 'Complete' && messageDiffs.value?.withDiffs.mergedOrder
      ? (messageDiffs.value.withDiffs!.mergedOrder as MedicationOrder)
      : order
  const { title, subTitle } = getMedicationTitleAndSubTitle(orderToShow)
  const route = getMedicationRoute(orderToShow)
  const instructions = isVital
    ? getVitalInstructions(orderToShow)
    : getDosageInstructions(orderToShow)

  const doseType = getMedOrderDosageType(order.medicationStatement!)

  return (
    <>
      <div
        className={twx(
          `flex flex-wrap items-center text-[14px] font-semibold leading-5 text-gray-04 [&>*]:whitespace-nowrap`,
          {
            'saturate-0': discontinued,
          }
        )}
      >
        <span>{title}</span>
        {route && (
          <span className={tw`ml-1 capitalize`}>({route.toLowerCase()})</span>
        )}
        {subTitle && <span className={tw`ml-1`}>{subTitle}</span>}
        <div className={tw`ml-1.5 flex flex-wrap items-center gap-2`}>
          <MedOrderPills order={orderToShow} />
          <OrderStatusPill order={orderToShow} />
        </div>
      </div>
      {renderMode === 'list' && (
        <div className={tw`mt-2 flex flex-row flex-wrap items-center gap-2`}>
          {instructions.map((dosageInstruction, index) => {
            const isLastInstruction = index === instructions.length - 1
            return (
              <div
                key={index}
                className={'flex flex-row flex-wrap items-center gap-2'}
              >
                <QuantityAndDosageBadge
                  dose={dosageInstruction}
                  className={tw`bg-gray-07 text-white`}
                />
                <TimingBadges
                  timing={dosageInstruction.timing}
                  use24HourTime={use24HourClock}
                  keyPrefix={orderToShow.id!}
                  className={tw`bg-gray-07 text-white`}
                />
                <DurationBadge
                  dose={dosageInstruction}
                  dosageType={doseType}
                  className={tw`bg-gray-07 text-white`}
                />
                {!isLastInstruction && <span className="text-gray-07">•</span>}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
