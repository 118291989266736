import { RoutineTypeAssessment } from '@augusthealth/models/com/august/protos/routine'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import {
  Description,
  levelsForRoutine,
  toFullDescription,
} from '@shared/components/RoutineOrders/Instructions'
import { RoutineOrder } from '@shared/types/routine_order'
import { isMorningOrBedtime } from '@shared/utils/routineOrder'
import { twx } from '@shared/utils/tailwind'

function AssessmentFullDescription({
  routineOrderId,
  assessment,
  preClasses,
}: {
  routineOrderId: string
  assessment: RoutineTypeAssessment
  preClasses: string
}) {
  const categoryTasks = assessment.tasks ?? []
  const fullDescription: Description[] = toFullDescription(categoryTasks)
  return (
    <>
      {fullDescription.map((fid, i) => (
        <div key={`${routineOrderId}-${i}`}>
          <div className="border-l-2 border-l-gray-10">
            <pre className={preClasses}>
              {fid.categoryLevel && (
                <li className="mb-2">
                  {fid.categoryLevel} {fid.categoryTitle}{' '}
                  {fid.notes && <>- {fid.notes}</>}
                </li>
              )}

              {fid.details.map((d, j) => (
                <li key={`${routineOrderId}-${i}-detail-${j}`}>
                  <span>{d}</span>
                </li>
              ))}
              {fid.options.map((o, j) => (
                <li key={`${routineOrderId}-${i}-option-${j}`}>
                  <span>{o}</span>
                </li>
              ))}
            </pre>
          </div>
        </div>
      ))}
    </>
  )
}

function AssessmentAbbreviatedDescription({
  assessment,
  preClasses,
}: {
  assessment: RoutineTypeAssessment
  preClasses: string
}) {
  const levelsTextForRoutine = levelsForRoutine(assessment.tasks ?? [])
  return (
    <div className={'flex'}>
      <div className="border-l-2 border-l-gray-10">
        <pre className={preClasses}>
          <>
            {levelsTextForRoutine.map((s, i) => (
              <div key={`l-i${i}`}>{s}</div>
            ))}
            {levelsTextForRoutine.length === 0 && (
              <div>No level information available.</div>
            )}
          </>
        </pre>
      </div>
    </div>
  )
}

function hasDetailedAssessmentDescription(routineOrder: RoutineOrder): boolean {
  const categoryTasks = routineOrder.routineType?.assessment?.tasks ?? []
  const fullDescription: Description[] = toFullDescription(categoryTasks)
  return fullDescription.some(
    (i) => i.details.length > 0 || i.options.length > 0
  )
}

export function Instructions({
  routineOrder,
  className,
}: {
  routineOrder: RoutineOrder
  className?: string
}) {
  const [showFullInstructions, setShowFullInstructions] = useState(
    !(
      isMorningOrBedtime(routineOrder) &&
      hasDetailedAssessmentDescription(routineOrder)
    )
  )
  const preClasses = twx(
    `whitespace-pre-wrap px-4 font-inter font-medium ${className}`
  )
  if (routineOrder.routineType.assessment) {
    return (
      <>
        {!showFullInstructions && (
          <AssessmentAbbreviatedDescription
            assessment={routineOrder.routineType.assessment}
            preClasses={preClasses}
          />
        )}
        {showFullInstructions && (
          <AssessmentFullDescription
            routineOrderId={routineOrder.id}
            assessment={routineOrder.routineType.assessment}
            preClasses={preClasses}
          />
        )}
        {!showFullInstructions && (
          <div>
            <ButtonLink
              onClick={(e) => {
                e.stopPropagation()
                setShowFullInstructions(true)
              }}
              className="text-left leading-[20px]"
            >
              Show full instructions...
            </ButtonLink>
          </div>
        )}
      </>
    )
  } else {
    return (
      <div className="border-l-2 border-l-gray-10">
        <pre className={preClasses}>{routineOrder.instructions}</pre>
      </div>
    )
  }
}
