import { Task } from '@augusthealth/models/com/august/protos/task'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SignatureInfoWithSkip } from '@shared/api/task'
import Lightbox from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  admissionTasksPathForPerson,
  getTasksUrl,
  taskPathForPerson,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import {
  markTaskAsAwaitingReview,
  unshareAndAssignTaskToAdmin,
} from '@app/api/tasks'
import HUD from '@app/components/HUD'
import { PageGeneratorItem } from '@app/components/Prospects/Forms/FormLayout/type'
import { getAdminCtaText } from '@app/components/SignatureFlow/helpers'
import SignatureFlowSidebar from '@app/components/SignatureFlow/Sidebar'
import {
  ConfirmMakeChangesOverlays,
  ExternalSignerMissingEmailOverlays,
  MissingRpOverlays,
  RpMissingEmailOverlays,
} from '@app/components/SignatureFlow/SignatureModalOverlays'
import useSignatureFlowData from '@app/components/SignatureFlow/useSignatureFlowData'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import styles from '@app/pages/Documents/Viewer/styles.module.css'
import { PDFBody } from './Components'

interface Props {
  task: Task
  person: Person
  onClose: () => void
  subHeaderComponent?: React.JSX.Element
  setIsLoaded?: (loaded: boolean) => void
  forceHideVerticalOverflow?: boolean
  configuration?: PageGeneratorItem[]
  dataType?: DataType
}

/**
 * Lightbox component for tasks that don't yet have a signature packet.
 * See `InProgressSignatureFlow` for tasks that do have a signature packet.
 */

export default function SignatureFlow({
  task,
  person: initialPerson,
  onClose,
  subHeaderComponent,
  setIsLoaded,
  forceHideVerticalOverflow = false,
  configuration,
  dataType,
}: Props) {
  const { signableForm } = useContext(TaskTemplateContext)

  const {
    blobData,
    createPacket,
    determineSignatureAction,
    currentSigners,
    originalConfiguredSigners,
    isCurrentUserNextSigner,
    isLoadingData,
    isTaskIncomplete,
    person,
    refreshTasks,
    responsiblePerson,
    setCurrentSigners,
    user,
    externalSignersWithoutEmail,
    refreshContacts,
  } = useSignatureFlowData({
    person: initialPerson,
    task,
    dataType,
    pageGeneratorConfiguration: configuration,
    signableForm,
  })

  const history = useHistory()
  const { setError } = useContext(GlobalContext)

  const [isRequestingSignature, setIsRequestingSignature] = useState(false)
  const [showHUD, setShowHUD] = useState<string>()
  const [showExternalSignersWithoutEmail, setShowExternalSignersWithoutEmail] =
    useState(false)
  const [showNoRp, setShowNoRp] = useState(false)
  const [noRpEmail, setNoRpEmail] = useState(false)
  const [confirmMakeChanges, setConfirmMakeChanges] = useState(false)

  // A ref for the PDF div. We use this to manually control the scroll offset.
  const divRef = useRef<HTMLDivElement>(null)

  async function createPacketAndProceed(
    updatedSigners?: SignatureInfoWithSkip[]
  ) {
    if (person.tag !== 'Complete') {
      return
    }

    await createPacket(updatedSigners)
    await refreshTasks()

    if (isCurrentUserNextSigner) {
      history.push(
        taskPathForPerson(person.value as Required<Person>, task.id || '')
      )
    } else {
      setShowHUD('Sent for signature.')
    }
  }

  const resetPDFScroll = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
  }

  const onClickRequestSignature = async () => {
    try {
      if (person.tag === 'Loading') {
        return
      }

      const action = determineSignatureAction({
        updatedSigners: currentSigners,
      })

      switch (action.tag) {
        case 'No RP':
          // Show the No RP flow on top of the PDF
          resetPDFScroll()
          return setShowNoRp(true)
        case 'No RP Email':
          // Show the RP Missing Email flow on top of the PDF
          resetPDFScroll()
          return setNoRpEmail(true)
        case 'Request Review':
          setIsRequestingSignature(true)
          await markTaskAsAwaitingReview({ person: person.value, task })
          return setShowHUD('Sent to administrator for review.')
        case 'Create Signature Packet':
          setIsRequestingSignature(true)
          await handleCreatingPacket()
          return
        case 'No External Email':
          resetPDFScroll()
          setShowExternalSignersWithoutEmail(true)
          return
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsRequestingSignature(false)
    }
  }

  const verticalOverflow =
    showNoRp || noRpEmail || forceHideVerticalOverflow ? 'hidden' : 'auto'

  const adminCtaText = getAdminCtaText({ currentSigners, user })
  const handleCreatingPacket = async (
    updatedSigners?: SignatureInfoWithSkip[]
  ) => {
    try {
      // optional updatedSigners will be received if missing exernal signer email is added
      await createPacketAndProceed(updatedSigners)
    } catch (e) {
      setError(e)
    } finally {
      if (!isCurrentUserNextSigner) {
        onClose()
        if (person.tag === 'Complete') {
          history.push(
            getTasksUrl({
              orgId: person.value.orgId,
              facilityId: person.value.facilityId,
              personId: person.value.id,
            })
          )
        }
      }
    }
  }

  return (
    <Lightbox
      mainContent={
        <div
          ref={divRef}
          className={styles.pdfContainer}
          style={{ overflow: verticalOverflow }}
        >
          <PDFBody
            blobData={blobData}
            setIsLoaded={setIsLoaded}
            subHeaderComponent={subHeaderComponent}
          />
        </div>
      }
      sidebarContent={
        <SignatureFlowSidebar
          task={task}
          user={user}
          person={person}
          isTaskIncomplete={isTaskIncomplete}
          blobData={blobData}
          isLoadingData={isLoadingData}
          onClose={onClose}
          responsiblePerson={responsiblePerson}
          isRequestingSignature={isRequestingSignature}
          originalConfiguredSigners={originalConfiguredSigners}
          currentSigners={currentSigners}
          setCurrentSigners={setCurrentSigners}
          onClickRequestSignature={onClickRequestSignature}
          onClickMakeChanges={() => setConfirmMakeChanges(true)}
        />
      }
      onClose={onClose}
    >
      {showNoRp && person.tag === 'Complete' && (
        <MissingRpOverlays person={person.value} />
      )}
      {noRpEmail && person.tag === 'Complete' && responsiblePerson && (
        <RpMissingEmailOverlays
          onContinue={async () => {
            if (externalSignersWithoutEmail?.length) {
              // Need to handle external signers without email before creating packet
              await refreshContacts()
              // Manually unset noRpEmail and set showExternalSignersWithoutEmail
              // Cannot re-call onClickRequestSignature the old RP without email is cached there
              setNoRpEmail(false)
              setShowExternalSignersWithoutEmail(true)
            } else {
              await handleCreatingPacket()
            }
          }}
          continueWithEmailButtonText={adminCtaText}
          personIds={person.value}
          responsiblePerson={responsiblePerson}
        />
      )}
      {showExternalSignersWithoutEmail && (
        <ExternalSignerMissingEmailOverlays
          onContinue={handleCreatingPacket}
          continueWithEmailButtonText={adminCtaText}
          externalSignersWithoutEmail={externalSignersWithoutEmail}
          allSigners={currentSigners}
        />
      )}
      {person.tag === 'Complete' && showHUD && (
        <HUD
          onExpire={() => {
            history.push(
              admissionTasksPathForPerson(person.value as Required<Person>)
            )
          }}
        >
          {showHUD}
        </HUD>
      )}
      {person.tag === 'Complete' && confirmMakeChanges && (
        <ConfirmMakeChangesOverlays
          onCancel={() => setConfirmMakeChanges(false)}
          onConfirm={async () => {
            await unshareAndAssignTaskToAdmin(task, person.value)
            await refreshTasks()
            history.push(taskPathForPerson(person.value, task.id || ''))
          }}
        />
      )}
    </Lightbox>
  )
}
