import { ComponentType } from 'react'
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  Props,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { Person } from '@shared/types/person'
import { tw, twx } from '@shared/utils/tailwind'

export interface GroupedOptionTypeBase<T = string, D = Record<string, object>> {
  label: string
  options: OptionTypeBase<T, D>[]
}

export interface OptionTypeBase<T = string, D = Record<string, object>> {
  label: string
  value: T
  isDisabled?: boolean
  subLabel?: string
  data?: D
}

export type PersonOption<T = Person> = {
  label: { name: string; status: string }
  value: T
}

export interface StyledSelectProps extends Props {
  size?: 'large' | 'medium'
}

export type SelectComponent = ComponentType<
  OptionProps<unknown, boolean, GroupBase<unknown>>
>

interface CreatableStyledSelectProps
  extends Props,
    CreatableProps<unknown, boolean, GroupBase<unknown>> {
  disabled?: boolean
  size?: 'medium' | 'large'
}

interface AsyncCreatableStyledSelectProps
  extends Props,
    AsyncCreatableProps<unknown, boolean, GroupBase<unknown>> {
  disabled?: boolean
  size?: 'medium' | 'large'
}

export const baseSelectControlStyles = (
  size = 'medium',
  state: ControlProps<unknown, boolean, GroupBase<unknown>>
) => ({
  borderColor:
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    !state.isDisabled && state.isFocused
      ? 'var(--form-input-border-hover) !important'
      : 'var(--form-input-border)',
  height:
    size === 'large'
      ? 'var(--form-input-large-height)'
      : 'var(--form-input-height)',
  cursor: 'cursor-pointer',
  fontSize: 'var(--form-input-font-size)',
  fontWeight: '500',
  borderRadius: 'var(--form-input-border-radius)',
  lineHeight: '16px',
  boxShadow: 'none',
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  outline: state.isFocused
    ? 'double 1px var(--form-input-border-hover) !important'
    : 'double 1px transparent !important',
  '&:hover': {
    borderColor: 'var(--form-input-border-hover) !important',
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  backgroundColor: state.isDisabled
    ? 'var(--form-input-disabled-background-color)'
    : 'white',
})

export const errorBorderControlStyles = (
  error,
  state: ControlProps<unknown, boolean, GroupBase<unknown>>
) => ({
  borderColor: `var(${
    error ? '--form-input-error-border' : '--form-input-border'
  })`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  outline: state.isFocused
    ? `double 1px var(${
        error ? '--form-input-error-border' : '--form-input-border-hover'
      }) !important`
    : 'double 1px transparent !important',
  '&:hover': {
    borderColor: `var(${
      error ? '--form-input-error-border' : '--form-input-border-hover'
    }) !important`,
  },
})

const baseMenuListStyles = {
  paddingTop: '0px',
  paddingBottom: '0px',
}

const baseDropdownIndicatorStyles = {
  color: '#595451',
  '&:hover': {
    color: '#595451',
  },
}

export const selectStyles = (size = 'medium'): StylesConfig => ({
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles(size, state),
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'cursor-pointer',
    fontSize: '14px',
  }),
  indicatorSeparator: () => ({}),
  menuList: (provided) => ({
    ...provided,
    ...baseMenuListStyles,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...baseDropdownIndicatorStyles,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? 'var(--form-input-disabled-text-color)'
      : 'var(--gray-04)',
  }),
})

export const modifiedStyles = (
  size = 'medium'
): StylesConfig<unknown, boolean, GroupBase<unknown>> => ({
  ...selectStyles(size),
  menu: (provided) => ({
    ...provided,
    zIndex: '102',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 4px 0px 0px',
  }),
})

const modifiedWithIconStyles = (
  size = 'medium'
): StylesConfig<unknown, boolean, GroupBase<unknown>> => ({
  ...modifiedStyles(size),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles(size, state),
    width: '100%',
    paddingLeft: 'var(--form-input-icon-left-padding)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: '100%',
    paddingLeft: '0px',
  }),
})

const inlineFormStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  ...selectStyles('medium'),
}

const buttonStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    fontSize: '11px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    fontWeight: '700',
    borderColor: 'var(--secondary-06)',
    cursor: 'cursor-pointer',
    '&:hover': {
      borderColor: 'var(--secondary-06)',
    },
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'cursor-pointer',
  }),
  indicatorSeparator: () => ({}),
  menuList: (provided) => ({
    ...provided,
    ...baseMenuListStyles,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...baseDropdownIndicatorStyles,
  }),
}

export const multiSelectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles('default', state),
    height: 'fit-content',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    textOverflow: 'string !important',
  }),
}

export const getComponents = (
  components?: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
) => {
  let finalComponents: object = {
    Option: StyledOption,
    DropdownIndicator: SelectDropdownIndicator,
  }

  if (components) {
    finalComponents = {
      ...finalComponents,
      ...components,
    }
  }

  return finalComponents
}

export function AsyncCreatableStyledSelect({
  isDisabled = false,
  size = 'medium',
  components,
  styles,
  id,
  ...rest
}: AsyncCreatableStyledSelectProps) {
  return (
    <AsyncCreatableSelect
      styles={{ ...modifiedStyles(size), ...styles }}
      components={getComponents(components)}
      isDisabled={isDisabled}
      id={id}
      inputId={`input-${id}`}
      {...rest}
    />
  )
}

export function CreatableStyledSelect({
  isDisabled = false,
  size = 'medium',
  components,
  styles,
  id,
  ...rest
}: CreatableStyledSelectProps) {
  return (
    <CreatableSelect
      styles={{ ...modifiedStyles(size), ...styles }}
      components={getComponents(components)}
      isDisabled={isDisabled}
      id={id}
      inputId={`input-${id}`}
      {...rest}
    />
  )
}

export function CreatableIconStyledSelect({
  isDisabled = false,
  size = 'medium',
  components,
  iconName,
  styles,
  ...rest
}: CreatableStyledSelectProps & {
  iconName: string
}) {
  return (
    <div className={tw`relative flex flex-row items-center`}>
      <i
        className={twx(iconName, 'fa-fw absolute left-[12px] z-[1]', {
          'text-primary-light': !isDisabled,
          'text-secondary-06': isDisabled,
        })}
      />
      <CreatableSelect
        styles={{ ...modifiedWithIconStyles(size), ...styles }}
        components={getComponents(components)}
        isDisabled={isDisabled}
        {...rest}
      />
    </div>
  )
}

export type IconStyledSelectProps = StyledSelectProps & {
  iconName: string
}

export function IconStyledSelect({
  isDisabled = false,
  size = 'medium',
  components,
  iconName,
  styles,
  className,
  ...rest
}: IconStyledSelectProps) {
  return (
    <div className={twx(`relative flex flex-row items-center`, className)}>
      <i
        className={twx(iconName, 'fa-fw absolute left-[12px] z-[1]', {
          'text-primary-light': !isDisabled,
          'text-secondary-06': isDisabled,
        })}
      />
      <Select
        styles={{ ...modifiedWithIconStyles(size), ...styles }}
        components={getComponents(components)}
        isDisabled={isDisabled}
        {...rest}
      />
    </div>
  )
}

export default function StyledSelect({
  isDisabled = false,
  id,
  styles,
  size = 'medium',
  components,
  ...rest
}: StyledSelectProps) {
  return (
    <Select
      classNames={{
        container: () => 'overscroll-contain',
        menu: () => 'overscroll-contain',
      }}
      styles={{
        ...modifiedStyles(size),
        ...styles,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body}
      components={getComponents(components)}
      isDisabled={isDisabled}
      id={id}
      inputId={`input-${id}`}
      {...rest}
    />
  )
}

export function InlineFormSelect({
  isDisabled = false,
  id,
  styles,
  ...rest
}: StyledSelectProps) {
  return (
    <Select
      isSearchable={false}
      components={{
        Control: InlineFormControl,
        DropdownIndicator: SelectDropdownIndicator,
      }}
      styles={{ ...inlineFormStyles, ...styles }}
      isDisabled={isDisabled}
      id={id}
      inputId={`input-${id}`}
      {...rest}
    />
  )
}

export function ButtonSelect({
  isDisabled = false,
  id,
  styles,
  components,
  ...rest
}: StyledSelectProps) {
  return (
    <Select
      styles={{ ...buttonStyles, ...styles }}
      components={getComponents(components)}
      isDisabled={isDisabled}
      id={id}
      inputId={`input-${id}`}
      {...rest}
    />
  )
}

export function StyledMultiSelect({
  isDisabled = false,
  size = 'medium',
  components,
  styles,
  ...rest
}: StyledSelectProps) {
  return (
    <Select
      isMulti={true}
      styles={{
        ...modifiedStyles(size),
        ...multiSelectStyles,
        ...styles,
      }}
      components={getComponents(components)}
      isDisabled={isDisabled}
      {...rest}
    />
  )
}

export function CreatableStyledMultiSelect({
  disabled = false,
  size = 'medium',
  components,
  styles,
  ...rest
}: CreatableStyledSelectProps) {
  return (
    <CreatableSelect
      isMulti={true}
      styles={{
        ...multiSelectStyles,
        ...modifiedStyles(size),
        ...styles,
      }}
      components={getComponents(components)}
      isDisabled={disabled}
      {...rest}
    />
  )
}

function SelectDropdownIndicator({ isDisabled }: DropdownIndicatorProps) {
  return (
    <i
      className={twx('fas fa-solid fa-caret-down mr-[12px] text-[12px]', {
        'text-secondary-07': isDisabled,
        'text-gray-03': !isDisabled,
      })}
    />
  )
}

function InlineFormControl({
  children,
  innerRef,
  innerProps,
  isDisabled,
}: ControlProps<unknown, boolean, GroupBase<unknown>>) {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={twx(
        'relative mb-0 flex h-[30px] cursor-pointer rounded-[6px] border border-form-input-border bg-white text-[14px] font-medium leading-[16px]',
        {
          'bg-form-input-disabled-background-color': isDisabled,
        }
      )}
    >
      {children}
    </div>
  )
}

export type StyledOptionProps<Opt = OptionTypeBase> = OptionProps<
  Opt,
  boolean,
  GroupBase<Opt>
> & {
  holderClassName?: string
  labelClassName?: string
  iconClassName?: string
}

export function SingleValue<Opt = OptionTypeBase>({
  children,
  ...rest
}: SingleValueProps<Opt, boolean, GroupBase<Opt>>) {
  const { subLabel } = rest.data as OptionTypeBase
  return (
    <components.SingleValue<Opt, boolean, GroupBase<Opt>> {...rest}>
      {children}
      {subLabel && <span className={tw`mute-text`}> {subLabel}</span>}
    </components.SingleValue>
  )
}

export function StyledOption<Opt = OptionTypeBase>(
  props: StyledOptionProps<Opt>
) {
  const {
    children,
    innerRef,
    innerProps,
    isDisabled,
    isSelected,
    data,
    holderClassName,
    labelClassName,
    iconClassName,
  } = props
  const { subLabel } = data as OptionTypeBase

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={twx(
        'cursor-pointer whitespace-nowrap p-0 pt-[16px] text-[14px] font-medium leading-[16px] text-secondary-01 transition-all hover:bg-[#f0edeb]',
        { 'text-mute-color': isDisabled },
        holderClassName
      )}
    >
      <div className={tw`mx-[8px] flex items-baseline`}>
        <i
          className={twx(
            'far fa-fw shrink-0',
            { 'fa-check': isSelected },
            iconClassName
          )}
        />
        <div
          className={twx(
            `ml-[9px] grow text-[14px]`,
            { 'font-bold': isSelected },
            labelClassName
          )}
        >
          {subLabel && (
            <div className={tw`flex-holder items-baseline`}>
              <div className={tw`whitespace-nowrap`}>{children}</div>
              <div className={tw`mute-text ml-[6px] text-[12px]`}>
                {subLabel}
              </div>
            </div>
          )}
          {!subLabel && children}
        </div>
      </div>
      <div
        className={tw`mx-[4px] mt-[11px] h-[4px] border-b border-[#f2efed]`}
      />
    </div>
  )
}
