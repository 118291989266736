import { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { incidentPathForPerson } from '@shared/legacy_routes'
import {
  Incident,
  IncidentActionType,
  IncidentLocation,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { customIncidentActionIdentifier, tags } from '@shared/utils/incident'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import { RHFSwitch } from '@app/components/Switch'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import { useIncidentActionTemplates } from '@app/hooks/useIncidentActionTemplates'
import styles from '../styles.module.css'
import { followUpActionsForFacility } from '../helpers'
import IncidentAttachment from '../IncidentAttachments'
import {
  createAttachments,
  getAttachmentError,
  IncidentFormData,
  isIncidentTimeRequired,
  locations,
  persistIncident,
  selectNewOrEditedAttachments,
  toDefaultValues,
} from './incidentFormHelpers'

export function AddIncident() {
  const { person } = useContext(PersonContext)

  if (person === undefined) {
    return null
  }

  return (
    <div className={styles.incidentFormContainer}>
      <div className={styles.page}>
        <h2 className="page-title horizontal mb-[40px]">Add Incident</h2>
        <IncidentForm person={person} />
      </div>
    </div>
  )
}

export default function IncidentForm({
  incident,
  isLoading = false,
  person,
}: {
  incident?: Incident
  isLoading?: boolean
  person: Person
}) {
  const history = useHistory()
  const { setError } = useContext(GlobalContext)
  const { facilities = [] } = useContext(FacilitiesContext)
  const methods = useForm<IncidentFormData>({
    mode: 'onChange',
    defaultValues: toDefaultValues(incident),
  })
  const { actionTemplates } = useIncidentActionTemplates({ person })
  const { control, register, handleSubmit, setValue, formState } = methods
  const { errors } = formState

  const [showOtherLocation, setShowOtherLocation] = useState(
    incident?.detail?.incidentDetail?.location ===
      IncidentLocation.INCIDENT_LOCATION_OTHER
  )

  if (!person) {
    return null
  }

  const currentFacility = facilities.find((f) => f.id === person.facilityId)
  const filteredFollowupActions = currentFacility
    ? followUpActionsForFacility(currentFacility)
    : []

  const onSubmit = async (formData: IncidentFormData) => {
    try {
      const { incidentId } = await persistIncident({
        formData,
        incident,
        person,
        actionTemplates: getOrElse(actionTemplates, []),
      })

      if (formData.attachments) {
        const newOrEditedAttachments = selectNewOrEditedAttachments({
          incidentAttachments: incident?.attachments,
          newAttachments: formData.attachments,
        })

        const result = await createAttachments({
          incidentId,
          attachments: newOrEditedAttachments,
          person,
        })

        const failedAttachments: any = result.filter((r) => r.tag === 'Error')

        if (failedAttachments.length) {
          const attachmentError = getAttachmentError(failedAttachments)
          setError(attachmentError)

          return history.push(
            incidentPathForPerson(person as Required<Person>, {
              id: incidentId,
            })
          )
        }
      }

      history.goBack()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isLoading || actionTemplates.tag === 'Loading'}>
        <div className="flex flex-col">
          <LabelAboveInput
            subLabel={requiredLabel(!!errors['notes'])}
            htmlFor="notes"
          >
            What happened?
          </LabelAboveInput>
          <BasicTextarea
            data-cy={'incident-note'}
            {...register('notes', { required: true })}
            placeholder="Describe what happened and what caused the incident.."
          />
          <div className="mt-[12px]">
            {tags.map((type, index) => (
              <span key={`note-${index}`}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  id={`tag-${index}`}
                  {...register('incidentTypes')}
                  value={type.value}
                />
                <label htmlFor={`tag-${index}`} className={styles.tag}>
                  <i className={`fa-solid mr-[4px] fa-${type.icon}`} />
                  <span className="text-[14px] font-medium leading-[16px]">
                    {type.name}
                  </span>
                </label>
              </span>
            ))}
          </div>
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            subLabel={requiredLabel(!!errors['date'] || !!errors['time'])}
            htmlFor="date"
          >
            When did this incident occur?
          </LabelAboveInput>
          <div className="flex items-center">
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  id="incidentDate"
                  maxDate={new Date()}
                  selected={value}
                  onChange={onChange}
                  customInput={<CalendarInput />}
                />
              )}
            />
            <div className={'ml-[16px] w-[184px]'}>
              <BasicInput
                data-testid="occurrence-time"
                type={'time'}
                {...register('time', {
                  required: isIncidentTimeRequired(incident),
                })}
                id={'incident-time'}
              />
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name="location"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="location">
                Where did this incident happen?
              </LabelAboveInput>
              <StyledSelect
                placeholder={'Select or enter location...'}
                options={locations}
                defaultValue={locations.find((l) => l.value === value)}
                onChange={(option: OptionTypeBase) => {
                  if (
                    option &&
                    option.value === IncidentLocation.INCIDENT_LOCATION_OTHER
                  ) {
                    setShowOtherLocation(true)
                    onChange(option.value)
                  } else if (option) {
                    setShowOtherLocation(false)
                    setValue('otherLocation', null)
                    onChange(option.value)
                  }
                }}
              />
            </div>
          )}
        />
        {showOtherLocation && (
          <BasicInput
            {...register('otherLocation')}
            placeholder={'Enter location...'}
            className={'mt-[12px]'}
          />
        )}
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="injuries">
            Incident-related injuries, if any
          </LabelAboveInput>
          <BasicInput
            placeholder={'Enter injuries...'}
            {...register('injuries')}
            id={'injuries'}
          />
        </div>
        <div className={'mb-[128px] mt-[32px]'}>
          <LabelAboveInput htmlFor={'followUpActions'}>
            What follow-up actions do you want to track for this incident?
          </LabelAboveInput>
          <EmbossedCard>
            {filteredFollowupActions.map((action, index) => (
              <RHFSwitch
                holderClassName={index !== 0 ? 'mt-[24px]' : undefined}
                register={register}
                switchSize={'small'}
                value={action.value}
                id={`fua-${index}`}
                name={'followUpActions'}
                key={`fua-${index}`}
                label={action.name}
              />
            ))}
            {getOrElse(actionTemplates, []).map((action, index) => {
              return (
                <RHFSwitch
                  holderClassName={'mt-[24px]'}
                  register={register}
                  switchSize={'small'}
                  value={customIncidentActionIdentifier(action)}
                  id={`fua-${index}`}
                  name={`customFollowUpActions.${customIncidentActionIdentifier(action)}`}
                  key={`fua-${index}`}
                  label={customIncidentActionIdentifier(action)}
                />
              )
            })}
            <div className={tw`mt-[20px] flex items-center`}>
              <RHFSwitch
                register={register}
                switchSize={'small'}
                name={'followUpActions'}
                value={IncidentActionType.INCIDENT_ACTION_TYPE_OTHER}
                id={`fua-other`}
              />
              <BasicInput
                containerClassName={tw`w-full max-w-[236px]`}
                className={'ml-[12px]'}
                {...register('followUpOther')}
                placeholder={'Other, e.g. file police report...'}
                inputSize={'inline'}
              />
            </div>
          </EmbossedCard>
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="attachments">
              Add documents <span className="text-tertiary">JPG, PDF, PNG</span>
            </LabelAboveInput>
            <EmbossedCard>
              <FormProvider {...methods}>
                <IncidentAttachment incident={incident} person={person} />
              </FormProvider>
            </EmbossedCard>
          </div>
        </div>
        <FullpageFormFooter
          yesBtn={{
            text: (incident ? 'Update' : 'Save') + ' Incident',
            props: {
              disabled: !!errors.date || !!errors.notes || !!errors.time,
              id: 'save-incident',
              ['data-testid']: 'save-incident',
            },
          }}
          noBtn={{
            text: 'Cancel',
            action: async () => history.goBack(),
          }}
          formState={formState}
        />
      </fieldset>
    </form>
  )
}
