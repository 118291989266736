import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { DataType } from '@shared/types/snapshot'
import { SettingsLevel as SL } from '@shared/utils/orgFacilitySettings'
import { CardLevel } from '../TaskCard/helpers'

export type SelectedData = {
  dropdownOption: OptionTypeBase
  assignment: SignableFormAssignment
}

export function getAssignmentMetadata({
  allAssignments,
  selectedOption,
  cardLevel,
  assignment,
  dataType,
  customType,
  facility,
}: {
  allAssignments: SignableFormAssignment[]
  dataType: DataType
  selectedOption?: OptionTypeBase
  cardLevel: CardLevel
  assignment?: SignableFormAssignment
  customType?: string
  facility?: Facility
}) {
  const { facilityId, orgId, state } = {
    facilityId: undefined,
    orgId: undefined,
    state: undefined,
    ...cardLevel,
  }

  let placeholder = 'Needs assignment...'
  let assignmentLevel: SL | undefined

  const globalAssignment = allAssignments.find((a) =>
    assignmentMatches(a, { dataType, customType })
  )
  // If the card is facility level, there is no 'state' data
  // so this will never find a state assignment for facility level cards.
  // We need to get state from somewhere else?
  const stateAssignment =
    (state || facility?.address?.state) &&
    allAssignments.find((a) =>
      assignmentMatches(a, {
        dataType,
        state: state || facility?.address?.state,
        customType,
      })
    )

  const orgAssignment =
    orgId &&
    allAssignments.find((a) =>
      assignmentMatches(a, { dataType, orgId, customType })
    )

  const orgStateAssignment =
    orgId &&
    (state || facility?.address?.state) &&
    allAssignments.find((a) =>
      assignmentMatches(a, {
        dataType,
        orgId,
        state: state || facility?.address?.state,
        customType,
      })
    )

  if (selectedOption && assignment) {
    const { signableFormId: currentFormId } = assignment
    if (orgAssignment && orgAssignment.signableFormId === currentFormId) {
      assignmentLevel =
        cardLevel.tag !== 'Organization' ? SL.ORG_LEVEL : undefined
    } else if (
      stateAssignment &&
      stateAssignment.signableFormId === currentFormId
    ) {
      assignmentLevel = cardLevel.tag !== 'State' ? SL.STATE_LEVEL : undefined
    } else if (
      globalAssignment &&
      globalAssignment.signableFormId === currentFormId
    ) {
      assignmentLevel = cardLevel.tag !== 'Global' ? SL.GLOBAL_LEVEL : undefined
    }
  } else {
    // If no assigment has been selected, we only need to check dataType
    // Assuming we can only have assignment of the dataType once
    if (orgStateAssignment) {
      placeholder = 'Default to Org/State...'
    } else if ((facilityId || cardLevel.tag === 'State/Org') && orgAssignment) {
      placeholder = 'Default to Org...'
    } else if (stateAssignment) {
      placeholder = 'Default to State assignment...'
    } else if (globalAssignment) {
      placeholder = 'Default to Global assignment...'
    }
  }

  return {
    placeholder,
    assignmentLevel,
  }
}

export function assignmentMatches(
  a: SignableFormAssignment,
  b: SignableFormAssignment
): boolean {
  return (
    a.dataType === b.dataType &&
    a.orgId === b.orgId &&
    (a.customType || '') === (b.customType || '') &&
    a.facilityId === b.facilityId &&
    a.state === b.state
  )
}
