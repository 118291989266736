import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { Task, TaskType } from '@augusthealth/models/com/august/protos/task'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import {
  ActionButtonHolder,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import {
  LightboxFooterButton,
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { LabelledCalendarInput } from '@shared/components/CalendarInput/LabelledCalendarInput'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import {
  getCategoriesFromSnapshot,
  getUnansweredRequiredTextFields,
  hasAssessmentLevelValues,
  isRealignment,
} from '@shared/utils/residentAssessment'
import { isIncomplete } from '@shared/utils/task'
import { finalizeResidentAssessment } from '@app/api/resident_assessment'
import { markTaskAsComplete } from '@app/api/tasks'
import useSnapshots from '@app/hooks/useSnapshots'
import { useTasks } from '@app/hooks/useTasks'
import { getArchivedDocuments } from '@app/pages/Documents/helpers'
import useConfiguredTasks from '@app/pages/Documents/useConfiguredTasks'
import LevelBadges from '@app/pages/Tasks/ResidentAssessment/LevelBadges'
import {
  hasAllAdditionalServicesDropdownDetails,
  hasNeedForEachCategory,
  isMissingRequiredEnhancedNeed,
} from '@app/pages/Tasks/ResidentAssessment/useAssessmentModification'
import styles from '../styles.module.css'
import ArchivedVersions from '../ArchivedVersions'
import { CompletedAt } from '../LevelOfCare'
import { AssessmentViewerMode, getAssessmentType } from './helpers'

export function Sidebar({
  assessment: snapshot,
  mode,
  person,
  onPrint,
  setShowServicePlanOverlay,
}: {
  assessment: Snapshot
  mode: AssessmentViewerMode
  person: Person
  onPrint?: () => void
  setShowServicePlanOverlay: Dispatch<SetStateAction<boolean>>
}) {
  const assessment = snapshot.data?.augustInitialAppraisal
  const { setError } = useContext(GlobalContext)
  const appraisalType = getAssessmentType(snapshot, person)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [assessmentDate, setAssessmentDate] = useState<DateMessage>()
  const possibleTasks = useConfiguredTasks(person)
  const { tasks } = useTasks(true)

  const unansweredRequiredTextFields = getUnansweredRequiredTextFields(snapshot)

  const missingAdmissionType = !assessment?.admissionType
  const missingAssessmentReason =
    (assessment?.settings?.assessmentReasons?.length ?? 0) > 0 &&
    !assessment?.assessmentReason?.assessmentReason

  const categories = getCategoriesFromSnapshot(snapshot) || []
  const hasNeedForEachLevel = hasNeedForEachCategory(
    snapshot,
    categories,
    person
  )
  const allAdditionalServicesAnswered = hasAllAdditionalServicesDropdownDetails(
    snapshot,
    person
  )
  const missingBathingEnhancedNeed = isMissingRequiredEnhancedNeed(
    snapshot,
    person
  )
  const onClickFinalize = async (
    task: Task,
    onSubmit: () => void
  ): Promise<void> => {
    try {
      setIsSubmitting(true)

      await finalizeResidentAssessment({ person, assessmentDate })
      await markTaskAsComplete(person, task)

      if (
        possibleTasks.tag === 'Complete' &&
        possibleTasks.value.some(
          (task) => task.taskType === TaskType.TASK_TYPE_SERVICE_PLAN
        )
      ) {
        const openServicePlanTask = tasks
          .filter((t) => isIncomplete(t))
          .find(
            (t) =>
              t.taskTemplateInfo!.taskType === TaskType.TASK_TYPE_SERVICE_PLAN
          )

        if (!openServicePlanTask) {
          setShowServicePlanOverlay(true)
        } else {
          onSubmit()
        }
      } else {
        onSubmit()
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const hasRealignmentReason = assessment && isRealignment(assessment)

  return (
    <>
      <LightboxSidebarContent
        title={
          <>
            Resident Assessment
            <div className="text-[16px] leading-[24px]">
              {getFirstAndLastName(person.name)}
            </div>
            {hasAssessmentLevelValues(snapshot) && (
              <LevelBadges assessment={snapshot} />
            )}
          </>
        }
        message={
          mode.tag === 'review' && (
            <>
              {hasRealignmentReason && (
                <>
                  <LabelledCalendarInput
                    onChange={(e: Date) => {
                      if (e) {
                        setAssessmentDate(fromDateToDateMessage(e))
                      }
                    }}
                    selected={
                      assessmentDate
                        ? fromDateMessageToDate(assessmentDate)
                        : undefined
                    }
                    maxDate={new Date()}
                    label={'Assessment Date'}
                  />
                </>
              )}
              {!hasRealignmentReason && (
                <>
                  <div>Please review the document</div>
                  <div>If everything looks correct, finalize the document.</div>
                </>
              )}
            </>
          )
        }
      >
        {mode.tag === 'view' && (
          <>
            <div className={styles.formContainer}>
              <CompletedAt document={snapshot} />
            </div>
            {appraisalType.tag === 'Internal' && (
              <ActionButtonHolder>
                <PrintButton onClick={onPrint} />
              </ActionButtonHolder>
            )}
            <ArchivedInternalAssessments person={person} />
          </>
        )}
      </LightboxSidebarContent>
      {mode.tag === 'review' && (
        <LightboxSidebarFooter className="flex-col">
          <AsyncIconButton
            buttonStyle={'primary-fill'}
            data-testid="finalize-appraisal"
            type="button"
            className={'mb-[12px]'}
            isLoading={isSubmitting || possibleTasks.tag === 'Loading'}
            disabled={
              isSubmitting ||
              possibleTasks.tag === 'Loading' ||
              unansweredRequiredTextFields.length > 0 ||
              missingAdmissionType ||
              missingAssessmentReason ||
              missingBathingEnhancedNeed ||
              !allAdditionalServicesAnswered ||
              !hasNeedForEachLevel ||
              (hasRealignmentReason && assessmentDate === undefined)
            }
            onClick={() => onClickFinalize(mode.assessmentTask, mode.onSubmit)}
          >
            Finalize
          </AsyncIconButton>
          <LightboxFooterButton onClick={mode.onMakeChanges}>
            Make Changes
          </LightboxFooterButton>
        </LightboxSidebarFooter>
      )}
    </>
  )
}

/**
 * Displays the 'View Archived Documents' UI for internal assessments.
 * Viewing archived external assessments is handled by the UploadedAppraisalViewer.
 */
function ArchivedInternalAssessments({ person }: { person: Person }) {
  const { snapshots: archivedVersions } = useSnapshots({
    dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
    status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
  })
  const archived = getArchivedDocuments({
    snapshots: getOrElse(archivedVersions, []),
    dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
    person: person!,
  })

  if (archived.length > 0) {
    return <ArchivedVersions documents={archived} />
  }

  return null
}
