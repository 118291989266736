import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { requestRefill } from '@shared/api/pharmacy_refill'
import { Button } from '@shared/components/baseMui/Button'
import {
  ModalButtons,
  NestedModalConfirmation,
} from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { MedicationOrder } from '@shared/types/medication_order'
import { RefillEvent, RefillStatus } from '@shared/types/pharmacy_refill'
import { getMedicationOrderName } from '@shared/utils/medicationStatement'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { tw, twx } from '@shared/utils/tailwind'
import {
  getRefillDisplayDetails,
  refillIsEligible,
} from '@app/components/Residents/Medications/Orders/Refills/helpers'
import { useRefillsByMedicationOrderGroup } from '@app/components/Residents/Medications/Orders/Refills/useRefillsByMedicationOrderGroup'
import { useCurrentFacility } from '@app/hooks/useFacilities'

type Props = {
  open: boolean
  order: MedicationOrder
  onClose: () => void
}
export const RefillModal = ({ open, order, onClose }: Props) => {
  const {
    settings: { use24HourClock },
  } = useCurrentFacility()
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId, id } = useParams<{
    orgId: string
    facilityId: string
    id: string
  }>()
  const [showOverlay, setShowOverlay] = useState(false)
  const { refills } = useRefillsByMedicationOrderGroup(
    open
      ? {
          orgId,
          facilityId,
          personId: id,
          medicationOrderGroupId: order.orderGroupId,
        }
      : undefined
  )

  const submitRefillRequest = async () => {
    try {
      await requestRefill({
        orgId,
        facilityId,
        personId: id,
        medicationOrderGroupId: order.orderGroupId,
      })
      setShowOverlay(true)
    } catch (e) {
      setError(e)
    }
  }

  const cardHeight = tw`flex h-fit min-h-12 items-center px-4 py-2`

  const disableOrderRefill =
    refills.tag === 'Loading' ||
    Boolean(
      refills.tag === 'Complete' &&
        refills.value &&
        !refillIsEligible(refills.value)
    )

  return (
    <Modal
      open={open}
      onClose={onClose}
      id={'order-refill'}
      contentClassName={twx(
        'w-[640px] min-w-[640px] max-w-[640px] flex flex-col gap-4'
      )}
    >
      {showOverlay && (
        <NestedModalConfirmation
          className={twx('flex flex-col gap-4 opacity-95')}
        >
          <div className={twx('font-medium')}>Refill Request Sent!</div>
          <Button
            buttonStyle={'secondary-fill'}
            className={tw`w-[128px]`}
            onClick={() => {
              setShowOverlay(false)
              onClose()
            }}
          >
            Done
          </Button>
        </NestedModalConfirmation>
      )}
      <Card className={twx('font-semibold text-gray-04', cardHeight)}>
        {getMedicationOrderName(order)}
      </Card>

      <div className={twx('flex flex-row items-start justify-between gap-4')}>
        <Card className={twx('flex-1 font-semibold text-gray-04', cardHeight)}>
          {order.medicationStatement.pharmacy ??
            (order.sourceData?.integrationSource
              ? getReadablePharmacyPartnerName(
                  order.sourceData!.integrationSource!
                )
              : 'Unknown Pharmacy')}
        </Card>

        <Card
          className={twx(
            'w-fit flex-row items-center gap-4 text-[14px] leading-[16px] text-gray-07',
            cardHeight
          )}
        >
          Refills Left
          <span
            className={twx(
              'flex min-h-[24px] min-w-[40px] items-center justify-center rounded-[4px] bg-black font-semibold text-white'
            )}
            data-testid={'refills-left'}
          >
            {order.medicationStatement.dispenseRequest
              ?.numberOfRepeatsRemaining ?? 0}
          </span>
        </Card>
      </div>
      <div className={twx('flex flex-col gap-1')}>
        {refills.tag === 'Complete' && refills.value ? (
          <>
            {refills.value.refills.map((refill, rIndex) => {
              return (
                <div
                  key={rIndex}
                  className={tw`border-form-input-border py-1 [&:not(:last-child)]:border-b`}
                >
                  {refill.data.events.map((event, eIndex) => {
                    return (
                      <RefillLineItem
                        key={eIndex}
                        actionType={event.status}
                        data={event}
                        use24HourClock={use24HourClock}
                      />
                    )
                  })}
                </div>
              )
            })}
            {!refills.value.refillAllowed &&
              refills.value.refills.length === 0 && (
                <div className={tw`text-[14px] font-medium leading-[20px]`}>
                  Medication not eligible for refill
                </div>
              )}
          </>
        ) : (
          <SimpleSpinner size={'fa-lg'} className={tw`mt-2`} />
        )}
      </div>

      <ModalButtons
        confirmProps={{
          label: 'Order Refill',
          onClick: () => void submitRefillRequest(),
          disabled: disableOrderRefill,
        }}
        cancelProps={{
          label: 'Cancel',
          onClick: onClose,
        }}
      />
    </Modal>
  )
}

const RefillLineItem = ({
  actionType,
  data,
  use24HourClock,
}: {
  actionType: RefillStatus
  data: RefillEvent
  use24HourClock: boolean
}) => {
  const details = getRefillDisplayDetails({ actionType, data, use24HourClock })
  if (details) {
    return (
      <div
        className={twx(
          'flex flex-row items-center gap-2 text-[14px] font-medium leading-[20px]',
          details.className
        )}
      >
        <Icon name={details.icon} />
        {details.statement}
      </div>
    )
  }

  return null
}
