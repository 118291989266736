import { Task } from '@augusthealth/models/com/august/protos/task'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Card from '@shared/components/Card'
import { PersonSearchBox } from '@shared/components/PersonSearchBox'
import { Person, ResidentStatus } from '@shared/types/person'
import { personNameMatchesSearch } from '@shared/utils/person'
import { isIncomplete } from '@shared/utils/task'
import Content from '@app/components/generic/Content'
import { RemoveTasksModal } from '@app/components/RemoveTasks/RemoveTasksModal'
import usePersonTasks from '@app/hooks/usePersonTasks'
import styles from './styles.module.css'
import GeneralUploader from '../Documents/Uploader'
import {
  getTodoTasks,
  getUpcomingTasks,
  sortPeopleByTodoTasks,
  sortPeopleByUpcomingTasks,
} from './helpers'
import PersonCard from './PersonCard'
import ProspectResidentSelector from './ProspectResidentSelector'
import ResidentCompletionBanner, {
  LoadingSkeleton,
} from './ResidentCompletionBanner'
import useCompletionStats from './useCompletionStats'

export default function Dashboard({
  match,
}: RouteComponentProps<{ orgId: string; facilityId: string }>) {
  const [showUploader, setShowUploader] = useState<{
    task: Task
    person: Person
  }>()
  const [showCloseTasks, setShowCloseTasks] = useState<{
    tasks: Task[]
    person: Person
  }>()

  const [nameFilter, setNameFilter] = useState('')
  const [personFilter, setPersonFilter] = useState<Set<ResidentStatus>>(
    new Set([ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT])
  )
  const [filterUpcomingTasks, setFilterUpcomingTasks] = useState(false)
  const [filterTodoTasks, setFilterTodoTasks] = useState(true)

  const { orgId, facilityId } = match.params
  const { personTasks, refreshTasks } = usePersonTasks(
    {
      orgId,
      facilityId,
    },
    [orgId, facilityId]
  )

  const {
    availableCompletionTasks,
    completionStats,
    completionTypeToTemplateHash,
    refreshStats,
  } = useCompletionStats({ orgId, facilityId })

  if (personTasks.tag === 'Loading') {
    return (
      <Content>
        <h1 className={styles.pageTitle}>Compliance Dashboard</h1>
        <div className="mb-[16px] mt-[32px]">{LoadingSkeleton()}</div>
        <h1 className={styles.pageTitle}>Open Tasks</h1>
        <div className="mb-[16px] mt-[32px] w-full">{LoadingSkeleton()}</div>
      </Content>
    )
  }

  const filteredPersonTasks =
    personTasks.tag === 'Complete'
      ? personTasks.value
          .filter((pt) => personFilter.has(pt.person!.residentStatus!))
          .filter((pt) => personNameMatchesSearch(pt.person!, nameFilter))
          .filter((pt) => (pt.tasks ?? []).filter(isIncomplete).length > 0)
      : []
  const upcomingTasksCount = filteredPersonTasks.reduce(
    (count, pt) => count + getUpcomingTasks(pt).length,
    0
  )
  const todoTasksCount = filteredPersonTasks.reduce(
    (count, pt) => count + getTodoTasks(pt).length,
    0
  )
  const copyOfTasks = cloneDeep(filteredPersonTasks)
  const sortedPersonTasks = filterTodoTasks
    ? sortPeopleByTodoTasks(copyOfTasks)
    : sortPeopleByUpcomingTasks(copyOfTasks)

  return (
    <Content>
      {showCloseTasks && (
        <RemoveTasksModal
          incompleteTasks={showCloseTasks.tasks}
          done={async (didCloseTasks) => {
            if (didCloseTasks) {
              await Promise.all([refreshTasks(), refreshStats()])
            }

            setShowCloseTasks(undefined)
          }}
          person={showCloseTasks.person}
        />
      )}
      {showUploader && (
        <GeneralUploader
          person={showUploader.person}
          tasks={[showUploader.task]}
          initialType={showUploader.task.taskTemplateInfo!.dataType}
          initialCustomType={showUploader.task.taskTemplateInfo!.customType}
          onUpdate={async () => {
            await Promise.all([refreshTasks(), refreshStats()])
          }}
          onClose={() => setShowUploader(undefined)}
        />
      )}
      <h1 className={styles.pageTitle}>Compliance Dashboard</h1>
      <ResidentCompletionBanner
        facilityId={facilityId}
        orgId={orgId}
        stats={completionStats}
        availableCompletionTasks={availableCompletionTasks}
        completionTypeToTemplateHash={completionTypeToTemplateHash}
      />
      <div className={styles.taskSearch}>
        <h1 className={styles.pageSubtitle}>Open Tasks</h1>
        <PersonSearchBox onChange={setNameFilter} value={nameFilter} />
      </div>
      <Card className="mb-[16px] mt-[32px] flex items-center justify-between">
        <div className="flex items-center text-[14px]">
          <i className="fa-solid fa-filter mr-[8px]" />
          <div className="font-semibold">Filter by...</div>
          <input
            className={styles.filterBadge}
            onChange={(event) => {
              setFilterTodoTasks(event.target.checked)
              setFilterUpcomingTasks(!event.target.checked)
            }}
            id="todo-filter"
            type="radio"
            value="todo"
            checked={filterTodoTasks}
            name="task-filter"
          />
          <label className={styles.filterLabel} htmlFor="todo-filter">
            To Do ({todoTasksCount})
          </label>
          <input
            className={styles.filterBadge}
            onChange={(event) => {
              setFilterUpcomingTasks(event.target.checked)
              setFilterTodoTasks(!event.target.checked)
            }}
            id="upcoming-filter"
            type="radio"
            value="upcoming"
            checked={filterUpcomingTasks}
            name="task-filter"
          />
          <label className={styles.filterLabel} htmlFor="upcoming-filter">
            Upcoming ({upcomingTasksCount})
          </label>
        </div>
        <ProspectResidentSelector
          personFilter={personFilter}
          setPersonFilter={setPersonFilter}
          personTasks={personTasks}
        />
      </Card>
      {sortedPersonTasks.length === 0 && (
        <Card id={'no-matches'}>
          {nameFilter.length > 0
            ? `No residents found matching "${nameFilter}"`
            : `No residents found matching your filters.`}
        </Card>
      )}
      {sortedPersonTasks.map((personTask, ix) => {
        if (filterUpcomingTasks && getUpcomingTasks(personTask).length === 0) {
          return null
        }

        return (
          <PersonCard
            key={`person-card-${ix}`}
            personTask={personTask}
            setShowCloseTasks={setShowCloseTasks}
            setShowUploader={setShowUploader}
            showOnlyTodoTasks={filterTodoTasks}
          />
        )
      })}
    </Content>
  )
}
