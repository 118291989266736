import { ReactNode, useEffect, useState } from 'react'
import {
  isAuthenticatedUserKey,
  isCognitoIdToken,
  logoutIfNoSessionIsStored,
} from '@shared/components/Auth/helpers'

interface Props {
  children: ReactNode
}

export const UnauthedUserSessionSynchronizer = ({ children }: Props) => {
  const [loggedOut, setLoggedOut] = useState(false)

  function syncSessionState(event: StorageEvent) {
    if (!loggedOut) {
      const { key, newValue } = event
      logoutIfNoSessionIsStored(event)

      if (isCognitoIdToken(key) && newValue !== null) {
        setLoggedOut(false)
        window.location.reload()
      }

      if (isAuthenticatedUserKey(key) && newValue !== null) {
        setLoggedOut(false)
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('storage', syncSessionState)
    return () => window.removeEventListener('storage', syncSessionState)
  }, [])

  return children
}
