import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { formatDateTime } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import AssessmentPage from '@app/pages/Tasks/ResidentAssessment/AssessmentPage'
import { getOrderedCategories } from '@app/pages/Tasks/ResidentAssessment/helpers'
import LevelBadges from '@app/pages/Tasks/ResidentAssessment/LevelBadges'

export type PrintableAssessmentProps = {
  person: Person
  assessment: Snapshot
  categories: AppraisalCategory[]
  printBlank?: boolean
  printWithoutName?: boolean
}

export function PrintableAssessment({
  person,
  assessment: defaultAssessment,
  categories,
  printBlank = false,
  printWithoutName = false,
}: PrintableAssessmentProps) {
  const assessment = printBlank ? {} : defaultAssessment
  let title = 'Resident Assessment'
  if (!printWithoutName) {
    title += ` for ${getFirstAndLastName(person.name)}`
  }

  return (
    <div style={{ margin: '100px' }}>
      <div className="text-[24px]">
        <span>{title}</span>
        <LevelBadges assessment={assessment} />
      </div>
      {assessment.createdAt && (
        <div className="secondary-text leading-[16px]">
          created on{' '}
          {formatDateTime(assessment.createdAt, { includeDate: true })}
        </div>
      )}
      <div className="mt-[32px]">
        <AssessmentPage
          showAdmissionTypeAndAssessmentReason={true}
          mode={{ tag: 'view' }}
          person={person}
          snapshot={assessment}
          categories={getOrderedCategories({ categories })}
        />
      </div>
    </div>
  )
}
