import { Link, useRouteMatch } from 'react-router-dom'
import { tw, twx } from '@shared/utils/tailwind'

export type RouterTab<T> = {
  id: T
  label: string
  path: string
}

export default function RouterTabBar<T extends string>({
  tabs,
  containerClassName,
}: {
  tabs: RouterTab<T>[]
  containerClassName?: string
}) {
  const className = twx(
    `flex bg-white rounded-md overflow-hidden`,
    containerClassName
  )

  return (
    <ul className={className} role="tablist">
      {tabs.map((tab) => {
        return <RouterTabBarItem tab={tab} key={tab.id} />
      })}
    </ul>
  )
}

function RouterTabBarItem<T extends string>({ tab }: { tab: RouterTab<T> }) {
  const match = useRouteMatch(tab.path)
  const isActive = Boolean(match)

  const linkClassName = twx(
    `px-6 py-5 uppercase text-sm`,
    isActive ? 'text-primary font-bold' : 'text-gray-07 font-semibold'
  )
  const bottomBarClassName = twx(`absolute bottom-0 h-1 w-full bg-primary`, {
    hidden: !isActive,
  })

  return (
    <li className={tw`flex items-center`}>
      <div className={tw`relative flex`}>
        <Link id={tab.id} to={tab.path} className={linkClassName}>
          {tab.label}
        </Link>
        <span className={bottomBarClassName} />
      </div>

      <span className="mx-1 h-1/2 w-px bg-secondary-10" />
    </li>
  )
}
