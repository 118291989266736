import { MedicationStatement } from '@augusthealth/models/com/august/protos/medication_statement'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import {
  dosageTypeToDisplay,
  isVitalOrder,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import { MedicationOrderDoseSchedules } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/MedicationOrderDoseSchedules'
import { VitalOrderSchedule } from '@app/components/Residents/Medications/Orders/VitalOrderSchedule'
import {
  canAddOrRemoveDoseParts,
  doesFormHaveSchedulingErrors,
  getMedOrderDosageType,
  mapFormMedToMedOrder,
} from '../helpers'
import { ScheduleBadges } from './ScheduleBadges'

type Props = {
  order: MedicationOrder
  use24HourTime: boolean
  readOnly: boolean
}
export default function ReviewOrderScheduleCard(props: Props) {
  const { order, use24HourTime, readOnly } = props
  const [expanded, setExpanded] = useState(false)
  const methods = useFormContext<MedOrderFormData>()

  const { formState, watch } = methods

  const medOrderFormData = watch()
  const formHasErrors = doesFormHaveSchedulingErrors(formState)
  const wholeOrderDoseType = getMedOrderDosageType(
    mapFormMedToMedOrder({
      formData: medOrderFormData,
      medOrder: order,
    }).medicationStatement as MedicationStatement
  )

  const canAddOrRemoveDoses = canAddOrRemoveDoseParts(order, medOrderFormData)
  const isVital = isVitalOrder(order)

  return (
    <CollapsibleCard
      defaultExpanded={true}
      enableAutoScrollOnExpand={false}
      expanded={formHasErrors}
      expandTrigger={formState.isSubmitting}
      contentTestId={`${wholeOrderDoseType}-schedule-card`}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      cardTitle={
        <div className={tw`w-full`}>
          {expanded ? (
            <MedOrderCardTitle value={'Schedule and Dosage...'} />
          ) : (
            <>
              <MedOrderCardTitle
                value={`${dosageTypeToDisplay(wholeOrderDoseType)} Schedule...`}
              />
              <ScheduleBadges
                order={order}
                doseType={wholeOrderDoseType}
                use24HourTime={use24HourTime}
              />
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
      role={'list'}
    >
      {isVital ? (
        <VitalOrderSchedule order={order} index={0} />
      ) : (
        <MedicationOrderDoseSchedules
          canAddOrRemoveDoses={canAddOrRemoveDoses}
          readOnly={readOnly}
          order={order}
          isNewOrder={false}
        />
      )}
    </CollapsibleCard>
  )
}
