import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import localForage from 'localforage'
import { matchPath } from 'react-router-dom'
import { initAmplify } from '@shared/amplify'
import {
  endSession,
  fetchUser,
  loginWithMagicLink as loginWithMagicLinkApi,
  loginWithUsernameAndPassword,
} from '@shared/api/user'
import { clearCachesAndRedirect, logout } from '@shared/components/Auth/Auth'
import environment from '@shared/environment'
import { scheme } from '@shared/hooks/useCurrentPage'
import { RequiredUserActionType } from '@shared/types/auth'
import { isSuperUser } from '@shared/utils/user'

const AUTHENTICATED_USER_KEY = 'authenticatedUser'

export function authEnabled(): boolean {
  return environment.authMethod !== 'none'
}

export function cookieAuthEnabled(): boolean {
  return environment.authMethod === 'cookie'
}

export function cognitoAuthEnabled(): boolean {
  return environment.authMethod === 'cognito'
}

export function initAuth(): void {
  if (cognitoAuthEnabled()) {
    initAmplify()
  }
}

interface LoginResult {
  action: RequiredUserActionType
  userAlias?: string
}

type CognitoUserWithChallengeParam = CognitoUser & {
  challengeParam?: {
    userAttributes?: {
      email?: string
      preferred_username?: string
    }
  }
}

async function handleCognitoLogin(
  username: string,
  password: string
): Promise<LoginResult> {
  Auth.configure({
    authenticationFlowType: 'USER_SRP_AUTH',
  })
  const user = (await Auth.signIn({ username, password })) as CognitoUser
  const { challengeName } = user

  if (challengeName === 'NEW_PASSWORD_REQUIRED') {
    const { preferred_username = '', email = '' } =
      (user as CognitoUserWithChallengeParam).challengeParam?.userAttributes ||
      {}

    return {
      userAlias: preferred_username || email,
      action: RequiredUserActionType.NEW_PASSWORD_REQUIRED,
    }
  }

  return { action: RequiredUserActionType.NO_ACTION, userAlias: username }
}

async function handleLogin(
  username: string,
  password: string
): Promise<LoginResult> {
  const response = await loginWithUsernameAndPassword(username, password)

  return {
    action: response.action,
    userAlias: response.username,
  }
}

export async function login(
  username: string,
  password: string
): Promise<LoginResult> {
  if (cognitoAuthEnabled()) {
    return handleCognitoLogin(username, password)
  } else if (cookieAuthEnabled()) {
    const response = await handleLogin(username, password)
    if (response.userAlias) {
      await setAuthenticatedUser(response.userAlias)
    }

    return response
  }

  throw new Error(`Cannot login with auth method ${environment.authMethod}`)
}

export async function loginWithMagicLink(
  email: string,
  code: string
): Promise<void> {
  if (cognitoAuthEnabled()) {
    const currentSessionAuthStatus = await cognitoLoggedInAs()
    const isUserLoggedIn = currentSessionAuthStatus.loggedIn
    const isLoggedInAsTargetUser =
      currentSessionAuthStatus.currentUserEmail &&
      currentSessionAuthStatus.currentUserEmail === email

    // If the current session is logged in as someone other than the target user (the one in the link), log them out before proceeding
    if (isUserLoggedIn && !isLoggedInAsTargetUser) {
      await logout()
    }

    if (!isLoggedInAsTargetUser) {
      const session = (await Auth.signIn(email)) as unknown
      await Auth.sendCustomChallengeAnswer(session, code)
    }
  } else if (cookieAuthEnabled()) {
    // We shouldn't have to check if the user is already logged in here.
    // If the session cookie in the request is for the same user, the backend will return early.
    // If it's for a different user, the associated session will be terminated before processing the code.
    const username = await loginWithMagicLinkApi(email, code)
    await setAuthenticatedUser(username)
  }
}

async function cognitoLoggedInAs(): Promise<{
  loggedIn: boolean
  currentUserEmail?: string
}> {
  try {
    const currentSession = await Auth.currentSession()
    const decodedIdentityToken = currentSession.getIdToken().decodePayload()
    return {
      loggedIn: true,
      currentUserEmail: decodedIdentityToken['email']
        ? String(decodedIdentityToken['email'])
        : undefined,
    }
  } catch {
    return { loggedIn: false }
  }
}

export async function logoutForAuthMethod(): Promise<void> {
  if (cognitoAuthEnabled()) {
    await Auth.signOut()
  } else if (cookieAuthEnabled()) {
    await endSession()
  }
}

export async function setAuthenticatedUser(username: string): Promise<void> {
  await localForage.setItem(AUTHENTICATED_USER_KEY, username)
}

export async function getAuthenticatedUser(): Promise<string | null> {
  return localForage.getItem(AUTHENTICATED_USER_KEY)
}

export async function clearAuthenticatedUser(): Promise<void> {
  await localForage.removeItem(AUTHENTICATED_USER_KEY)
}

export async function userHasAccessToOrg(redirectPath: string) {
  const user = await fetchUser()

  const matchResult = matchPath<{
    orgId?: string
    personId?: string
    facilityId?: string
  }>(redirectPath, {
    path: scheme,
  })

  return (
    (user.groups ?? []).some(
      (g) => g.personMatcher?.organizationId === matchResult?.params.orgId
    ) || isSuperUser(user)
  )
}

export const logoutIfNoSessionIsStored = (event: StorageEvent) => {
  const { key, oldValue, newValue } = event
  if (cognitoAuthEnabled()) {
    if (isCognitoIdToken(key) && newValue === null) {
      void logout()
    }
  }

  if (cookieAuthEnabled() && isAuthenticatedUserKey(key)) {
    const userHasChanged = oldValue !== null && oldValue !== newValue
    const noUser = newValue === null
    if (userHasChanged || noUser) {
      clearCachesAndRedirect({ clearUser: false })
    }
  }

  // The key is null when window.localStorage.clear() is called
  if (key === null) {
    void logout()
  }
}

export const isCognitoIdToken = (key: string | null) => {
  return (
    typeof key === 'string' &&
    key.startsWith('CognitoIdentityServiceProvider') &&
    key.endsWith('idToken')
  )
}

export const isAuthenticatedUserKey = (key: string | null) => {
  return typeof key === 'string' && key.endsWith(AUTHENTICATED_USER_KEY)
}
