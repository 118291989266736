import { ResultsOrErrors } from '@augusthealth/models/com/august/protos/api/import_results'
import { ImportKind } from '@augusthealth/models/com/august/protos/data_import'
import { useContext, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import { fetchImportPreview, importFile } from '@app/api/imports'
import HUD from '@app/components/HUD'
import ImportUploader from '@app/components/ImportUploader'
import { BaseOrgSelect } from '../SharedSelects/base/BaseOrgSelect'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import ImportList from './ImportList'
import PreviewTable from './PreviewTable'

export default function FileImport() {
  const { setError } = useContext(GlobalContext)

  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [isForceImport, setIsForceImport] = useState(false)
  const [hasError, setHasError] = useState<boolean>()
  const [fileToUpload, setFileToUpload] = useState<File>()
  const [previewResponse, setPreviewResponse] =
    useState<Loading<ResultsOrErrors[]>>()
  const [importResponse, setImportResponse] =
    useState<Loading<ResultsOrErrors[]>>()

  const importKindOptions: OptionTypeBase<ImportKind>[] = [
    { value: ImportKind.IMPORT_KIND_RESIDENT, label: 'Resident' },
    { value: ImportKind.IMPORT_KIND_USER, label: 'User' },
    { value: ImportKind.IMPORT_KIND_FACILITY_FIELDS, label: 'Facility Fields' },
  ]
  const [importKind, setImportKind] = useState<ImportKind>(
    importKindOptions[0].value
  )

  const isFacilityFieldsImport =
    importKind === ImportKind.IMPORT_KIND_FACILITY_FIELDS
  const requestPreview = async () => {
    setPreviewResponse({ tag: 'Loading' })
    try {
      const { results } = await fetchImportPreview({
        facilityId: isFacilityFieldsImport
          ? undefined
          : selectedFacility?.value.id,
        orgId: selectedOrganization!.value.id,
        file: fileToUpload!,
        force: isForceImport,
        importKind,
      })
      setPreviewResponse({ tag: 'Complete', value: results ?? [] })
      setHasError(results?.some((r) => r.error))
    } catch (e) {
      setPreviewResponse(undefined)
      setError(e)
    }
  }
  const requestImport = async () => {
    setImportResponse({ tag: 'Loading' })
    try {
      const { results } = await importFile({
        facilityId: isFacilityFieldsImport
          ? undefined
          : selectedFacility?.value.id,
        orgId: selectedOrganization!.value.id,
        file: fileToUpload!,
        force: isForceImport,
        importKind,
      })
      setImportResponse({ tag: 'Complete', value: results ?? [] })
      setPreviewResponse(undefined)
    } catch (e) {
      setImportResponse(undefined)
      setError(e)
    }
  }

  const shouldDisablePreview =
    !selectedOrganization ||
    (!isFacilityFieldsImport && !selectedFacility) || // Facility Fields doesn't require Facility dropdown
    !fileToUpload ||
    previewResponse?.tag === 'Loading'

  return (
    <div className="content-holder">
      <h1 className="page-title mt-[24px]">
        Resident / User / Facility Fields Import
      </h1>
      <div
        style={{
          display: 'grid',
          gap: '32px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(496px, 1fr))',
        }}
      >
        <ImportList importKind={importKind} importResponse={importResponse} />
        <div>
          <h2 className="mb-[24px] text-[20px] font-semibold leading-[24px]">
            Start New Import
          </h2>
          <h3 className="text-[14px] font-bold uppercase leading-[18px]">
            Select an Import Type
          </h3>
          <Card>
            <StyledSelect
              className="w-full"
              options={importKindOptions}
              name={'import_kind'}
              id={'import_kind'}
              onChange={(o: OptionTypeBase<ImportKind>) => {
                setImportKind(o.value)
              }}
              value={importKindOptions.find((opt) => opt.value === importKind)}
            />
          </Card>
          <section className="mt-[32px]">
            <h3 className="mt-[32px] text-[14px] font-bold uppercase leading-[18px]">
              Upload a File
            </h3>
            <ImportUploader
              importFile={fileToUpload}
              setImportFile={setFileToUpload}
              fileFormat={{
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
              }}
            />
          </section>
          <section className="mt-[32px]">
            <h3 className="text-[14px] font-bold uppercase leading-[18px]">
              Select a {isFacilityFieldsImport ? 'Organization' : 'Facility'}
            </h3>
            <Card className="h-full flex-col justify-between">
              <div className="mb-[16px]">
                {isFacilityFieldsImport ? (
                  <BaseOrgSelect
                    onOrgSelect={setSelectedOrganization}
                    selectedOrganization={selectedOrganization}
                  />
                ) : (
                  <OrgFacilitySelects
                    onOrgSelect={setSelectedOrganization}
                    onFacilitySelect={setSelectedFacility}
                    selectedOrganization={selectedOrganization}
                    selectedFacility={selectedFacility}
                  />
                )}
              </div>
              {!isFacilityFieldsImport && (
                <BasicCheckbox
                  name="force-import"
                  checked={isForceImport}
                  onChange={(e) => setIsForceImport(e.currentTarget.checked)}
                >
                  Import even if facility has existing{' '}
                  {importKind === ImportKind.IMPORT_KIND_USER
                    ? 'users'
                    : 'residents'}
                </BasicCheckbox>
              )}
              <AsyncIconButton
                isLoading={previewResponse?.tag === 'Loading'}
                disabled={shouldDisablePreview}
                className="mt-[16px]"
                buttonStyle="primary-fill"
                id="preview-import"
                onClick={requestPreview}
              >
                Preview Import
              </AsyncIconButton>
            </Card>
          </section>
        </div>
      </div>
      {previewResponse?.tag === 'Complete' && (
        <Modal
          open={true}
          id={'preview-import'}
          onClose={() => setPreviewResponse(undefined)}
          contentClassName={twx('text-secondary-02 h-full w-[1040px]')}
        >
          <ModalTitle>Preview Import Results</ModalTitle>
          <PreviewTable results={previewResponse.value} />
          <div className="mt-8 flex w-full">
            <AsyncIconButton
              buttonStyle="secondary-outline"
              onClick={() => setPreviewResponse(undefined)}
              id="cancel-import"
              className="mr-[16px]"
              width="136px"
            >
              Cancel
            </AsyncIconButton>
            <AsyncIconButton
              isLoading={importResponse?.tag === 'Loading'}
              disabled={
                !fileToUpload || importResponse?.tag === 'Loading' || hasError
              }
              buttonStyle="primary-fill"
              id="complete-import"
              onClick={requestImport}
              width="144px"
            >
              Continue Import
            </AsyncIconButton>
          </div>
        </Modal>
      )}
      {importResponse?.tag === 'Complete' && (
        <HUD milliseconds={3500}>
          <div className="text-[16px] font-semibold leading-[24px]">
            Import Queued Successfully!
          </div>
          <div className="text-[16px] leading-[24px]">
            Results will be sent to your <br />
            email when they are available.
          </div>
        </HUD>
      )}
    </div>
  )
}
