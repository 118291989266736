import { Vital } from '@augusthealth/models/com/august/protos/vital'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { TimeZoneConvertFunc } from '@shared/contexts/CurrentFacilityContext'
import { formatDateToDateAtTimeLabel } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import {
  BloodPressureOptions,
  BloodSugarOptions,
  getVitalValueForType,
  OxygenSaturationOptions,
  TemperatureOptions,
  VITALS_INPUT_PROPS_MAP,
  VitalsType,
} from '@shared/utils/vitals'

export function getVitalsForTab({
  activeTab,
  allVitals,
}: {
  activeTab?: VitalsType
  allVitals: Loading<Vital[]>
}) {
  if (allVitals.tag === 'Complete' && !!activeTab) {
    const vitalType = activeTab

    return allVitals.value.filter(
      (v) => !!v && v.panel && !!v.panel[vitalType!]
    )
  }

  return []
}

const findMatchingValue = (
  options: OptionTypeBase<any>[],
  valueToFind: any
): string | null => {
  return options.find((opt) => opt.value === valueToFind)?.label ?? null
}

function getOptionalVitalsLabelForType({
  vital,
  vitalType,
}: {
  vital: Vital
  vitalType: VitalsType
}): string | null {
  const panel = vital.panel ? vital.panel[vitalType] : null
  if (panel) {
    if ('type' in panel)
      return findMatchingValue(TemperatureOptions, panel.type)
    if ('position' in panel)
      return findMatchingValue(BloodPressureOptions, panel.position)
    if ('timing' in panel)
      return findMatchingValue(BloodSugarOptions, panel.timing)
    if ('supplemental' in panel)
      return findMatchingValue(OxygenSaturationOptions, panel.supplemental)
  }
  return null
}

export function getCreationTime(vital: Vital) {
  return vital.createdBy?.modificationTime
}

export function getModificationTime(vital: Vital) {
  return vital.updatedBy?.modificationTime
}

export function getModifiedDateString(
  vital: Vital,
  timezoneCoverter?: TimeZoneConvertFunc
) {
  if (!vital.recordedAt) {
    return ''
  }

  const dateTime = timezoneCoverter
    ? timezoneCoverter(vital.recordedAt)
    : vital.recordedAt

  return formatDateToDateAtTimeLabel(dateTime)
}

export function getModifiedVerb(vital: Vital) {
  const creationTime = getCreationTime(vital)
  const modificationTime = getModificationTime(vital)

  return modificationTime === creationTime ? 'Added' : 'Edited'
}

export function getModifiedByName(vital: Vital) {
  const userName = vital.updatedBy?.modifiedByUserName
  return getFullName(userName)
}

export function formatVital({
  vital,
  activeTab,
  timezoneCoverter,
}: {
  vital: Vital
  activeTab: VitalsType
  timezoneCoverter?: TimeZoneConvertFunc
}) {
  if (!activeTab || !vital?.panel) {
    return {}
  }

  const { id } = vital

  const vitalType = activeTab

  const { unitsLabel } = VITALS_INPUT_PROPS_MAP(vitalType!)
  const date = getModifiedDateString(vital, timezoneCoverter)
  const modifiedBy = `${getModifiedVerb(vital)} by ${getModifiedByName(vital)}`

  return {
    value: getVitalValueForType({
      vital: vital as Required<Vital>,
      vitalType: vitalType!,
    }),
    unitsLabel,
    optionalLabel: getOptionalVitalsLabelForType({
      vital,
      vitalType: vitalType!,
    }),
    date,
    modifiedBy,
    id,
  }
}
