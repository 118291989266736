import {
  ManualPaymentEntry,
  ManualPaymentRequest,
  PaymentMethod,
  ResidentListEntry,
} from '@shared/types/billing'
import { formatIsoDate } from '@shared/utils/date'

export interface BulkPaymentsForm {
  residents: {
    resident: ResidentListEntry
    payments: SinglePaymentForm[]
  }[]
  externalId: string
  depositDate: Date
}

interface SinglePaymentForm {
  checkNumber: string
  checkDate: Date | null
  amountInCents: number | undefined
}

export function isValidPaymentRow(p: SinglePaymentForm) {
  return Boolean(p.checkNumber && p.checkDate && p.amountInCents)
}

export function needsValidation(p: SinglePaymentForm) {
  return Boolean(p.checkNumber || p.checkDate || p.amountInCents)
}

export function formToRequest(data: BulkPaymentsForm): ManualPaymentRequest {
  // Convert to API format

  const entries: ManualPaymentEntry[] = data.residents.flatMap((r) =>
    r.payments.filter(isValidPaymentRow).map((p) => ({
      personId: r.resident.personId,
      checkNumber: p.checkNumber,
      paymentDate: formatIsoDate(p.checkDate!),
      amountCents: p.amountInCents!,
    }))
  )

  return {
    depositDate: formatIsoDate(data.depositDate),
    externalId: data.externalId,
    paymentMethod: PaymentMethod.CHECK,
    payments: entries,
  }
}
