import { BillingSettings } from '@augusthealth/models/com/august/protos/settings/billing_settings'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import { getBillingSettings, updateBillingSettings } from '@shared/api/billing'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { tw } from '@shared/utils/tailwind'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { Table, TableContainer } from '@app/components/Table'
import DaySettingRow from './DaySettingRow'
import LoadingTable from './LoadingTable'
import TextSettingModal from './TextSettingModal'
import TextSettingRow from './TextSettingRow'

export type SettingsTableProps = {
  orgId: string
  facilityId: string
  setHudText: (text: string) => void
}

export type InputType =
  | 'checkPayableText'
  | 'glCodes.accountsReceivable'
  | 'glCodes.cash'
  | 'glCodes.fees'

export default function SettingsTable(props: SettingsTableProps) {
  const { setError } = useContext(GlobalContext)
  const [modalType, setModalType] = useState<InputType | null>(null)
  const [isSettingsLoading, setIsSettingsLoading] = useState(true)

  async function loadBillingSettings() {
    setIsSettingsLoading(true)
    let resp: BillingSettings = {}
    try {
      resp = await getBillingSettings(props)
    } catch (e) {
      setError(e)
    } finally {
      setIsSettingsLoading(false)
    }
    return resp
  }

  const methods = useForm<BillingSettings>({
    defaultValues: async () => loadBillingSettings(),
  })

  const {
    handleSubmit,
    formState,
    formState: { isSubmitted, disabled, isLoading, isDirty },
    reset,
  } = methods

  const {
    defaultDueDate: { dayOfMonth = undefined } = {},
    checkPayableText,
    glCodes: { accountsReceivable = '', cash = '', fees = '' } = {},
  } = useWatch({ control: methods.control })

  function emptyFormValueToNull(formData: BillingSettings) {
    return Object.entries(formData).reduce((acc, [key, value]) => {
      if (typeof value === 'object') {
        acc[key] = emptyFormValueToNull(value)
        return acc
      }

      acc[key] = ['', undefined].includes(value) ? null : value
      return acc
    }, {})
  }

  async function onSubmit(formData: BillingSettings) {
    try {
      const { orgId, facilityId } = props
      const settings = emptyFormValueToNull(formData)
      await updateBillingSettings({ orgId, facilityId, settings })
      props.setHudText('Settings saved successfully!')
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    reset({}, { keepValues: true })
  }, [isSubmitted])

  function getModalByType(type: InputType) {
    const coreProps = {
      closeFn: () => setModalType(null),
    }
    const propsDict = {
      checkPayableText: {
        title: 'Edit Check Payable Text',
        inputName: 'checkPayableText',
        inputLabel: 'Check Payable Text',
      },
      'glCodes.accountsReceivable': {
        title: 'Edit Revenue Account',
        inputName: 'glCodes.accountsReceivable',
        inputLabel: 'Revenue Account',
      },
      'glCodes.cash': {
        title: 'Edit Cash Account',
        inputName: 'glCodes.cash',
        inputLabel: 'Cash Account',
      },
      'glCodes.fees': {
        title: 'Edit Fees Account',
        inputName: 'glCodes.fees',
        inputLabel: 'Fees Account',
      },
    }
    const props = propsDict[type]
    return <TextSettingModal {...coreProps} {...props} />
  }

  if (isSettingsLoading) {
    return <LoadingTable />
  }

  return (
    <FormProvider {...methods}>
      <form
        className={tw`flex flex-col gap-2 pb-8`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={tw`flex flex-col py-8`}>
          <PersonPageTitle title="General Settings" withBorder={false} />
          <TableContainer>
            <Table className="table-auto">
              <tbody>
                <DaySettingRow data={dayOfMonth} />
                <TextSettingRow
                  title="Make checks payable to"
                  data={checkPayableText}
                  handleEditClick={() => setModalType('checkPayableText')}
                />
                <TextSettingRow
                  title="Revenue account"
                  data={accountsReceivable}
                  handleEditClick={() =>
                    setModalType('glCodes.accountsReceivable')
                  }
                />
                <TextSettingRow
                  title="Cash account"
                  data={cash}
                  handleEditClick={() => setModalType('glCodes.cash')}
                />
                <TextSettingRow
                  title="Fees account"
                  data={fees}
                  handleEditClick={() => setModalType('glCodes.fees')}
                />
              </tbody>
            </Table>
            <div className={tw`mb-4 mt-4 flex w-full justify-end pr-3`}>
              <AsyncIconButton
                initialIcon="save"
                disabled={
                  isSubmitted ||
                  !isDirty ||
                  formState.isSubmitting ||
                  disabled ||
                  isLoading
                }
                isLoading={formState.isSubmitting || isLoading}
                buttonStyle="primary-fill"
                buttonSize="medium"
                type="submit"
              >
                Save settings
              </AsyncIconButton>
            </div>
          </TableContainer>
        </div>
      </form>
      {modalType && <>{getModalByType(modalType)}</>}
      <Prompt
        when={isDirty && !isSubmitted}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    </FormProvider>
  )
}
