import { Task } from '@augusthealth/models/com/august/protos/task'
import { isEqual, sortBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DocumentViewerUploaderFooter } from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LightboxSidebarTitle } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import {
  AugustError,
  isRequiredSignatureError,
  requiredSignatureError,
} from '@shared/utils/error'
import { covidDiseaseName } from '@shared/utils/immunizations'
import { getOrElse } from '@shared/utils/loading'
import { isSignable } from '@shared/utils/taskTemplateInfo'
import { useImmunizationsByPerson } from '@app/hooks/useImmunizations'
import useUploadableDocuments from '@app/hooks/useUploadableDocuments'
import {
  FormValues,
  getFormPerDataType,
} from '@app/pages/Documents/Uploader/Forms'
import styles from '@app/pages/Documents/Uploader/styles.module.css'
import VaccineViewer from '@app/pages/Documents/Viewer/VaccineViewer'
import {
  configuredNameForDataType,
  isPermittedOption,
  submitDocument,
} from './helpers'
import Uploader from './Uploader'

interface Props {
  person: Person
  tasks: Task[]
  onClose: () => void
  onUpdate: (fileUploaded?: boolean) => Promise<void>
  initialType?: DataType
  initialCustomType?: string
}

export type SelectDocumentUploadType = {
  dataType?: DataType
  customType?: string
  isSignable?: boolean
}

type SelectLabelValue = OptionTypeBase<SelectDocumentUploadType>

export default function GeneralUploader({
  onUpdate,
  onClose,
  person,
  tasks,
  initialType,
  initialCustomType,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const [submitting, setSubmitting] = useState(false)
  const [showUploadInProgress, setShowUploadInProgress] = useState(false)
  const [files, setFiles] = useState<File[]>()
  const [currentDocument, setCurrentDocument] =
    useState<SelectDocumentUploadType>({
      dataType: initialType,
      customType: initialCustomType,
    })
  const [formData, setFormData] = useState<{
    component: ((props: any) => JSX.Element) | null
    submitTestId: string
  }>()
  const onComplete = async (updated: boolean) => {
    if (updated) {
      await onUpdate()
    }
    onClose()
  }
  const methods = useForm<FormValues>()
  const { handleSubmit, formState, reset } = methods

  const { uploadableDocuments } = useUploadableDocuments({
    tasks,
    overrideIds: {
      orgId: person.orgId,
      facilityId: person.facilityId,
      personId: person.id,
    },
  })

  const { immunizations, reloadImmunizations } = useImmunizationsByPerson({
    person,
  })

  const namedUploadOptions = getOrElse(uploadableDocuments, []).map((o) => {
    return {
      label: configuredNameForDataType({
        tasks,
        configuredTemplate: o,
      }),
      value: {
        dataType: o.dataType,
        customType: o.customType,
        isSignable: isSignable(o),
      },
    }
  })

  const uploadOptions: SelectLabelValue[] = [
    ...sortBy(
      namedUploadOptions.filter((uploadOption) =>
        isPermittedOption({ uploadOption, user, person })
      ),
      (o) => o.label
    ),
    {
      label: 'Other Documents',
      value: { dataType: DataType.DATA_TYPE_OTHER_UPLOAD },
    },
  ]

  useEffect(() => {
    reset()
    setFormData(getFormPerDataType(currentDocument.dataType))
  }, [currentDocument])

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true)
    setShowUploadInProgress(true)

    try {
      await submitDocument({ files: files!, person, tasks, data })
      await onComplete(true)
    } catch (e) {
      if (isRequiredSignatureError(e as AugustError)) {
        setError(requiredSignatureError)
      } else {
        setError(e)
      }
    } finally {
      setSubmitting(false)
      setShowUploadInProgress(false)
    }
  }

  // Immunizations don't require a file attachment; all other types do.
  const isImmuniztionrecord =
    currentDocument.dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD
  const [allowToSubmitWithoutFiles, setAllowToSubmitWithoutFiles] =
    useState(isImmuniztionrecord)
  const noFiles = !files || files.length === 0
  const isSubmitDisabled = submitting || (!allowToSubmitWithoutFiles && noFiles)

  const ChosenFormComponent = formData?.component

  if (
    currentDocument.dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD &&
    immunizations.tag === 'Complete'
  ) {
    /* TODO: This is a hack to get the immunization form to render when chosen
        from the dropdown. Doing this right would be a bit of a pain; there
        is an example of how it could work in ResidentDocumentUploader. */

    return (
      <VaccineViewer
        diseaseName={covidDiseaseName}
        immunizations={immunizations.value}
        person={person}
        onUpdate={async () => {
          await reloadImmunizations()
          await onUpdate()
        }}
        onClose={onClose}
        displayUploaderByDefault
      />
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Uploader
          uploadInProgress={showUploadInProgress}
          onFileChange={setFiles}
          defaultButtonText="Upload Document"
          popUpActions={
            <DocumentViewerUploaderFooter
              yesBtn={{
                label: 'Save',
                props: {
                  disabled: isSubmitDisabled,
                  'data-testid': formData?.submitTestId,
                },
                hideButton: !currentDocument.dataType,
              }}
              noBtn={{
                action: () => void onComplete(false),
                label: 'Cancel',
              }}
              formState={formState}
            />
          }
        >
          <LightboxSidebarTitle>Upload Document</LightboxSidebarTitle>
          <div className={styles.formContainer}>
            <LabelAboveInput htmlFor="input-documentType">
              <span className={styles.labelText}>Type</span>
            </LabelAboveInput>
            <StyledSelect
              placeholder={
                uploadableDocuments.tag === 'Loading'
                  ? 'Loading...'
                  : 'Select...'
              }
              isDisabled={initialType !== undefined}
              value={uploadOptions.find(
                (o) =>
                  o.value.dataType === currentDocument.dataType &&
                  o.value.customType === currentDocument.customType
              )}
              options={uploadOptions}
              name="documentType"
              id="documentType"
              instanceId={'documentType'}
              onChange={(o: {
                label: string
                value: SelectDocumentUploadType
              }) => {
                setCurrentDocument(o.value)
              }}
            />
            {ChosenFormComponent && uploadableDocuments.tag === 'Complete' && (
              <ChosenFormComponent
                dataType={currentDocument.dataType}
                label={
                  uploadOptions.find((o) => isEqual(o.value, currentDocument))
                    ?.label
                }
                customType={currentDocument.customType}
                isSignable={currentDocument.isSignable}
                setAllowToSubmitWithoutFiles={setAllowToSubmitWithoutFiles}
              />
            )}
          </div>
        </Uploader>
      </form>
    </FormProvider>
  )
}
