import { times } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  BillingCategory,
  BillingEvent,
  BillingFee,
  LedgerExport,
  RecurringCharge,
  ResidentListEntry,
} from '@shared/types/billing'
import { Order } from '@shared/utils/common'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { DownloadMenuButtonProps, MenuItem } from '@app/components/DownloadMenu'
import PersonPageTitle from '@app/components/PersonPageTitle'
import TitleActions from '@app/components/PersonPageTitle/TitleActions'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader, {
  getTableHeaderOnToggle,
  Header,
} from '@app/components/Table/TableHeader'
import {
  ChargesColumnName,
  InvoicesColumnName,
  ResidentsColumnName,
  TransactionsColumnName,
} from '@app/pages/Facilities/Billing/Residents/helpers'
import {
  BillingCategoriesColumnName,
  FeesColumnName,
} from '@app/pages/Facilities/Billing/Settings/helpers'
import { GeneralLedgerColumnName } from '../../Reports/GeneralLedger/helpers'

/**
 * Should this Table UI Component be designed to build any table rather than Billing's tables?
 */

type Rows =
  | BillingFee[]
  | RecurringCharge[]
  | BillingCategory[]
  | BillingEvent[]
  | ResidentListEntry[]
  | LedgerExport[]

type Column =
  | FeesColumnName
  | BillingCategoriesColumnName
  | TransactionsColumnName
  | ResidentsColumnName
  | InvoicesColumnName
  | ChargesColumnName
  | GeneralLedgerColumnName

type BtnProps = {
  label: string
  onClick: () => void
  order?: number
}

type DownloadMenuProps = {
  menuItems: MenuItem[]
  buttonProps: DownloadMenuButtonProps
  order?: number
}

type SearchBoxProps = {
  searchFn: ({ rows, searchTerm }: { rows: Rows; searchTerm: string }) => Rows
  searchPlaceholder: string
  order?: number
}

type Props = {
  rows: Loading<Rows>
  headers: Header[]
  defaultSelectedColumn: Column
  sortingFn?: ({
    rows,
    selectedColumn,
    sortingOrder,
  }: {
    rows: Rows
    selectedColumn: string
    sortingOrder: Order
  }) => Rows
  title: ReactNode
  children: (sortedRows: Rows) => React.ReactNode
  addBtnProps?: BtnProps
  downloadMenuProps?: DownloadMenuProps
  searchBoxProps?: SearchBoxProps
  titleActionChildren?: ReactNode
}

export default function WithTable(props: Props) {
  const {
    children,
    title,
    sortingFn,
    headers,
    rows,
    defaultSelectedColumn,
    addBtnProps,
    downloadMenuProps,
    searchBoxProps,
    titleActionChildren,
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const { searchFn, searchPlaceholder } = searchBoxProps || {}
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)
  const [selectedColumn, setSelectedColumn] = useState<Column>(
    defaultSelectedColumn
  )
  const [sortedRows, setSortedRows] = useState<Rows | null>(null)

  useEffect(() => {
    if (rows.tag === 'Complete') {
      const searchedRows = searchFn
        ? searchFn({ rows: rows.value, searchTerm })
        : rows.value

      if (sortingFn) {
        const newSortedRows = sortingFn({
          rows: searchedRows || rows,
          selectedColumn,
          sortingOrder,
        })
        setSortedRows(newSortedRows)
      } else {
        setSortedRows(searchedRows)
      }
    }
  }, [rows, searchTerm, selectedColumn, sortingOrder, searchFn, sortingFn])

  return (
    <div className={tw`flex flex-col py-8`}>
      <PersonPageTitle title={title} withBorder={false}>
        <TitleActions
          searchBoxProps={
            searchBoxProps
              ? {
                  setSearchTerm,
                  searchPlaceholder,
                  searchTerm,
                  order: searchBoxProps.order,
                }
              : undefined
          }
          downloadMenuProps={downloadMenuProps}
          addBtnProps={addBtnProps}
        >
          {titleActionChildren}
        </TitleActions>
      </PersonPageTitle>
      <TableContainer>
        <Table className="table-auto">
          <TableHeader
            headers={headers}
            sortable={
              sortingFn
                ? {
                    onToggle: (colName: string) => {
                      getTableHeaderOnToggle({
                        selectedColumn,
                        selectedOrder: sortingOrder,
                        setColumn: setSelectedColumn as (c: string) => void,
                        setOrder: setSortingOrder,
                      })(colName)
                    },
                    selectedColumn,
                    sortingOrder,
                  }
                : undefined
            }
          />
          {rows.tag === 'Loading' && (
            <tbody>
              {times(5, (i) => (
                <TrWithHoverEffect key={i} onClick={() => {}}>
                  {times(headers.length, (j) => (
                    <TdWithExtraPadding key={j}>
                      <Skeleton height={21} />
                    </TdWithExtraPadding>
                  ))}
                </TrWithHoverEffect>
              ))}
            </tbody>
          )}
          {rows.tag === 'Complete' && (
            <tbody>
              {sortedRows?.length === 0 && (
                <tr>
                  <TdWithExtraPadding colSpan={headers.length}>
                    Records not found
                  </TdWithExtraPadding>
                </tr>
              )}
              {sortedRows && children(sortedRows)}
            </tbody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
