import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  PersonFilter,
  ScheduledTaskAssignment,
} from '@augusthealth/models/com/august/protos/scheduled_task_assignment'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  SETTINGS_LEVEL_OPTIONS,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import { PERSON_FILTER_OPTIONS } from '@shared/utils/scheduledTaskAssignment'
import { stateOptions } from '@shared/utils/state'
import {
  deleteOrgFacilitySetting,
  upsertSettings,
} from '@app/api/orgFacilitySettings'
import HUD from '@app/components/HUD'
import Warning from '@app/components/Warning'
import { CardLevel } from '../PdfTemplate/AssignTemplates/TaskCard/helpers'

function mapCardLevelToSettingslevel(cardLevel: CardLevel) {
  switch (cardLevel.tag) {
    case 'Global':
      return SettingsLevel.GLOBAL_LEVEL
    case 'State/Org':
      return SettingsLevel.ORG_STATE_LEVEL
    case 'State':
      return SettingsLevel.STATE_LEVEL
    case 'Organization':
      return SettingsLevel.ORG_LEVEL
    default:
      return SettingsLevel.FACILITY_LEVEL
  }
}

export default function ScheduleTaskModal({
  scheduleTaskSettings,
  taskDefinitionSettings,
  name,
  onClose,
  onScheduledTaskChange,
  cardLevel,
}: {
  scheduleTaskSettings?: OrgFacilitySettings
  taskDefinitionSettings: OrgFacilitySettings
  name: string
  onClose: () => void
  onScheduledTaskChange: () => Promise<void>
  cardLevel: CardLevel
}) {
  const settingsLevel = mapCardLevelToSettingslevel(cardLevel)
  const { setError } = useContext(GlobalContext)
  const [messageHUD, setMessageHUD] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const { orgId, facilityId, state } = cardLevel
  const { settings } = taskDefinitionSettings
  const { customType, dataType, taskType, displayName } =
    settings?.taskDefinition?.template || {}
  const { control, handleSubmit, register, formState } =
    useForm<ScheduledTaskAssignment>({
      defaultValues: scheduleTaskSettings?.settings?.scheduledTask || {
        dataType,
        customType,
        parameters: {
          tasksToCreate: [taskType],
        },
      },
    })
  const { errors } = formState
  const onSubmit = async (formData: ScheduledTaskAssignment) => {
    const scheduledTask: ScheduledTaskAssignment = { ...formData }
    // React-form-hook will default to empty string in input=hidden
    // Need to initialize customType manually
    if (scheduledTask.customType === '') {
      delete scheduledTask.customType
    }

    const key = scheduledTask.customType
      ? `${dataType}_${customType}`
      : dataType

    try {
      await upsertSettings({
        key,
        orgId,
        facilityId,
        state,
        settings: { scheduledTask },
        settingsType: SettingsType.SETTINGS_TYPE_SCHEDULED_TASK,
      })
      setMessageHUD(
        `Scheduled Task for ${displayName} has been ${
          scheduleTaskSettings ? 'updated' : 'added'
        }.`
      )
      await onScheduledTaskChange()
    } catch (e) {
      setError(e)
    }
  }
  const onDelete =
    scheduleTaskSettings &&
    (async () => {
      setIsDeleting(true)
      try {
        await deleteOrgFacilitySetting(scheduleTaskSettings)
        setMessageHUD(`Scheduled Task for ${displayName} has been deleted.`)
        await onScheduledTaskChange()
      } catch (e) {
        setError(e)
      } finally {
        setIsDeleting(false)
      }
    })
  const selectedLevelOpt = SETTINGS_LEVEL_OPTIONS.find(
    (opt) => opt.value === settingsLevel
  )

  if (messageHUD) {
    return <HUD onExpire={onClose}>{messageHUD}</HUD>
  }

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      title={`${
        scheduleTaskSettings ? 'Edit' : 'Create'
      } Schedule for ${displayName}`}
    >
      {scheduleTaskSettings === undefined && (
        <Warning className={'mb-[32px]'}>
          If you are trying to make schedules for the same task but with
          different person filters, this is not the right place. Please make a
          Linear and someone will help you out. Example of what I mean:
          <ul className={'mt-2 list-disc'}>
            <li>Resident assessment for assisted living every 360 days</li>
            <li>Resident assessment for memory care every 180 days</li>
          </ul>
        </Warning>
      )}
      <form data-testid="modal-form">
        <input type="hidden" {...register('dataType')} />
        <input type="hidden" {...register('customType')} />
        <input type="hidden" {...register('parameters.tasksToCreate')} />
        <LabelAboveInput htmlFor="settingsLevel">
          Settings Level
        </LabelAboveInput>
        <StyledSelect
          name="settingsLevel"
          isDisabled
          options={SETTINGS_LEVEL_OPTIONS}
          value={
            selectedLevelOpt
              ? {
                  label: `${selectedLevelOpt.label} - ${name}`,
                  value: selectedLevelOpt.value,
                }
              : undefined
          }
        />
        {['State', 'State/Org'].includes(cardLevel.tag) && (
          <div className="mt-[16px]">
            <LabelAboveInput htmlFor="state">State</LabelAboveInput>
            <StyledSelect
              name="state"
              options={stateOptions}
              value={stateOptions.find((opt) => opt.value === state)}
              isDisabled
            />
          </div>
        )}
        <div className="mt-[16px] flex">
          <div className="mr-[8px] grow">
            <LabelAboveInput
              htmlFor="parameters.daysToExpire"
              subLabel={requiredWhenError(!!errors.parameters?.daysToExpire)}
            >
              Days to Expire
            </LabelAboveInput>
            <BasicInput
              {...register('parameters.daysToExpire', {
                required: true,
                valueAsNumber: true,
              })}
              type="number"
            />
          </div>
          <div className="ml-[8px] grow">
            <LabelAboveInput
              htmlFor="parameters.daysInAdvance"
              subLabel={requiredWhenError(!!errors.parameters?.daysInAdvance)}
            >
              Days in Advance
            </LabelAboveInput>
            <BasicInput
              {...register('parameters.daysInAdvance', {
                required: true,
                valueAsNumber: true,
              })}
              type="number"
            />
          </div>
        </div>
        <div className="mt-[16px]">
          <LabelAboveInput
            htmlFor="parameters.filters"
            subLabel={requiredWhenError(!!errors.parameters?.filters)}
          >
            Filters
          </LabelAboveInput>
          <Controller
            name="parameters.filters"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledMultiSelect
                  name="parameters.filters"
                  maxMenuHeight={200}
                  onChange={(opts: OptionTypeBase<PersonFilter>[]) => {
                    if (!opts || opts.length === 0) {
                      onChange(null)
                    } else {
                      onChange(opts.map((o) => o.value))
                    }
                  }}
                  value={
                    value
                      ? PERSON_FILTER_OPTIONS.filter(
                          (opt: OptionTypeBase<PersonFilter>) =>
                            value.includes(opt.value)
                        )
                      : undefined
                  }
                  options={PERSON_FILTER_OPTIONS}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px] flex justify-between">
          <div>
            <AnimatedPopupFormFooter
              yesBtn={{
                props: {
                  type: 'button',
                  onClick: handleSubmit(onSubmit),
                },
              }}
              noBtn={{ action: onClose }}
              formState={formState}
            />
          </div>
          <div className="ml-[16px]">
            {scheduleTaskSettings && (
              <Button
                buttonStyle="danger-fill"
                onClick={onDelete}
                isLoading={isDeleting}
                disabled={isDeleting}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      </form>
    </AnimatedPopup>
  )
}
