import { LoaderSvg } from '@shared/components/LoadingPopup'
import LogoHeader from '@shared/components/LogoHeader'
import { tw } from '@shared/utils/tailwind'
import Footer from './Footer'

export default function Loader({ title }: { title: string }) {
  return (
    <div className={tw`flex flex-col items-center`}>
      <LogoHeader title={title} />

      <div className={tw`mt-[40px]`}>
        <LoaderSvg />
      </div>

      <Footer />
    </div>
  )
}
