import { Contact } from '@augusthealth/models/com/august/protos/contact'
import Badge from '@shared/components/Badge'
import { Button } from '@shared/components/baseMui/Button'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw, twx } from '@shared/utils/tailwind'
import ProfileImage from '@app/components/Table/ProfileImage'
import ContactCard from '@app/pages/Contacts/ContactCard'
import { BadgeConfig, configureBadges } from '../../../helpers'

interface PayerDetailsModalProps {
  person: Person
  payers: Contact[]
  open: boolean
  onClose: () => void
}
export default function PayerDetailsModal({
  person,
  payers,
  open,
  onClose,
}: PayerDetailsModalProps) {
  return (
    <Modal open={open} onClose={onClose} id="payer-detail-modal">
      <ModalTitle className={tw`mb-6 border-none pb-0 text-secondary-02`}>
        Payer Details
      </ModalTitle>
      <Card className={tw`mb-4 flex flex-row p-4`}>
        <ProfileImage person={person} className={tw`mr-4 h-10 w-10`} />
        <div>
          <div
            data-testid="person-name"
            className={tw`mb-1 text-sm font-semibold text-gray-04`}
          >
            {getFirstAndLastName(person.name)}
          </div>
          <div className={tw`text-xs font-medium text-gray-07`}>
            Monthly recurring:
          </div>
        </div>
      </Card>
      <div
        className={twx('grid grid-cols-1 gap-4', {
          'md:grid-cols-2': payers.length >= 2,
          'xl:grid-cols-3': payers.length >= 3,
        })}
      >
        {payers.map((p, i) => (
          <ContactCard
            key={`${p.id}-${i}`}
            contact={p}
            gridIndex={i}
            linkable={false}
          >
            <div className="mt-6 border-t border-gray-11 pt-6">
              <BadgeBuilder
                // TODO: remove hardcoded values
                configs={configureBadges(p, true, undefined, {
                  type: 'number',
                  value: 300000,
                })}
              />
            </div>
          </ContactCard>
        ))}
      </div>
    </Modal>
  )
}

function BadgeBuilder({ configs }: { configs: BadgeConfig[][] }) {
  return (
    <div className="grid grid-cols-1 gap-2">
      {configs.map((rowConfig, j) => (
        <div key={j}>
          {rowConfig.map((c, i) => (
            <Badge
              key={i}
              color={c.active ? 'gray-06' : 'yellow'}
              shape="square"
              badgeSize="large"
              className={tw`mr-1 px-2 font-medium normal-case`}
              icon={c.iconType}
            >
              {c.text}
            </Badge>
          ))}
          <Button
            buttonStyle="tertiary-fill"
            buttonSize="small"
            className={tw`border border-gray-10 px-2`}
            onClick={() => console.log('actions coming soon!')}
          >
            <Icon name="pen" />
          </Button>
        </div>
      ))}
    </div>
  )
}
