import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  PasswordPolicy,
  TwoFactorLevel,
} from '@augusthealth/models/com/august/protos/settings/security_policy'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Organization } from '@shared/types/organization'
import { upsertSettings } from '@app/api/orgFacilitySettings'
import useOrganizations from '@app/hooks/useOrganizations'
import styles from '../AssessmentConfiguration/styles.module.css'
import { passwordPolicyOptions } from './constants'
import { fetchOrgFacilitySecuritySettings } from './helpers'

export default function SecuritySettings() {
  const { setError } = useContext(GlobalContext)
  const { organizations } = useOrganizations([])
  const [isLoading, setIsLoading] = useState(false)
  const [orgFacilitySettings, setOrgFacilitySettings] = useState<
    OrgFacilitySettings | undefined
  >(undefined)

  const { reset, watch, formState, setValue, control, handleSubmit } =
    useForm<OrgFacilitySettings>({
      defaultValues: {
        orgId: undefined,
        settings: {
          securityPolicy: {
            passwordPolicy: PasswordPolicy.PASSWORD_POLICY_UNSPECIFIED,
            twoFactorLevel: TwoFactorLevel.TWO_FACTOR_LEVEL_UNSPECIFIED,
          },
        },
      },
      mode: 'onChange',
    })

  const selectedOrg = watch('orgId') as OptionTypeBase<Organization> | undefined
  const selectedPasswordPolicy = watch(
    'settings.securityPolicy.passwordPolicy'
  ) as OptionTypeBase<PasswordPolicy> | undefined
  /*
   * TODO(dimitry): see comment in :179
  const twoFactorySwitchLabel =
    watch('settings.securityPolicy.twoFactorLevel') ===
    TwoFactorLevel.TWO_FACTOR_LEVEL_ALL_USERS
      ? 'On'
      : 'Off'
   */

  async function propagateOrgSecuritySettings(orgId: string | undefined) {
    setIsLoading(true)
    try {
      const orgFacilitySettingsResp =
        await fetchOrgFacilitySecuritySettings(orgId)

      const { createdBy, updatedBy, ...rest } = orgFacilitySettingsResp!

      setOrgFacilitySettings(rest)
      reset({
        orgId,
        settings: rest.settings,
      })
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function onSubmit(formData: OrgFacilitySettings) {
    setIsLoading(true)
    try {
      await upsertSettings({
        ...orgFacilitySettings,
        settings: {
          ...orgFacilitySettings?.settings,
          securityPolicy: {
            ...orgFacilitySettings?.settings?.securityPolicy,
            passwordPolicy: formData?.settings?.securityPolicy?.passwordPolicy,
            twoFactorLevel: formData?.settings?.securityPolicy?.twoFactorLevel,
          },
        },
      })
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (organizations.tag === 'Loading' || isLoading) {
    return (
      <div className="content-holder">
        <h4 className="page-title mt-[24px]">Security Settings</h4>
        <div className={styles.container}>
          <div className={styles.left}>
            <Skeleton height={30} count={3} />
          </div>
          <div className={styles.right}>
            <Skeleton height={30} count={3} />
          </div>
        </div>
      </div>
    )
  }

  const orgOptions = organizations.value.map((o: Organization) => {
    return { label: o.name!, value: o }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content-holder">
        <h4 className="page-title mt-[24px]">Security Settings</h4>
        <div className={styles.container}>
          <div className={styles.left}>
            <Card className={styles.card}>
              <LabelAboveInput htmlFor="organizations">
                I want to set security settings for...
              </LabelAboveInput>
              <Controller
                control={control}
                name="orgId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledSelect
                      options={orgOptions}
                      onChange={(opt: OptionTypeBase<Organization>) => {
                        void propagateOrgSecuritySettings(opt.value.id)
                        onChange(opt.value.id)
                      }}
                      value={orgOptions.find((opt) => opt.value.id === value)}
                    />
                  )
                }}
              />
            </Card>
          </div>
          <div className={styles.right}>
            {selectedOrg && (
              <Card className={styles.card}>
                <LabelAboveInput htmlFor="settings.securityPolicy.passwordPolicy">
                  Current security settings for {selectedOrg?.label}
                </LabelAboveInput>
                <Controller
                  control={control}
                  name="settings.securityPolicy.passwordPolicy"
                  render={({ field: { onChange, value } }) => {
                    if (value === PasswordPolicy.PASSWORD_POLICY_UNSPECIFIED)
                      return <></>
                    const defaultValue = passwordPolicyOptions.find(
                      (o) => o.value === value
                    )
                    return (
                      <StyledSelect
                        inputId="passwordPolicy"
                        defaultValue={defaultValue}
                        className="mb-[16px]"
                        placeholder="Select password policy..."
                        options={passwordPolicyOptions}
                        onChange={(e: OptionTypeBase<PasswordPolicy>) => {
                          onChange(e.value)
                          setValue(
                            'settings.securityPolicy.passwordPolicy',
                            e.value
                          )
                        }}
                        value={selectedPasswordPolicy?.value}
                      />
                    )
                  }}
                />
                {/* TODO(dimitry): uncomment when 2FA is ready

                <div className="print-hide mb-[16px] mt-[16px] flex items-center">
                  <Controller
                    control={control}
                    name="settings.securityPolicy.twoFactorLevel"
                    defaultValue={TwoFactorLevel.TWO_FACTOR_LEVEL_UNSPECIFIED}
                    render={({ field: { onChange, value } }) => {
                      const isEnabled =
                        value === TwoFactorLevel.TWO_FACTOR_LEVEL_ALL_USERS

                      return (
                        <section>
                          <LabelAboveInput htmlFor="2-Factor Authentication">
                            2-Factor Authentication
                          </LabelAboveInput>
                          <Switch
                            checked={isEnabled}
                            id={'twoFactorLevel'}
                            label={twoFactorySwitchLabel}
                            onChange={() => {
                              const newValue = isEnabled
                                ? TwoFactorLevel.TWO_FACTOR_LEVEL_DISABLED
                                : TwoFactorLevel.TWO_FACTOR_LEVEL_ALL_USERS
                              onChange(newValue)
                              setValue(
                                'settings.securityPolicy.twoFactorLevel',
                                newValue
                              )
                            }}
                            switchSize={'small'}
                          />
                        </section>
                      )
                    }}
                  />
                </div>
                  */}

                <AsyncIconButton
                  type="submit"
                  buttonStyle="primary-fill"
                  disabled={isLoading || !formState.isDirty}
                  isLoading={isLoading}
                >
                  Save Security Settings
                </AsyncIconButton>
              </Card>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
