import { Controller, UseFormReturn } from 'react-hook-form'
import Select, {
  ActionMeta,
  components,
  MultiValueProps,
  OptionProps,
  StylesConfig,
} from 'react-select'
import Icon from '@shared/components/Icon'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import {
  getComponents,
  modifiedStyles,
  multiSelectStyles,
  OptionTypeBase,
  StyledSelectProps,
} from '@shared/components/Selects/StyledSelect'
import { ResidentListEntry } from '@shared/types/billing'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { formatRoomNumber, getProfileSvgPath } from '@shared/utils/person'
import { tw, twx } from '@shared/utils/tailwind'
import { BillingChargeFormData } from '../helpers'

export default function ResidentsDropdown({
  residents,
  useFormReturn,
  disabled,
}: {
  residents: ResidentListEntry[]
  useFormReturn: UseFormReturn<BillingChargeFormData>
  disabled?: boolean
}) {
  const { control, formState } = useFormReturn
  const { errors } = formState

  const options: ResidentOption[] = residents.map((r) => {
    const profileUrl = r.profilePictureUrl
      ? r.profilePictureUrl
      : getProfileSvgPath(r)

    const roomString = formatRoomNumber(
      r.roomDetails?.roomNumber,
      r.roomDetails?.bedNumber
    )

    return {
      value: r.personId,
      label: getFirstAndLastName(r.name),
      profileUrl,
      roomString,
    }
  })

  return (
    <div>
      <LabelAboveInput
        uppercase={false}
        subLabel={requiredLabel(Boolean(errors.personIds))}
        htmlFor="personIds"
      >
        Resident(s)
      </LabelAboveInput>
      <Controller
        control={control}
        name="personIds"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          const matchingOpt = options.filter((opt) => value.includes(opt.value))

          return (
            <StyledMultiResidentSelect
              max={5}
              placeholder="Enter resident name or select..."
              onChange={(opts: OptionTypeBase<string>[]) => {
                if (!opts || opts.length === 0) {
                  onChange([])
                } else {
                  onChange(opts.map((o) => o.value))
                }
              }}
              value={value ? matchingOpt : undefined}
              options={options}
              inputId="personIds"
              isDisabled={disabled}
            />
          )
        }}
      />
    </div>
  )
}

interface ResidentOption extends OptionTypeBase {
  profileUrl: string
  roomString?: string
}

interface StyledMultiResidentSelectProps
  extends Omit<StyledSelectProps, 'value'> {
  max: number
  value: ResidentOption[] | undefined
}

const maxToShowStyles: StylesConfig = {
  valueContainer: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary-light)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      color: 'white',
      backgroundColor: 'var(--primary-light)',
      filter: 'brightness(.9)',
    },
    color: 'white',
    backgroundColor: 'var(--primary-light)',
    filter: state.isFocused ? 'brightness(.9)' : 'brightness(1)',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: 'hidden',
  }),
}

function StyledMultiResidentSelect({
  isDisabled = false,
  size = 'medium',
  components,
  styles,
  options = [],
  onChange,
  value,
  ...rest
}: StyledMultiResidentSelectProps) {
  const selectAllOption: ResidentOption = {
    value: 'SELECT_ALL',
    label: 'Select all',
    profileUrl: '/svg/icon--people.svg',
  }
  const optsWithSelectAll = [selectAllOption, ...options]
  const isSelectAllSelected = value?.length === options.length
  const dropdownValues = isSelectAllSelected
    ? [selectAllOption, ...(value as OptionTypeBase[])]
    : value

  const onChangeWithSelectAll = (
    newValue: OptionTypeBase[],
    actionMeta: ActionMeta<OptionTypeBase>
  ) => {
    const { action, option, removedValue } = actionMeta

    if (action === 'select-option' && option?.value === selectAllOption.value) {
      onChange && onChange(options, actionMeta)
    } else if (
      (action === 'deselect-option' &&
        option?.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value) ||
      action === 'clear'
    ) {
      onChange && onChange([], actionMeta)
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected) {
      onChange &&
        onChange(
          options.filter((o: OptionTypeBase) => o.value !== option?.value),
          actionMeta
        )
    } else {
      onChange && onChange(newValue, actionMeta)
    }
  }

  return (
    <Select
      isMulti={true}
      styles={{
        ...modifiedStyles(size),
        ...multiSelectStyles,
        ...maxToShowStyles,
        ...styles,
      }}
      components={getComponents({
        MultiValue: MultiValueWithMax,
        Option: ResidentOptionWithCheckbox,
        ...components,
      })}
      isDisabled={isDisabled}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      options={optsWithSelectAll}
      onChange={onChangeWithSelectAll}
      value={dropdownValues}
      {...rest}
    />
  )
}

const MultiValueWithMax = (props: MultiValueProps) => {
  const { index, getValue, selectProps } = props
  const maxToShow = selectProps['max'] as number
  const selectAllPresent = getValue().some(
    (v: OptionTypeBase) => v.value === 'SELECT_ALL'
  )
  const updatedMax = selectAllPresent ? maxToShow + 1 : maxToShow
  const overflow = getValue()
    .slice(updatedMax)
    .map((x: OptionTypeBase) => x.label)

  return selectAllPresent && index === 0 ? null : index < updatedMax ? (
    <components.MultiValue {...props} />
  ) : index === updatedMax ? (
    <MoreSelectedBadge items={overflow} />
  ) : null
}

function MoreSelectedBadge({ items }: { items: string[] }) {
  return (
    <div
      className={tw`m-[2px] text-primary underline hover:cursor-pointer hover:brightness-75`}
    >{`and ${items.length} more`}</div>
  )
}

function ResidentOptionWithCheckbox(props: OptionProps) {
  const { innerProps, innerRef, data, isSelected, label, isFocused } = props

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={twx('flex h-10 items-center px-3 py-2', {
        'bg-[#f0edeb]': isFocused,
      })}
    >
      <BasicCheckboxViewOnly checked={isSelected} />
      <div className={tw`w-100 flex justify-between text-[14px] font-semibold`}>
        <div className={tw`flex items-center`}>
          <img
            src={(data as ResidentOption).profileUrl}
            className={tw`mr-[16px] h-[28px] w-[28px] rounded`}
          />
          <div>{label}</div>
        </div>
        {(data as ResidentOption).roomString && (
          <div className={tw`flex w-24 items-center gap-1 text-gray-07`}>
            <Icon name="bed" />
            <div
              className={tw`overflow-hidden text-ellipsis whitespace-nowrap`}
            >
              {(data as ResidentOption).roomString}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function BasicCheckboxViewOnly({ checked }: { checked: boolean }) {
  const divClassName = twx(
    'h-[16px] w-[16px] relative rounded-[3px] border border-duotone-level3 mr-3',
    'bg-white transition-all duration-75 ease-linear hover:cursor-pointer',
    { 'bg-august-primary border-august-primary': checked }
  )
  const iconClassName = twx('text-white absolute -left-[2px] top-[7px] fa-sm')

  return (
    <div className={divClassName}>
      {checked && <Icon name="check" className={iconClassName} />}
    </div>
  )
}
