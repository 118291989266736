import { tw } from '@shared/utils/tailwind'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import RadioGroup from '@app/components/formElements/RadioGroup'
import ButtonOption from '@app/components/formElements/RadioGroup/ButtonOption'

interface RadioButtonOption {
  label: string
  value: string
}

interface Question {
  label: string
  value: string
}

interface Props extends Omit<ElementHolderProps, 'children'> {
  name: string
  value?: { [key: string]: string }
  onUpdate: (data: string | null, name: string) => void
  questions: Question[]
  options: RadioButtonOption[]
}

export function SharedRadioButtonGroup(props: Props) {
  const radioButtons = props.questions.map((box) => {
    const name = `${props.name}_${box.value}`
    return (
      <tr key={name}>
        <td className={tw`pr-[16px]`}>{box.label}</td>
        <td>
          <RadioGroup
            options={props.options}
            name={name}
            disabled={false}
            value={props.value ? props.value[name] : undefined}
            onUpdate={props.onUpdate}
            inGenerator={true}
            OptionComponent={ButtonOption}
            isOptional={true}
          />
        </td>
      </tr>
    )
  })

  return (
    <ElementHolder {...props}>
      <div className={tw`pt-[8px]`}>
        <table>{radioButtons}</table>
      </div>
    </ElementHolder>
  )
}
