import { match, P } from 'ts-pattern'
import {
  RecurringCharge,
  ResidentListEntry,
  StatementStatus,
} from '@shared/types/billing'
import { Contact, Contact_BillingStatementMethod } from '@shared/types/contact'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { firstLastName, primaryEmail } from '@shared/utils/contact'
import { toOrdinal } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { formatRoomNumber } from '@shared/utils/person'
import { Order, sortNumber } from '@shared/utils/sorting'

export enum TransactionsColumnName {
  SERVICE_DATE = 'Service date',
  DESCRIPTION = 'Description',
  TRANSACTION_TYPE = 'Type',
  AMOUNT = 'Amount',
  BALANCE = 'Balance',
}

export enum ResidentsColumnName {
  RESIDENT = 'Resident',
  UPDATES = 'Updates',
  CURRENT_BALANCE = 'Current Balance',
  STATEMENT_BALANCE = 'Statement Balance',
  STATEMENT_STATUS = 'Statement Status',
  ROOM = 'Room',
}

export enum InvoicesColumnName {
  NUMBER = 'Number',
  BALANCE_DUE = 'Balance Due',
  INVOICE = 'Invoice',
  DATE_DUE = 'Date Due',
  DESCRIPTION = 'Description',
  SERVICE_DATES = 'Service Dates',
  NAME = 'Name',
}

export enum ChargesColumnName {
  DESCRIPTION = 'Description',
  AMOUNT = 'Amount',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
}

export function getSearchedResidents({
  rows,
  searchTerm,
}: {
  rows: ResidentListEntry[]
  searchTerm: string
}) {
  return rows.filter((resident) => {
    const { name } = resident
    const lowerName = getFirstAndLastName(name).toLowerCase()
    const searchTermLower = searchTerm.toLowerCase()
    return lowerName.includes(searchTermLower)
  })
}

export function getSortedResidents({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: ResidentListEntry[]
  selectedColumn: ResidentsColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === ResidentsColumnName.RESIDENT) {
    return rows.sort((a, b) => {
      const aName = getFirstAndLastName(a.name)
      const bName = getFirstAndLastName(b.name)
      if (sortingOrder === Order.ASC) {
        return aName.localeCompare(bName)
      }
      return bName.localeCompare(aName)
    })
  }

  if (selectedColumn === ResidentsColumnName.CURRENT_BALANCE) {
    return rows.sort((a, b) => {
      const aBalance = a.totalBalanceCents
      const bBalance = b.totalBalanceCents
      if (sortingOrder === Order.ASC) {
        return aBalance - bBalance
      }
      return bBalance - aBalance
    })
  }

  if (selectedColumn === ResidentsColumnName.STATEMENT_BALANCE) {
    return rows.sort((a, b) => {
      const aBalance = a.statementBalanceCents
      const bBalance = b.statementBalanceCents
      if (sortingOrder === Order.ASC) {
        return aBalance - bBalance
      }
      return bBalance - aBalance
    })
  }

  if (selectedColumn === ResidentsColumnName.STATEMENT_STATUS) {
    return rows.sort((a, b) => {
      const aStatusOrder = a.lastInvoice?.data.status
        ? statusSort[a.lastInvoice?.data.status]
        : 0
      const bStatusOrder = b.lastInvoice?.data.status
        ? statusSort[b.lastInvoice?.data.status]
        : 0
      if (sortingOrder === Order.ASC) {
        return aStatusOrder - bStatusOrder
      }
      return bStatusOrder - aStatusOrder
    })
  }

  if (selectedColumn === ResidentsColumnName.UPDATES) {
    return rows.sort((a, b) => {
      return sortNumber({
        numA: a.pendingBillingEvents,
        numB: b.pendingBillingEvents,
        sortingOrder,
      })
    })
  }

  if (selectedColumn === ResidentsColumnName.ROOM) {
    return rows.sort((a, b) => {
      const aRoom = a.roomDetails
        ? formatRoomNumber(a.roomDetails.roomNumber, a.roomDetails.bedNumber)
        : ''
      const bRoom = b.roomDetails
        ? formatRoomNumber(b.roomDetails.roomNumber, b.roomDetails.bedNumber)
        : ''
      if (sortingOrder === Order.ASC) {
        return aRoom.localeCompare(bRoom)
      }
      return bRoom.localeCompare(aRoom)
    })
  }

  return rows
}

const statusSort: Record<StatementStatus, number> = {
  [StatementStatus.ERROR]: 1,
  [StatementStatus.NEEDS_WORK]: 2,
  [StatementStatus.PENDING]: 3,
  [StatementStatus.APPROVED]: 4,
  [StatementStatus.PAID]: 5,
  [StatementStatus.DUE]: 6,
}

export function getFrequencyLabelFromCharge(charge: RecurringCharge) {
  const { frequency } = charge.item.data

  return convertEnumValueToLabel(frequency)
}

/**
 * 1 contact => Name 1
 * 2 contacts => Name 1 & Name 2
 * 3 contacts => Name 1, Name 2 & Name 3
 */
export function payerText(payers: Contact[]) {
  const lastPayer = payers.at(-1)
  const lastPayerName = lastPayer ? firstLastName(lastPayer) : ''

  return (
    payers
      .map((p) => firstLastName(p))
      .join(', ')
      // replace the final comma with &
      .replace(new RegExp(`, ${lastPayerName}$`), ` & ${lastPayerName}`)
  )
}

// PayerDetailsModal helpers

export enum IconType {
  STATEMENT_DELIVERY = 'house-heart',
  PAYMENT_DETAILS = 'money-check-dollar-pen',
  AUTO_PAY_AMOUNT = 'usd-square',
  AUTO_PAY_DATE = 'calendar-day',
}

export enum StatementDeliveryText {
  IN_ROOM = 'Statement: In Room',
  EMAIL = 'Statement: Email',
  MAIL = 'Statement: Mail',
}

export enum PaymentDetailsText {
  ACH = 'ACH',
  PAYMENT_DETAILS_UNSET = 'No Payment Details',
}

export enum AutopayText {
  AUTO_PAY = 'Auto Pay',
  AUTO_PAY_UNSET = 'Auto Pay: Off',
}

type Badge<I, T, A> = {
  iconType: I
  text: T
  active: A
}

type StatementDeliveryBadge = Badge<
  IconType.STATEMENT_DELIVERY,
  StatementDeliveryText,
  true
>
type PaymentDetailsBadge = Badge<
  IconType.PAYMENT_DETAILS,
  PaymentDetailsText,
  boolean
>
type AutopayAmountBadge = Badge<IconType.AUTO_PAY_AMOUNT, string, boolean>
type AutopayDateBadge = Badge<IconType.AUTO_PAY_DATE, string, true>

export type BadgeConfig =
  | PaymentDetailsBadge
  | StatementDeliveryBadge
  | AutopayAmountBadge
  | AutopayDateBadge

type PaymentCap = {
  type: 'number' | 'percentage'
  value: number
}

export const defaultDeliveryBadge: StatementDeliveryBadge = {
  iconType: IconType.STATEMENT_DELIVERY,
  text: StatementDeliveryText.MAIL,
  active: true,
}

export const defaultPaymentBadge: PaymentDetailsBadge = {
  iconType: IconType.PAYMENT_DETAILS,
  text: PaymentDetailsText.PAYMENT_DETAILS_UNSET,
  active: false,
}

export const defaultAutoPay: AutopayAmountBadge = {
  iconType: IconType.AUTO_PAY_AMOUNT,
  text: AutopayText.AUTO_PAY_UNSET,
  active: false,
}

// TODO: update props once PayerSettingsData is available from backend
export function configureBadges(
  payer: Contact,
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  autopay: boolean,
  paymentDayOfMonth?: number,
  paymentCap?: PaymentCap
) {
  const badgeConfigs: BadgeConfig[][] = []
  // statement delivery
  const email = primaryEmail(payer)
  const deliveryBadge = match([email, payer.billingStatementMethod])
    .returnType<StatementDeliveryBadge>()
    .with(
      [
        P.not(undefined),
        P.union(
          undefined,
          Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_EMAIL,
          Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_UNSPECIFIED,
          Contact_BillingStatementMethod.UNRECOGNIZED
        ),
      ],
      () => {
        return { ...defaultDeliveryBadge, text: StatementDeliveryText.EMAIL }
      }
    )
    .with(
      [
        P.select(),
        Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_PRINT,
      ],
      () => {
        return { ...defaultDeliveryBadge, text: StatementDeliveryText.IN_ROOM }
      }
    )
    .otherwise(() => defaultDeliveryBadge)

  badgeConfigs.push([deliveryBadge])

  // payment details
  const ach = payer.externalFields?.rainforestPaymentMethod?.ach
  const paymentDetailsBadge = match(ach)
    .returnType<PaymentDetailsBadge>()
    .with(P.not(undefined), () => {
      return {
        ...defaultPaymentBadge,
        text: PaymentDetailsText.ACH,
        active: true,
      }
    })
    .otherwise(() => defaultPaymentBadge)
  badgeConfigs.push([paymentDetailsBadge])

  // autopay
  if (paymentDetailsBadge.active === true) {
    const autoPayAmountBadge = match(autopay)
      .returnType<AutopayAmountBadge>()
      .with(true, () => {
        const paymentStr = getPaymentString(paymentCap)
        return {
          ...defaultAutoPay,
          active: true,
          text: AutopayText.AUTO_PAY + paymentStr,
        }
      })
      .otherwise(() => defaultAutoPay)
    const autoPayBadges: (AutopayAmountBadge | AutopayDateBadge)[] = [
      autoPayAmountBadge,
    ]
    if (autopay && paymentDayOfMonth) {
      const autopayDateBadge: AutopayDateBadge = {
        iconType: IconType.AUTO_PAY_DATE,
        active: true,
        text: toOrdinal(paymentDayOfMonth),
      }
      autoPayBadges.push(autopayDateBadge)
    }
    badgeConfigs.push(autoPayBadges)
  }

  return badgeConfigs
}

const getPaymentString = (paymentCap: PaymentCap | undefined) => {
  if (!paymentCap) {
    return ''
  }

  if (paymentCap.type === 'number') {
    return ': ' + formatCurrencyForBilling(paymentCap.value)
  } else {
    return ': ' + paymentCap.value + '%'
  }
}
