import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useEffect, useState } from 'react'
import { getFacilityAssessmentConfiguration } from '@shared/api/facility_settings'
import {
  GroupedOptionTypeBase,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import {
  AssessmentCategory,
  AssessmentConfig,
  AssessmentReason,
} from '@shared/types/assessment_configuration'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  SettingsConfig,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import {
  buildAssessmentConfig,
  buildOrgFacilitySettingsFromAssessmentConfig,
  facilityMatchesOrg,
  getAssessmentCategoryOptions,
} from './helpers'

/**
 * This hook fetches assessment configuration and gives access to several pieces of state related to the configuration:
 * - settingsConfig: Configuration related to the level selects in the assessment tools
 * - config: The org facility settings from the network
 * - assessmentConfig: A data structure built from the org facility settings to make it easier to work with
 * - selectedOrganization: The currently selected org in the form
 * - selectedFacility: The currently selected facility in the form
 * @param onTrigger A function that fires each time this hook fires. Used for resetting the assessment tools form.
 * @param deps
 */
export default function useAssessmentConfiguration(onTrigger: () => void) {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [settingsConfig, setSettingsConfig] = useState<SettingsConfig>({})
  const [config, setConfig] = useState<OrgFacilitySettings>()
  const [assessmentConfig, setAssessmentConfig] = useState<AssessmentConfig>({})
  const [categoryDropdownOptions, setCategoryDropdownOptions] =
    useState<GroupedOptionTypeBase<AssessmentCategory>[]>()

  const orgId = selectedOrganization?.value.id
  const facilityId = selectedFacility?.value.id

  const reloadConfig = async () => {
    onTrigger()
    setCategoryDropdownOptions(undefined)
    setAssessmentConfig({})
    setConfig(undefined)

    if (!selectedOrganization || !selectedFacility) {
      return Promise.resolve()
    }

    void getFacilityAssessmentConfiguration(
      selectedOrganization.value.id,
      selectedFacility.value.id
    ).then((data) => {
      setSettingsConfig({
        settingsLevel: SettingsLevel.FACILITY_LEVEL,
        orgId: selectedOrganization.value.id,
        facilityId: selectedFacility.value.id,
      })
      setConfig(data)

      if (data?.settings?.appraisalSettings?.categories) {
        setAssessmentConfig(
          buildAssessmentConfig(data.settings.appraisalSettings.categories)
        )
        setCategoryDropdownOptions(
          getAssessmentCategoryOptions(
            data.settings.appraisalSettings.categories
          )
        )
      }
    })
  }

  const reloadCategoryDropdownOptions = (
    updatedConfig: AssessmentConfig,
    assessmentReasons: AssessmentReason[]
  ) => {
    const newCategories = buildOrgFacilitySettingsFromAssessmentConfig(
      updatedConfig,
      {},
      assessmentReasons,
      {
        keepEmptyCategories: true,
        keepDeletedCategories: true,
      }
    ).settings!.appraisalSettings!.categories!

    const options = getAssessmentCategoryOptions(
      newCategories,
      config!.settings!.appraisalSettings!.categories!
    )
    setCategoryDropdownOptions(options)
  }

  useEffect(() => {
    onTrigger()
    setCategoryDropdownOptions(undefined)
    setAssessmentConfig({})
    setConfig(undefined)

    if (
      !selectedOrganization ||
      !facilityMatchesOrg({
        facility: selectedFacility,
        organization: selectedOrganization,
      })
    ) {
      setSelectedFacility(null)
    } else {
      void reloadConfig()
    }
  }, [orgId, facilityId])

  return {
    reloadConfig,
    assessmentConfig,
    setAssessmentConfig,
    settingsConfig,
    setSettingsConfig,
    categoryDropdownOptions,
    setCategoryDropdownOptions,
    config,
    setConfig,
    selectedFacility,
    setSelectedFacility,
    selectedOrganization,
    setSelectedOrganization,
    reloadCategoryDropdownOptions,
  }
}
