import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import {
  SignatureInfo,
  Task,
} from '@augusthealth/models/com/august/protos/task'
import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Lightbox, {
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import { snapshotFileUrl, taskPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getOrElse } from '@shared/utils/loading'
import { showSignInPersonLink } from '@shared/utils/signature'
import {
  getIsComplete,
  isAwaitingReview,
  isPendingSignatures,
  taskTitle,
} from '@shared/utils/task'
import { getFormPdfUrl } from '@app/api/form'
import { unshareAndAssignTaskToAdmin } from '@app/api/tasks'
import { hasUnassignedAdminSigner } from '@app/components/SignatureFlow/helpers'
import { ConfirmMakeChangesOverlays } from '@app/components/SignatureFlow/SignatureModalOverlays'
import useBlobData from '@app/hooks/useBlobData'
import useTask from '@app/hooks/useTask'
import { useTasks } from '@app/hooks/useTasks'
import styles from '@app/pages/Documents/Viewer/styles.module.css'
import { PDFBody, PrintDownloadButtons, StaticReviewCopy } from '../Components'
import ContinueButton from './ContinueButton'
import { Signatures } from './Signatures'

/**
 * Rendered when the task has an outstanding signature packet
 * @param initialTask
 * @param person
 * @param onClose
 * @constructor
 */
export default function InProgressSignatureFlow({
  task: initialTask,
  person,
  onClose,
}: {
  task: Task
  person: Person
  onClose: () => void
}) {
  const { user } = useUserContext()
  const { tasks, refreshTasks } = useTasks(true)
  const { task: networkTask } = useTask({
    person,
    taskId: initialTask.id!,
  })

  const localTask = tasks.find((t) => t.id === initialTask.id) as Task
  const task = getOrElse(networkTask, localTask)
  const blobData = useBlobData(fileUrlForTask(task, person))
  const history = useHistory()

  const [confirmMakeChanges, setConfirmMakeChanges] = useState(false)
  const [makingChanges, setMakingChanges] = useState(false)
  const [disableChangeSigner, setDisableChangeSigner] = useState(false)
  const [currentSigners, setCurrentSigners] = useState<SignatureInfo[]>(
    task.signatures ?? []
  )

  // A ref for the PDF div. We use this to manually control the scroll offset.
  const divRef = useRef<HTMLDivElement>(null)

  const allowToShowSignInPerson = showSignInPersonLink({
    person,
    task,
    user,
  })

  return (
    <Lightbox
      mainContent={
        <div ref={divRef} style={{ overflow: 'auto' }}>
          <PDFBody blobData={blobData} />
        </div>
      }
      sidebarContent={
        <>
          <LightboxSidebarContent title={taskTitle(task)}>
            <StaticReviewCopy />
            {(isPendingSignatures(task) || getIsComplete(task)) && (
              <Signatures
                user={user}
                currentSigners={currentSigners}
                setCurrentSigners={setCurrentSigners}
                task={task}
                allowToShowSignInPerson={allowToShowSignInPerson}
                disableChangeSigner={
                  disableChangeSigner || blobData.tag !== 'Complete'
                }
                onSignInPersonComplete={onClose}
              />
            )}
            <PrintDownloadButtons blobData={blobData} />
          </LightboxSidebarContent>
          <LightboxSidebarFooter className="flex-col">
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
            >
              {!getIsComplete(task) && (
                <AsyncIconButton
                  disabled={makingChanges}
                  isLoading={makingChanges}
                  buttonStyle={'secondary-outline'}
                  initialIcon={'fa-pen'}
                  onClick={async () => {
                    if (divRef.current) {
                      divRef.current.scrollTop = 0
                    }
                    setConfirmMakeChanges(true)
                  }}
                >
                  Make Changes
                </AsyncIconButton>
              )}
            </PersonPermissionGate>
            <ContinueButton
              setButtonIsProcessing={setDisableChangeSigner}
              disabled={
                networkTask.tag === 'Loading' ||
                blobData.tag === 'Loading' ||
                hasUnassignedAdminSigner(currentSigners)
              }
              task={task}
              person={person}
              user={user}
              currentSigners={currentSigners}
              refreshTasks={refreshTasks}
              onClose={onClose}
            />
          </LightboxSidebarFooter>
        </>
      }
      showSidebarOnPrint
      onClose={onClose}
    >
      {confirmMakeChanges && <div className={styles.headerCover} />}
      {confirmMakeChanges && (
        <ConfirmMakeChangesOverlays
          onCancel={() => setConfirmMakeChanges(false)}
          onConfirm={async () => {
            setMakingChanges(true)
            await unshareAndAssignTaskToAdmin(task, person)
            await refreshTasks()
            history.push(
              taskPathForPerson(person as Required<Person>, task.id || '')
            )
          }}
        />
      )}
    </Lightbox>
  )
}

export function fileUrlForTask(task: Task, person: Person) {
  if (isAwaitingReview(task)) {
    return getFormPdfUrl({
      orgId: person.orgId,
      facilityId: person.facilityId,
      personId: person.id,
      dataType: task.taskTemplateInfo!.dataType!,
      customType: task.taskTemplateInfo!.customType,
    })
  }

  const params = task.taskTemplateInfo!.customType
    ? { customType: task.taskTemplateInfo!.customType }
    : {}
  return snapshotFileUrl({
    personId: person.id,
    orgId: person.orgId,
    dataType: task.taskTemplateInfo!.dataType!,
    snapshotId: task.snapshotId!,
    params,
  })
}
