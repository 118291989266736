import {
  DosageType,
  MedicationStatement,
} from '@augusthealth/models/com/august/protos/medication_statement'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import {
  dosageTypeToDisplay,
  isUnknownDosageType,
  UnknownDosageType,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import { vitalEnumToLabel } from '@shared/utils/vitals'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import {
  getMedOrderDosageType,
  mapFormMedToMedOrder,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { MedicationOrderDoseSchedules } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/MedicationOrderDoseSchedules'
import { ScheduleBadges } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleBadges'
import { VitalOrderSchedule } from '@app/components/Residents/Medications/Orders/VitalOrderSchedule'

type Props = {
  use24HourTime: boolean
}

export default function NewOrderScheduleCard(props: Props) {
  const { use24HourTime } = props
  const [expanded, setExpanded] = useState(false)
  const methods = useFormContext<MedOrderFormData>()

  const { formState, watch } = methods

  const medOrderFormData = watch()
  const emptyOrder: Partial<MedicationOrder> = {
    medicationStatement: {},
  }
  const wholeOrderDoseType = getMedOrderDosageType(
    mapFormMedToMedOrder({
      formData: medOrderFormData,
      medOrder: emptyOrder,
    }).medicationStatement as MedicationStatement
  )
  const userSetDosageType = medOrderFormData.doses?.[0]?.userSetDosageType
  const firstDoseType: Exclude<DosageType, UnknownDosageType> =
    isUnknownDosageType(userSetDosageType)
      ? DosageType.DOSAGE_TYPE_ROUTINE
      : userSetDosageType

  const formHasErrors = Object.keys(formState.errors).length > 0

  const isVitalOrder = !!medOrderFormData.vitalType

  return (
    <CollapsibleCard
      defaultExpanded={true}
      enableAutoScrollOnExpand={formState.isSubmitted}
      expanded={formHasErrors}
      expandTrigger={formState.isSubmitting}
      contentTestId={'diy-schedule-card'}
      cardTitle={
        <div className={tw`w-full`}>
          {isVitalOrder ? (
            <MedOrderCardTitle
              value={`Record ${vitalEnumToLabel(medOrderFormData.vitalType)}`}
            />
          ) : (
            <>
              {expanded ? (
                <MedOrderCardTitle value={'Schedule and Dosage...'} />
              ) : (
                <>
                  <MedOrderCardTitle
                    value={
                      formState.dirtyFields?.doses
                        ? `${dosageTypeToDisplay(wholeOrderDoseType)} Schedule...`
                        : 'Schedule and Dosage'
                    }
                  />
                  <ScheduleBadges
                    order={emptyOrder}
                    doseType={firstDoseType}
                    use24HourTime={use24HourTime}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
      role={'list'}
    >
      {isVitalOrder ? (
        <VitalOrderSchedule order={emptyOrder} index={0} />
      ) : (
        <MedicationOrderDoseSchedules
          canAddOrRemoveDoses={true}
          readOnly={false}
          order={emptyOrder}
          isNewOrder={true}
        />
      )}
    </CollapsibleCard>
  )
}
