import { Vital } from '@augusthealth/models/com/august/protos/vital'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  getDefaultVitalValues,
  QueuedVital,
  VitalsFormData,
} from '@shared/utils/vitals'
import { mergePatchVitals } from '@app/api/vitals'
import PersonContext from '@app/contexts/PersonContext'
import VitalsContext from '@app/contexts/VitalsContext'
import Tr from './Tr'
import { IsModifying } from './type'

export default function VitalsTableBody({ vitals }: { vitals: Vital[] }) {
  const { person } = useContext(PersonContext)
  const { setError } = useContext(GlobalContext)
  const { activeTab, latestVitals, refreshAllVitals, refreshLatestVitals } =
    useContext(VitalsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModifying, setIsModifying] = useState<IsModifying>()
  const methods = useForm<VitalsFormData>({})

  useEffect(() => {
    setIsModifying(undefined)
  }, [activeTab])

  useEffect(() => {
    if (isModifying?.vitalId) {
      const vital = vitals.find((v) => v.id === isModifying.vitalId)
      if (vital) {
        methods.reset(getDefaultVitalValues(vital as QueuedVital))
      }
    }
  }, [isModifying?.vitalId])

  if (!person) {
    return null
  }

  const patchVitals = async (patch: Vital) => {
    try {
      setIsSubmitting(true)
      await mergePatchVitals(person, { ...patch, id: isModifying?.vitalId })
      setIsModifying(undefined)
    } catch (e) {
      setError(e)
    }
  }

  const onEditVital = async (data: VitalsFormData) => {
    const vitalsPatch = formDataToVitalsPayload(data)
    // we do not want to set a new recordedAt when updating
    const { recordedAt, ...vitalsPatchWithoutRecordedAt } = vitalsPatch
    await patchVitals(vitalsPatchWithoutRecordedAt)
    await refreshAllVitals(false)
    await refreshLatestVitals()
    setIsSubmitting(false)
  }

  const onDeleteVital = async (vitalId: string) => {
    const vitalsPatch: Vital = {
      panel: { [activeTab!]: null },
      id: vitalId,
    }
    await patchVitals(vitalsPatch)
    await refreshAllVitals(false)
    await refreshLatestVitals()
    setIsSubmitting(false)
  }

  return (
    <tbody>
      {vitals.map((vital) => {
        const { id } = vital
        return (
          <Tr
            key={`vtb-${id}`}
            activeTab={activeTab!}
            isModifying={isModifying}
            methods={methods}
            person={person}
            vital={vital}
            isSubmitting={isSubmitting}
            latestVitals={latestVitals}
            setIsModifying={setIsModifying}
            onDeleteVital={onDeleteVital}
            onEditVital={onEditVital}
          />
        )
      })}
    </tbody>
  )
}
