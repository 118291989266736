import { PersonFilter } from '@augusthealth/models/com/august/protos/scheduled_task_assignment'
import { TaskType } from '@augusthealth/models/com/august/protos/task'
import Badge from '@shared/components/Badge'
import Card from '@shared/components/Card'
import { ScheduledTaskSettings } from '@shared/types/scheduled_task_settings'
import { DataType } from '@shared/types/snapshot'
import { TaskDefinitionSettings } from '@shared/types/task_definition_settings'
import {
  getDaysInAdvance,
  getDaysToExpire,
  getFilters,
  getIdentifyingKey,
  getTasksToCreate,
} from '@shared/utils/scheduledTaskSettings'
import { getTitleFromDataType } from '@shared/utils/task'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'

export function ScheduledTaskCard({
  settings,
  taskSettings,
}: {
  settings: ScheduledTaskSettings
  taskSettings: TaskDefinitionSettings[]
}) {
  const settingsLevel = getSettingsLevel(settings)
  const [dataType, customType] = getIdentifyingKey(settings)
  const filters = getFilters(settings)
  const expiresEvery = getDaysToExpire(settings)
  const daysInAdvance = getDaysInAdvance(settings)

  const tasksCreated = getTasksToCreate(settings)
    .map((taskType) => ({
      taskType,
      customType,
    }))
    .map(nameForTaskType(taskSettings))

  const title = nameForDataType(taskSettings, dataType, customType)

  return (
    <Card className={'mb-[16px] flex-col'} data-testid={dataType}>
      <div className={'flex items-center'}>
        <span className={'font-medium'}>{title}</span>
        <Badge shape={'square'} color={'purple'} className={'ml-[8px]'}>
          {settingsLevel}
        </Badge>
      </div>
      <div className={'mt-[16px] text-[14px]'}>
        <ul className={'mb-0 pl-[16px]'}>
          <li>Expires every: {expiresEvery} days</li>
          <li>Task is created: {daysInAdvance} days in advance of expiry</li>
          <FiltersItem filters={filters} />
          {tasksCreated.length > 0 && (
            <li>
              Tasks created:{' '}
              {tasksCreated.map((name, i) => (
                <span key={`task-created-${i}`}>{name}</span>
              ))}
            </li>
          )}
        </ul>
      </div>
    </Card>
  )
}

function FiltersItem({ filters }: { filters: PersonFilter[] }) {
  if (filters.length === 0) {
    return (
      <li>
        Applies to:{' '}
        <Badge color={'gray'} shape={'square'}>
          Everyone
        </Badge>
      </li>
    )
  }

  return (
    <li>
      Applies to people with any of:{' '}
      {filters
        .map((pf) => pf.replace('PERSON_FILTER_', '').replaceAll('_', ' '))
        .map((pf) => (
          <Badge
            color={'gray'}
            shape={'square'}
            className={'mr-[8px]'}
            key={pf}
          >
            {pf}
          </Badge>
        ))}
    </li>
  )
}

function nameForTaskType(taskSettings: TaskDefinitionSettings[]) {
  // eslint-disable-next-line react/display-name
  return ({
    taskType,
    customType,
  }: {
    taskType: TaskType
    customType: string | undefined
  }) => {
    const taskDefinition = taskSettings.find(
      (ts) =>
        ts.settings.taskDefinition.template?.taskType === taskType &&
        ts.settings.taskDefinition.template?.customType === customType
    )

    return (
      taskDefinition?.settings.taskDefinition.template?.displayName ?? (
        <>
          <span title="Task not found in Task Definitions">⚠️</span>
          {taskType}
        </>
      )
    )
  }
}

function nameForDataType(
  taskSettings: TaskDefinitionSettings[],
  dataType: DataType,
  customType: string | undefined
) {
  return (
    taskSettings.find(
      (ts) =>
        ts.settings.taskDefinition.template?.dataType === dataType &&
        ts.settings.taskDefinition.template?.customType === customType
    )?.settings.taskDefinition.template?.displayName ??
    getTitleFromDataType(dataType)
  )
}
