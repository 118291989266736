import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { isResponsiblePerson as contactIsResponsiblePerson } from '@shared/utils/contact'
import { isResponsiblePerson as loginAsResponsiblePerson } from '@shared/utils/user'
import { RHFSwitch } from '@app/components/Switch'
import styles from './styles.module.css'
import { ContactDetails } from '../ContactCard'
import { rolesForCategory } from './helpers'
import { OnClose } from './types'

type Props = {
  contact?: Contact
  isEditing?: boolean
  methods: UseFormReturn
  onClose: OnClose
  setShowContactRoles: (isEditing: boolean) => void
  person: Person
}
export default function RolesTab({
  contact,
  isEditing,
  methods,
  onClose,
  setShowContactRoles,
  person,
}: Props) {
  const { formState, getValues, register, setValue } = methods
  const primaryRelationship = getValues('primaryRelationship')
  const secondaryRelationship = getValues('secondaryRelationship')?.value
  const [roleOptions, setRoleOptions] = useState<
    OptionTypeBase<Contact_ContactRelationship>[]
  >(rolesForCategory(primaryRelationship, secondaryRelationship))
  const { user } = useUserContext()
  const contactIsRP = contactIsResponsiblePerson(contact!)
  const { orgId, facilityId, id: personId } = person
  const hideNote =
    contactIsRP &&
    loginAsResponsiblePerson({ user, orgId, facilityId, personId })

  useEffect(() => {
    setRoleOptions(rolesForCategory(primaryRelationship, secondaryRelationship))
  }, [primaryRelationship])

  return (
    <>
      <h2 className="mb-[24px] text-[24px] leading-[24px]">
        {isEditing ? 'Edit Contact' : 'Add Roles & Notes'}
      </h2>
      <div className={styles.card}>
        <ContactDetails contact={contact!} />
        {isEditing && (
          <AsyncIconButton
            className="mt-[16px]"
            onClick={() => setShowContactRoles(false)}
            buttonStyle="secondary-fill"
            buttonSize="xsmall"
            width="128px"
          >
            Edit Contact Details
          </AsyncIconButton>
        )}
      </div>
      <div className="mt-[32px]">
        <LabelAboveInput uppercase={false} htmlFor="roles">
          Role
        </LabelAboveInput>
        {contactIsRP && (
          <RHFSwitch
            onClick={() => {
              // disabled/checked props break things unexpectedly, so just keep the value in the array
              setValue('roles', [...getValues('roles')])
            }}
            id="Responsible Person"
            name="roles"
            switchSize="small"
            label="Responsible Person"
            register={register}
            value={
              Contact_ContactRelationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON
            }
          />
        )}
        {roleOptions.map(({ label, value }) => (
          <RHFSwitch
            data-testid={label}
            key={`${label}-switch`}
            id={`${label}`}
            holderClassName="mt-[16px]"
            register={register}
            name="roles"
            value={value}
            label={label}
            switchSize="small"
          />
        ))}
      </div>
      {!hideNote && (
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="notes" uppercase={false}>
            Notes
          </LabelAboveInput>
          <BasicTextarea {...register('notes')} />
        </div>
      )}
      <div className="mt-[32px]">
        <AnimatedPopupFormFooter
          formState={formState}
          noBtn={{
            label: isEditing ? 'Cancel' : 'Back',
            action: isEditing
              ? () => onClose(false)
              : () => setShowContactRoles(false),
          }}
          yesBtn={{}}
        />
      </div>
    </>
  )
}
