import { encodeQueryParams } from '@shared/api/request'
import {
  baseSelectControlStyles,
  errorBorderControlStyles,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import notEmpty from '@shared/utils/notEmpty'

type RxTermsResponse = {
  medicationName: string[]
  medicationCodes: string[]
  strengthsAndForms: string[][]
  rxCuis: string[][]
}

export type StrengthAndForm = {
  strengthAndForm: string
  rxCui?: string
}
export type FormattedRxTerm = {
  displayName: string
  codeName: string
  strengthsAndForms: StrengthAndForm[]
}
const searchMedicationsByName = async (name: string) => {
  const response = await fetch(
    encodeQueryParams(
      `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?ef=STRENGTHS_AND_FORMS,RXCUIS&terms=${name}`
    )
  )

  const jsonResponse: RxTermsResponse = await response.json()

  return formatRxTermsResponse(jsonResponse)
}

const formatRxTermsResponse = (
  response: RxTermsResponse
): FormattedRxTerm[] => {
  const medicationNames = (response[1] ?? []).map((s) => s.trim())
  const strengthsAndForms = (response[2]['STRENGTHS_AND_FORMS'] ?? []).map(
    (strengths) => strengths.map((s) => s.trim())
  )

  const formattedResponse = {
    medicationNames,
    strengthsAndForms,
    rxCuis: response[2]['RXCUIS'],
    medicationCodes: response[3],
  }

  return formattedResponse.medicationNames.map((medName, index) => {
    // med code is a one to one match
    const matchingMedCodes = formattedResponse.medicationCodes[index]

    // strengthAndForm and rxCui will have an array of options
    const matchingStrengthsAndForms = formattedResponse.strengthsAndForms[index]
    const matchingRxCuis = formattedResponse.rxCuis[index]

    const pairedStrengthAndRxCui = matchingStrengthsAndForms.map(
      (saf, sIndex) => ({
        strengthAndForm: saf,
        rxCui: matchingRxCuis[sIndex],
      })
    )

    return {
      displayName: medName,
      codeName: matchingMedCodes[0],
      strengthsAndForms: pairedStrengthAndRxCui,
    }
  })
}

export const getAndCacheMedications = ({
  cache,
  includeVitalOption,
}: {
  cache: React.Dispatch<
    React.SetStateAction<OptionTypeBase<FormattedRxTerm | string>[]>
  >
  includeVitalOption: boolean
}): ((term: string) => Promise<OptionTypeBase<FormattedRxTerm | string>[]>) => {
  return async function (term: string) {
    try {
      const matches = await searchMedicationsByName(term)

      const asOptions: OptionTypeBase<FormattedRxTerm | string>[] = [
        includeVitalOption ? RECORD_VITAL_OPTION : null,
        ...matches.map((matchingMedication) => {
          return {
            value: matchingMedication,
            label: matchingMedication.displayName,
          }
        }),
      ].filter(notEmpty)

      cache(asOptions)

      return asOptions
    } catch {
      return []
    }
  }
}

export const RECORD_VITAL_OPTION: OptionTypeBase = {
  label: 'Record Vital',
  value: 'vital',
}

export const MedicationSearchStyles = (error: boolean) => ({
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles('medium', state),
    ...errorBorderControlStyles(error, state),
    height: '64px',
    padding: '20px',
  }),
  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: '20px',
    lineHeight: '24px',
    height: '100%',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: '20px',
    lineHeight: '24px',
    height: '100%',
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: '20px',
    lineHeight: '24px',
    height: '100%',
  }),
})
