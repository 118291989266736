import { requestJson } from '@shared/api/request'
import { apiUrl, personUrl } from '@shared/api/urls'
import { Person, RequiredPersonIds } from '@shared/types/person'
import { RoutineOrder, RoutineStatus } from '@shared/types/routine_order'
import { DeepNull } from '@shared/types/utilities'
import { AugustError } from '@shared/utils/error'
import { Result } from '@shared/utils/loading'

type CreateRoutineOrderResponse = Result<
  { data: { id: string }; meta: { hello: 'Created' } },
  AugustError
>
type UpdateRoutineOrderResponse = Result<RoutineOrder, AugustError>
type DiscontinueRoutineOrderResponse = Result<RoutineOrder, AugustError>
type DiscardRoutineOrderResponse = Result<RoutineOrder, AugustError>
type GetRoutineOrdersResponse = Result<RoutineOrder[], AugustError>
type GetRoutineOrderResponse = Result<RoutineOrder, AugustError>

type CreateRoutineOrderRequired = Required<
  Pick<
    RoutineOrder,
    'name' | 'categoryKey' | 'instructions' | 'status' | 'routineType'
  >
> & {
  schedules: Omit<DeepNull<RoutineOrder['schedules']>, 'id'>
}
type CreateRoutineOrderOptional = Pick<
  RoutineOrder,
  'effectivePeriod' | 'personId'
> &
  DeepNull<Pick<RoutineOrder, 'customKey'>>
export type CreateRoutineOrderPayload = CreateRoutineOrderRequired &
  CreateRoutineOrderOptional

export type UpdateRoutineOrderPayload = Omit<RoutineOrder, 'schedules'> & {
  schedules: DeepNull<RoutineOrder['schedules']>
}

const routineOrdersUrl = (person: RequiredPersonIds) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/routineOrders',
    { includesAssessmentRoutineOrder: true }
  )

const routineOrderUrl = (person: RequiredPersonIds, routineId: string) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/routineOrders/:id',
    { id: routineId, includesAssessmentRoutineOrder: true }
  )

const routineOrderByGroupUrl = (
  person: RequiredPersonIds,
  routineGroupId: string
) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/routineOrders/orderGroups/:orderGroupId/latest',
    { orderGroupId: routineGroupId, includesAssessmentRoutineOrder: true }
  )

const discontinueRoutineOrderUrl = (
  person: RequiredPersonIds,
  routineId: string
) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/routineOrders/:id/discontinue',
    { id: routineId }
  )

const discardRoutineOrderUrl = (person: RequiredPersonIds, routineId: string) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/routineOrders/:id/discard',
    { id: routineId }
  )

export const createRoutineOrder = async (
  person: RequiredPersonIds,
  data: Omit<CreateRoutineOrderPayload, 'status' | 'personId'>
): Promise<CreateRoutineOrderResponse> => {
  const scheduledRoutineOrder: CreateRoutineOrderPayload = {
    ...data,
    personId: person.id,
    status: RoutineStatus.ROUTINE_STATUS_ACTIVE,
  }

  try {
    const responseJson = await requestJson({
      url: routineOrdersUrl(person),
      method: 'POST',
      body: JSON.stringify(scheduledRoutineOrder),
    })

    return { tag: 'Complete', value: responseJson }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export async function updateRoutineOrder(
  person: RequiredPersonIds,
  updateData: UpdateRoutineOrderPayload
): Promise<UpdateRoutineOrderResponse> {
  try {
    const responseJson = await requestJson({
      url: routineOrderUrl(person, updateData.id),
      method: 'POST',
      body: JSON.stringify(updateData),
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export async function discontinueRoutineOrder(
  person: RequiredPersonIds,
  routineOrderId: string
): Promise<DiscontinueRoutineOrderResponse> {
  try {
    const responseJson = await requestJson({
      url: discontinueRoutineOrderUrl(person, routineOrderId),
      method: 'POST',
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export async function discardRoutineOrder(
  person: RequiredPersonIds,
  routineOrderId: string
): Promise<DiscardRoutineOrderResponse> {
  try {
    const responseJson = await requestJson({
      url: discardRoutineOrderUrl(person, routineOrderId),
      method: 'POST',
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export const getRoutineOrders = async (
  person: Person
): Promise<GetRoutineOrdersResponse> => {
  try {
    const responseJson = await requestJson({
      url: routineOrdersUrl(person),
      method: 'GET',
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export async function getRoutineOrder(
  person: RequiredPersonIds,
  routineOrderId: string
): Promise<GetRoutineOrderResponse> {
  try {
    const responseJson = await requestJson({
      url: routineOrderUrl(person, routineOrderId),
      method: 'GET',
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}

export async function getLatestRoutineOrder(
  person: RequiredPersonIds,
  routineOrderGroupId: string
): Promise<GetRoutineOrderResponse> {
  try {
    const responseJson = await requestJson({
      url: routineOrderByGroupUrl(person, routineOrderGroupId),
      method: 'GET',
    })

    return { tag: 'Complete', value: responseJson.data }
  } catch (e) {
    return { tag: 'Error', value: e as AugustError }
  }
}
