import { clearCareAppUserAccountCache } from '@careapp/cache'
import { Auth } from 'aws-amplify'
import localForage from 'localforage'
import { useEffect } from 'react'
import { isCurrentSessionValid } from '@shared/api/user'
import {
  clearAuthenticatedUser,
  cognitoAuthEnabled,
  cookieAuthEnabled,
  logoutForAuthMethod,
} from '@shared/components/Auth/helpers'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { clearEmarRuntimeCache } from '@shared/utils/emar'
import { isConnectivityError } from '@shared/utils/error'

export async function logout(redirectTo?: string): Promise<void> {
  return logoutForAuthMethod().finally(() => {
    clearCachesAndRedirect({ clearUser: true, redirectTo })
  })
}

export function clearCachesAndRedirect({
  clearUser,
  redirectTo,
}: {
  clearUser: boolean
  redirectTo?: string
}): void {
  void Promise.allSettled([
    clearCareAppUserAccountCache(),
    clearEmarRuntimeCache(),
    clearLastUserActivity(),
    clearUser ? clearAuthenticatedUser() : Promise.resolve(),
  ]).then(() => {
    if (redirectTo) {
      window.location.href = redirectTo
    } else {
      window.location.reload()
    }
  })
}

export async function isLoggedIn(): Promise<boolean> {
  try {
    if (cognitoAuthEnabled()) {
      await Auth.currentSession()
      return true
    } else if (cookieAuthEnabled()) {
      return await isCurrentSessionValid()
    }
    return false
  } catch (e) {
    if (isConnectivityError(e)) {
      throw e
    }

    return false
  }
}

export async function setLastUserActivity(): Promise<void> {
  const current = Date.now()
  await localForage.setItem('lastUserActivity', `${current}`)
}

async function getLastUserActivity(): Promise<number | null> {
  const raw = await localForage.getItem<string>('lastUserActivity')
  return raw === null ? null : parseInt(raw)
}

export async function clearLastUserActivity(): Promise<void> {
  await localForage.removeItem('lastUserActivity')
}

export function ValidateUserSession({
  interval,
  sessionInactivityExpiration,
  failureRedirectUrl,
}: {
  interval: number
  sessionInactivityExpiration: number
  failureRedirectUrl?: string
}) {
  useEffect(() => {
    const refreshTokenTimer = window.setInterval(() => {
      // if there hasn't been user activity in X hours, invalidate session
      void getLastUserActivity().then((lastActivity) => {
        if (
          lastActivity === null ||
          lastActivity + sessionInactivityExpiration < Date.now()
        ) {
          // then it's an invalid session, logout
          ErrorMonitoring.capture({
            error: 'ValidateUserSession logging user out',
            level: 'log',
          })
          void logout(failureRedirectUrl)
        }
      })
    }, interval)
    return () => window.clearInterval(refreshTokenTimer)
  }, [sessionInactivityExpiration, interval])
  return null
}
