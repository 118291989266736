import { tw } from '@shared/utils/tailwind'

export default function LogoHeader({
  title,
  copy,
}: {
  title: string
  copy?: string
}) {
  return (
    <div className={tw`flex flex-col items-center gap-6`}>
      <div className={tw`flex items-center`}>
        <img
          src="/svg/symbol.svg"
          alt="August Health logo symbol"
          className={tw`mr-[10px] h-[32px] w-[32px]`}
        />
        <img
          src="/svg/wordmark-copy-only.svg"
          alt="August Health logo copy"
          className={tw`h-[38px] w-[228px]`}
        />
      </div>

      <div className={tw`font-inter text-[20px] font-semibold leading-[24px]`}>
        {title}
      </div>
      {copy && (
        <div className={tw`text-center font-medium text-gray-07`}>{copy}</div>
      )}
    </div>
  )
}
