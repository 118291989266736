import { capitalize } from 'lodash'
import React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { match } from 'ts-pattern'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  Parameter,
  ParameterConditional,
  ParameterType,
  ValidParameterConditional,
  ValidParameterType,
} from '@shared/types/dosage'
import {
  validatePainScale,
  validatePositiveNumber,
} from '@shared/utils/formValidationFunctions'
import notEmpty from '@shared/utils/notEmpty'
import { validStringOrNull } from '@shared/utils/parsing'
import {
  AllVitalTypes,
  ParameterizedVitalsType,
  Units,
  UnitsLabel,
  VitalsFormData,
  VitalsType,
  VitalsTypeDropdowns,
} from '@shared/utils/vitals'
import { MedicationAdministrationRow } from '@emar/db/emar'
import { AdministerablePrn } from '@emar/hooks/useDeferredAdministerablePrns'

export const getParameterConditionalOptions =
  (): OptionTypeBase<ValidParameterConditional>[] => {
    return Object.values(ParameterConditional)
      .filter(
        (conditional) =>
          conditional !== ParameterConditional.UNRECOGNIZED &&
          conditional !== ParameterConditional.PARAMETER_CONDITIONAL_UNSPECIFIED
      )
      .map((conditional) => {
        return {
          label: getReadableParameterConditional(conditional),
          value: conditional,
        }
      })
  }

export const getParameterTypeOptions =
  (): OptionTypeBase<ValidParameterType>[] => {
    return Object.values(ParameterType)
      .filter(
        (param) =>
          param !== ParameterType.UNRECOGNIZED &&
          param !== ParameterType.PARAMETER_TYPE_UNSPECIFIED
      )
      .map((param) => {
        return {
          label: getReadableParameterType(param),
          value: param,
        }
      })
  }

export const getReadableParameterType = (
  parameterType: ParameterType
): string => {
  return parameterType
    .replace('PARAMETER_TYPE_', '')
    .split('_')
    .map((namePart: string) => capitalize(namePart))
    .join(' ')
}

export const getReadableParameterConditional = (
  parameterConditional: ValidParameterConditional
): string => {
  return match(parameterConditional)
    .returnType<string>()
    .with(
      ParameterConditional.PARAMETER_CONDITIONAL_GREATER_THAN,
      () => 'above'
    )
    .with(ParameterConditional.PARAMETER_CONDITIONAL_EQUAL_TO, () => 'exactly')
    .with(ParameterConditional.PARAMETER_CONDITIONAL_LESS_THAN, () => 'below')
    .exhaustive()
}

const parameterConditionalToSymbolLabel = (
  parameterConditional: ValidParameterConditional
) => {
  return match(parameterConditional)
    .returnType<string>()
    .with(ParameterConditional.PARAMETER_CONDITIONAL_GREATER_THAN, () => '>')
    .with(ParameterConditional.PARAMETER_CONDITIONAL_EQUAL_TO, () => '=')
    .with(ParameterConditional.PARAMETER_CONDITIONAL_LESS_THAN, () => '<')
    .exhaustive()
}

/**
 * Returns a label that reads "parameterLabel = | > | < numericValue" joined by OR
 * i.e. systolic blood pressure > 110 OR weight < 85
 * @param parameters
 */
export const buildConditionalParameterLabelForAdministration = (
  parameters: Parameter[]
): string => {
  return parameters
    .map((param) => {
      const vitalLabel = getReadableParameterType(
        param.parameterType as ValidParameterType
      )
      const comparison = parameterConditionalToSymbolLabel(
        param.conditional as ValidParameterConditional
      )

      return `${vitalLabel} ${comparison} ${param.numeric?.value}`
    })
    .join(' OR ')
}

export const parameterTypeToUnit = (
  parameterType: ValidParameterType
): string => {
  return match(parameterType)
    .with(ParameterType.PARAMETER_TYPE_WEIGHT, () => Units.lbs)
    .with(ParameterType.PARAMETER_TYPE_TEMPERATURE, () => Units.degF)
    .with(
      ParameterType.PARAMETER_TYPE_DIASTOLIC_BLOOD_PRESSURE,
      () => Units.mmHg
    )
    .with(
      ParameterType.PARAMETER_TYPE_SYSTOLIC_BLOOD_PRESSURE,
      () => Units.mmHg
    )
    .with(ParameterType.PARAMETER_TYPE_HEART_RATE, () => Units.bpm)
    .with(ParameterType.PARAMETER_TYPE_RESPIRATORY_RATE, () => Units.brpm)
    .with(ParameterType.PARAMETER_TYPE_OXYGEN_SATURATION, () => Units.o2)
    .with(ParameterType.PARAMETER_TYPE_BLOOD_SUGAR, () => Units.mgdL)
    .with(ParameterType.PARAMETER_TYPE_PAIN, () => Units.pain)
    .with(ParameterType.PARAMETER_TYPE_HEIGHT, () => Units.inch)
    .exhaustive()
}

export const parameterTypeToUnitLabel = (
  parameterType: ValidParameterType
): string => {
  return match(parameterType)
    .with(ParameterType.PARAMETER_TYPE_WEIGHT, () => UnitsLabel.lbs)
    .with(ParameterType.PARAMETER_TYPE_TEMPERATURE, () => UnitsLabel.degF)
    .with(
      ParameterType.PARAMETER_TYPE_DIASTOLIC_BLOOD_PRESSURE,
      () => UnitsLabel.mmHg
    )
    .with(
      ParameterType.PARAMETER_TYPE_SYSTOLIC_BLOOD_PRESSURE,
      () => UnitsLabel.mmHg
    )
    .with(ParameterType.PARAMETER_TYPE_HEART_RATE, () => UnitsLabel.bpm)
    .with(ParameterType.PARAMETER_TYPE_RESPIRATORY_RATE, () => UnitsLabel.brpm)
    .with(ParameterType.PARAMETER_TYPE_OXYGEN_SATURATION, () => UnitsLabel.o2)
    .with(ParameterType.PARAMETER_TYPE_BLOOD_SUGAR, () => UnitsLabel.mgdL)
    .with(ParameterType.PARAMETER_TYPE_PAIN, () => UnitsLabel.pain)
    .with(ParameterType.PARAMETER_TYPE_HEIGHT, () => UnitsLabel.inch)
    .exhaustive()
}

type ParameterValidation = {
  validationFunction: () => boolean | string
  props: Partial<React.HTMLProps<HTMLInputElement>>
  valuePlaceholder: string
}
const commonParameterProps = {
  type: 'number',
  min: 0,
}
export const parameterTypeToValidation = (
  parameterType: ValidParameterType
) => {
  return match(parameterType)
    .returnType<ParameterValidation>()
    .with(ParameterType.PARAMETER_TYPE_WEIGHT, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_WEIGHT
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_TEMPERATURE, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_TEMPERATURE
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_DIASTOLIC_BLOOD_PRESSURE, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_DIASTOLIC_BLOOD_PRESSURE
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_SYSTOLIC_BLOOD_PRESSURE, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_SYSTOLIC_BLOOD_PRESSURE
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_HEART_RATE, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_HEART_RATE
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_RESPIRATORY_RATE, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_RESPIRATORY_RATE
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_OXYGEN_SATURATION, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_OXYGEN_SATURATION
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_BLOOD_SUGAR, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_BLOOD_SUGAR
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_PAIN, () => ({
      validationFunction: validatePainScale,
      props: { ...commonParameterProps, max: 10 },
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_PAIN
      ),
    }))
    .with(ParameterType.PARAMETER_TYPE_HEIGHT, () => ({
      validationFunction: validatePositiveNumber,
      props: commonParameterProps,
      valuePlaceholder: parameterTypeToUnitLabel(
        ParameterType.PARAMETER_TYPE_HEIGHT
      ),
    }))
    .exhaustive()
}

export const parameterTypeToVitalType = (
  parameterType: ValidParameterType
): AllVitalTypes | null => {
  return match(parameterType)
    .with(ParameterType.PARAMETER_TYPE_WEIGHT, () => VitalsType.WEIGHT)
    .with(
      ParameterType.PARAMETER_TYPE_TEMPERATURE,
      () => VitalsType.TEMPERATURE
    )
    .with(
      ParameterType.PARAMETER_TYPE_DIASTOLIC_BLOOD_PRESSURE,
      () => ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE
    )
    .with(
      ParameterType.PARAMETER_TYPE_SYSTOLIC_BLOOD_PRESSURE,
      () => ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE
    )
    .with(ParameterType.PARAMETER_TYPE_HEART_RATE, () => VitalsType.HEART_RATE)
    .with(
      ParameterType.PARAMETER_TYPE_RESPIRATORY_RATE,
      () => VitalsType.RESPIRATORY_RATE
    )
    .with(
      ParameterType.PARAMETER_TYPE_OXYGEN_SATURATION,
      () => VitalsType.OXYGEN_SATURATION
    )
    .with(
      ParameterType.PARAMETER_TYPE_BLOOD_SUGAR,
      () => VitalsType.BLOOD_SUGAR
    )
    .with(ParameterType.PARAMETER_TYPE_PAIN, () => ParameterizedVitalsType.PAIN)
    .with(
      ParameterType.PARAMETER_TYPE_HEIGHT,
      () => ParameterizedVitalsType.HEIGHT
    )
    .exhaustive()
}

export const administrationHasConditionalParameters = (
  administration: MedicationAdministrationRow | AdministerablePrn
): boolean => {
  return Boolean(administration.dosageInstruction?.parameters?.length)
}

export const administrationHasBloodSugarConditionalParameter = (
  administration: MedicationAdministrationRow
): boolean => {
  if (administrationHasConditionalParameters(administration)) {
    const parameters = administration.dosageInstruction
      ?.parameters as Parameter[]

    return (
      parameters.filter(
        (param) =>
          param.parameterType === ParameterType.PARAMETER_TYPE_BLOOD_SUGAR
      ).length > 0
    )
  }

  return false
}

function hasFilledInDropdownValue<Form extends FieldValues>({
  parameterFormName,
  formData,
}: {
  formData: Form
  parameterFormName: FieldPath<Form>
}) {
  const vitalDropdownName: string | undefined = VitalsTypeDropdowns[
    parameterFormName as AllVitalTypes
  ] as string

  if (vitalDropdownName) {
    return !!formData[vitalDropdownName as FieldPath<Form>]
  }

  return true
}

export function isFormMissingConditionalParameterData<
  Form extends FieldValues,
>({
  formData,
  administration,
}: {
  formData: Form
  administration: MedicationAdministrationRow | AdministerablePrn
}): boolean {
  const hasConditionalParameters =
    administrationHasConditionalParameters(administration)

  if (!hasConditionalParameters) {
    return false
  }

  const parameters = administration.dosageInstruction?.parameters as Parameter[]
  const parameterTypes: ValidParameterType[] = parameters
    .map((param) => param.parameterType as ValidParameterType)
    .filter(notEmpty)
  const parameterFormNames = parameterTypes
    .map((paramType) => parameterTypeToVitalType(paramType))
    .filter(notEmpty) as FieldPath<Form>[]

  const formIsValid = parameterFormNames.every((paramName) => {
    return (
      validStringOrNull(formData[paramName]) &&
      hasFilledInDropdownValue({ parameterFormName: paramName, formData })
    )
  })

  return !formIsValid
}

export enum ConditionalAction {
  hold = 'hold',
  administer = 'administer',
}

const compareFormDataWithConditionalParameter = ({
  formData,
  parameter,
}: {
  formData: Partial<VitalsFormData>
  parameter: Parameter
}): ConditionalAction => {
  const parameterType = parameter.parameterType
  const comparison = parameter.numeric?.value

  const parameterName =
    parameterType &&
    parameterTypeToVitalType(parameterType as ValidParameterType)

  if (!parameterName || comparison === undefined) {
    return ConditionalAction.hold
  }

  const conditional = parameter.conditional as ValidParameterConditional
  return match(conditional)
    .returnType<ConditionalAction>()
    .with(ParameterConditional.PARAMETER_CONDITIONAL_EQUAL_TO, () => {
      return +formData[parameterName] === comparison
        ? ConditionalAction.hold
        : ConditionalAction.administer
    })
    .with(ParameterConditional.PARAMETER_CONDITIONAL_GREATER_THAN, () => {
      return +formData[parameterName] > comparison
        ? ConditionalAction.hold
        : ConditionalAction.administer
    })
    .with(ParameterConditional.PARAMETER_CONDITIONAL_LESS_THAN, () => {
      return +formData[parameterName] < comparison
        ? ConditionalAction.hold
        : ConditionalAction.administer
    })
    .exhaustive()
}

export function shouldHoldMedicationDueToConditionalParameters<
  Form extends FieldValues,
>({
  formData,
  administration,
}: {
  formData: Form
  administration: MedicationAdministrationRow | AdministerablePrn
}): boolean {
  const mapped = getConditionalParameterHoldResults({
    formData,
    administration,
  })

  return shouldHoldDueToConditionalParameterHoldResults(mapped)
}

export function shouldHoldDueToConditionalParameterHoldResults<
  Form extends FieldValues,
>(results: ConditionalParameterHoldResults<Form>): boolean {
  return Object.values(results).some(
    (holdOrAdminister) => holdOrAdminister.action === ConditionalAction.hold
  )
}

export function getConditionalParameterHoldResults<Form extends FieldValues>({
  formData,
  administration,
}: {
  formData: Form
  administration: MedicationAdministrationRow | AdministerablePrn
}): ConditionalParameterHoldResults<Form> {
  const hasConditionalParameters =
    administrationHasConditionalParameters(administration)

  if (!hasConditionalParameters) {
    return {} as ConditionalParameterHoldResults<Form>
  }

  const parameters = administration.dosageInstruction?.parameters as Parameter[]

  return parameters.reduce((accum, param) => {
    const formKey = parameterTypeToVitalType(
      param.parameterType as ValidParameterType
    ) as AllVitalTypes

    return {
      ...accum,
      [formKey]: {
        action: compareFormDataWithConditionalParameter({
          formData,
          parameter: param,
        }),
        parameter: param,
      },
    }
  }, {}) as ConditionalParameterHoldResults<Form>
}

type ConditionalParameterHoldResults<Form> = Record<
  keyof Form,
  ConditionalParameterHoldResult
>

type ConditionalParameterHoldResult = {
  action: ConditionalAction
  parameter: Parameter
}
