import {
  Task,
  TaskTemplateInfo,
} from '@augusthealth/models/com/august/protos/task'
import { isEqual, sortBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { fetchTasks } from '@shared/api/task'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { documentUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { getOrElse, Loading } from '@shared/utils/loading'
import { taskTitle } from '@shared/utils/task'
import { isSignable } from '@shared/utils/taskTemplateInfo'
import { useImmunizationsByPerson } from '@app/hooks/useImmunizations'
import { useToolsUploadableDocuments } from '@app/hooks/useUploadableDocuments'
import DocumentUploadZone from '@app/pages/Documents/Uploader/DocumentUploadZone/DocumentUploadZone'
import { configuredNameForDataType } from '@app/pages/Documents/Uploader/helpers'
import styles from './styles.module.css'
import { OrgFacilityPersonSelects } from '../SharedSelects/OrgFacilityPersonSelects'
import FormProducer from './FormProducer'
import {
  submitImmunizationsWithPartialUpload,
  submitPartialDocument,
  validateForm,
} from './helpers'
import PdfCreator from './PdfCreator/PdfCreator'

type UploadedDoc = {
  fileName: string
  documentUrl: string
  datatype: string
}
export const ResidentDocumentUploader = () => {
  const { setError } = useContext(GlobalContext)

  const [currentTaskTemplate, setCurrentTaskTemplate] = useState<
    TaskTemplateInfo | undefined
  >()
  const [tasks, setTasks] = useState<Loading<Task[]>>({ tag: 'Loading' })
  const [showUploadInProgress, setShowUploadInProgress] = useState(false)
  const [file, setFile] = useState<File>()
  const [blobForPdfToCreateFrom, setBlobForPdfToCreateFrom] = useState<any>()
  const [blobForPdfToCreate, setBlobForPdfToCreate] = useState<any>()
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDoc[]>([])
  const [methods, setMethods] = useState<UseFormReturn<any>>()

  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [selectedPerson, setSelectedPerson] =
    useState<OptionTypeBase<Person> | null>(null)

  const [selectedPages, setSelectedPages] = useState<number[]>([])

  const orgId = selectedOrganization?.value?.id
  const facilityId = selectedFacility?.value?.id
  const personId = selectedPerson?.value?.id

  const { immunizations, reloadImmunizations } = useImmunizationsByPerson({
    person: selectedPerson?.value,
  })

  const { uploadableDocuments } = useToolsUploadableDocuments({
    tasks: getOrElse(tasks, []),
    ids: {
      orgId: orgId,
      facilityId: facilityId,
      id: personId,
    },
  })

  const namedUploadOptions = getOrElse(uploadableDocuments, []).map((o) => {
    return {
      label: configuredNameForDataType({
        tasks: getOrElse(tasks, []),
        configuredTemplate: o,
      }),
      value: o,
    }
  })

  const uploadOptions: { label: string; value: TaskTemplateInfo }[] = [
    ...sortBy(namedUploadOptions, (o) => o.label),
    {
      label: 'Profile Photo',
      value: { dataType: DataType.DATA_TYPE_PROFILE_PHOTO },
    },
    {
      label: 'Other Documents',
      value: { dataType: DataType.DATA_TYPE_OTHER_UPLOAD },
    },
  ]

  useEffect(() => {
    if (personId && orgId) {
      setTasks({ tag: 'Loading' })
      fetchTasks({ pId: personId, orgId: orgId })
        .then((response) => setTasks({ tag: 'Complete', value: response.data }))
        .catch(setError)
    }
  }, [personId, orgId])

  const onUploadInitialDocument = async (files: File[]) => {
    setShowUploadInProgress(true)
    try {
      if (files?.length > 0) {
        const blobData = await files[0].arrayBuffer()
        const copyOfBlobData = await files[0].arrayBuffer()

        setBlobForPdfToCreateFrom(blobData)
        setBlobForPdfToCreate(copyOfBlobData)
        setFile(files[0])
      }
    } catch (e) {
      setError(e)
    } finally {
      setShowUploadInProgress(false)
    }
  }

  const onSaveAndUploadDocument = async () => {
    const dataType = currentTaskTemplate?.dataType
    const hasErrors = validateForm({
      file,
      formValues: methods!.getValues(),
      dataType,
      setFormError: methods!.setError,
      setAugustError: setError,
      isSignable: currentTaskTemplate && isSignable(currentTaskTemplate),
    })

    if (hasErrors) {
      return
    }

    setShowUploadInProgress(true)
    try {
      const readableDataType = taskTitle(currentTaskTemplate!) || dataType!
      const fileName =
        (methods && methods.getValues('name')) || file?.name || readableDataType

      const selectedPageNumbers = selectedPages.map((page) => page + 1)
      let documentId: string

      if (dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD) {
        documentId = await submitImmunization(selectedPageNumbers)
      } else {
        documentId = await submitGenericDocument(selectedPageNumbers, fileName)
      }

      const docUrl = documentUrl({
        person: {
          orgId: orgId || '',
          facilityId: facilityId || '',
          id: personId || '',
          residentStatus: undefined,
        },
        documentId: documentId,
      })

      setUploadedDocuments([
        ...uploadedDocuments,
        {
          fileName,
          documentUrl: docUrl,
          datatype: readableDataType,
        },
      ])
      onResetUploader(false)
    } catch (e) {
      setError(e)
    } finally {
      setShowUploadInProgress(false)
    }
  }

  const submitImmunization = async (
    selectedPageNumbers: number[]
  ): Promise<string> => {
    const {
      name: diseaseName,
      immunizations: updatedImmunizationsForSelectedDisease,
      newImmunization,
    } = (methods && methods.getValues()) || {}

    const originalImmunizations =
      immunizations.tag === 'Complete' ? immunizations.value : []

    const updatedImmunizations = [
      ...updatedImmunizationsForSelectedDisease,
      ...originalImmunizations.filter(
        (immu) => immu.protocolApplied?.targetDisease?.text !== diseaseName
      ),
      newImmunization,
    ]

    const response = await submitImmunizationsWithPartialUpload({
      file: file!,
      originalImmunizations,
      selectedPages: selectedPageNumbers,
      updatedImmunizations,
      person: selectedPerson!.value,
    })
    await reloadImmunizations()
    return response.data.attachmentSnapshotId
  }

  const submitGenericDocument = async (
    selectedPageNumbers: number[],
    fileName: string
  ): Promise<string> => {
    const response = await submitPartialDocument({
      taskTemplateInfo: currentTaskTemplate!,
      data: {
        ...methods!.getValues(),
        name: fileName,
      },
      person: selectedPerson!.value,
      file: file!,
      tasks: getOrElse(tasks, []),
      selectedPages: selectedPageNumbers,
    })

    return response.data.id
  }

  const onResetUploader = (resetBlob: boolean) => {
    if (resetBlob) {
      setBlobForPdfToCreateFrom(undefined)
      setBlobForPdfToCreate(undefined)
      setFile(undefined)
      setUploadedDocuments([])
      setShowUploadInProgress(false)
    }
    setCurrentTaskTemplate(undefined)
    setSelectedPages([])
    methods?.reset()
  }

  const isLoadingDropdown =
    uploadableDocuments.tag === 'Loading' || tasks.tag === 'Loading'

  const missingSelection = !personId || !facilityId || !orgId
  const isPdfFile = file?.type === 'application/pdf'

  return (
    <form className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.docContainer}>
          {!blobForPdfToCreateFrom && (
            <DocumentUploadZone
              onFileChange={onUploadInitialDocument}
              uploadInProgress={showUploadInProgress}
              defaultButtonText={'Upload Document'}
            />
          )}
          {blobForPdfToCreateFrom && (
            <>
              <PdfCreator
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
                pdfToCreateFrom={blobForPdfToCreateFrom}
                pdfToCreate={blobForPdfToCreate}
                documentProps={{
                  error: (
                    <img
                      className={styles.errorImage}
                      src={(file && URL.createObjectURL(file)) || ''}
                      alt="Image Preview"
                    />
                  ),
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.action}>
        <div className={styles.formInputs}>
          <LabelAboveInput htmlFor={'organizations'}>
            Org/Facility/Person
          </LabelAboveInput>
          <OrgFacilityPersonSelects
            onOrgSelect={(e) => {
              setSelectedOrganization(e)
              setSelectedFacility(null)
              setSelectedPerson(null)
            }}
            onFacilitySelect={(e) => {
              setSelectedFacility(e)
              setSelectedPerson(null)
            }}
            onPersonSelect={(e) => {
              setSelectedPerson(e)
            }}
            selectedOrganization={selectedOrganization}
            selectedFacility={selectedFacility}
            selectedPerson={selectedPerson}
          />
          <hr className="my-[16px]" />
          <LabelAboveInput htmlFor={'documentType'}>
            Document Type
          </LabelAboveInput>
          <StyledSelect
            placeholder={
              isLoadingDropdown ? 'Loading...' : 'Select Document...'
            }
            value={
              uploadOptions.find((o) =>
                isEqual(o.value, currentTaskTemplate)
              ) ?? null
            }
            options={uploadOptions}
            name="documentType"
            id="documentType"
            instanceId="documentType"
            onChange={(o: OptionTypeBase<TaskTemplateInfo>) =>
              setCurrentTaskTemplate(o.value)
            }
            isDisabled={!blobForPdfToCreateFrom || isLoadingDropdown}
          />
          {currentTaskTemplate && (
            <FormProducer
              currentTaskTemplate={currentTaskTemplate}
              setMethods={setMethods}
              immunizations={immunizations}
            />
          )}
        </div>
        <div className={styles.rightPanelFooter}>
          <div className={styles.previousUploadsContainer}>
            {uploadedDocuments.length > 0 && (
              <>
                <div className={styles.previousUploadsTitle}>
                  Previously Uploaded
                </div>
                <ul className={styles.previousUploadsList}>
                  {uploadedDocuments
                    .slice()
                    .reverse()
                    .map((doc) => {
                      return (
                        <li key={doc.documentUrl}>
                          <a
                            href={doc.documentUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {doc.fileName}
                          </a>
                          <div>{doc.datatype}</div>
                        </li>
                      )
                    })}
                </ul>
              </>
            )}
          </div>
          <AsyncIconButton
            buttonStyle={'primary-fill'}
            onClick={onSaveAndUploadDocument}
            type={'button'}
            isLoading={showUploadInProgress}
            className={styles.button}
            disabled={
              !blobForPdfToCreateFrom ||
              showUploadInProgress ||
              !currentTaskTemplate ||
              (blobForPdfToCreateFrom &&
                isPdfFile &&
                selectedPages.length === 0) ||
              missingSelection
            }
          >
            Upload Document
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle={'secondary-outline'}
            onClick={() => onResetUploader(true)}
            type={'reset'}
            className={styles.button}
          >
            Reset Uploader
          </AsyncIconButton>
        </div>
      </div>
    </form>
  )
}
