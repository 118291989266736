import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { Task } from '@augusthealth/models/com/august/protos/task'
import { useHistory } from 'react-router-dom'
import { UrlAndContentType } from '@shared/api/request'
import { SignatureInfoWithSkip } from '@shared/api/task'
import {
  LightboxSidebarContent,
  LightboxSidebarFooter,
  LightboxSidebarTitle,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { AsyncResult, Loading } from '@shared/utils/loading'
import { isAwaitingReview, taskTitle } from '@shared/utils/task'
import { isResponsiblePerson as isUserRP } from '@shared/utils/user'
import {
  CompleteFormButton,
  PrintDownloadButtons,
  ReviewSignatures,
  StaticReviewCopy,
} from '@app/components/SignatureFlow/Components'
import {
  getAdminCtaText,
  getRpCtaCtext,
  noReviewTaskWithUnassignedSigners,
  shouldDisableCTA,
} from '@app/components/SignatureFlow/helpers'
import Warning from '@app/components/Warning'

export default function SignatureFlowSidebar({
  task,
  user,
  person,
  isTaskIncomplete,
  blobData,
  isLoadingData,
  onClose,
  responsiblePerson,
  isRequestingSignature,
  currentSigners,
  originalConfiguredSigners,
  setCurrentSigners,
  onClickRequestSignature,
  onClickMakeChanges,
}: {
  task: Task
  user: UserAccount
  person: Loading<Person>
  isTaskIncomplete: Loading<boolean>
  blobData: AsyncResult<UrlAndContentType, any>
  isLoadingData: boolean
  onClose: () => void
  responsiblePerson: Contact | undefined
  isRequestingSignature: boolean
  currentSigners: SignatureInfoWithSkip[]
  originalConfiguredSigners: SignatureInfoWithSkip[]
  setCurrentSigners: (signers: SignatureInfoWithSkip[]) => void
  onClickRequestSignature: () => Promise<void | undefined>
  onClickMakeChanges: () => void
}) {
  const history = useHistory()

  if (
    isLoadingData ||
    person.tag === 'Loading' ||
    isTaskIncomplete.tag === 'Loading'
  ) {
    return (
      <LightboxSidebarContent>
        <LightboxSidebarTitle>{taskTitle(task)}</LightboxSidebarTitle>
        <StaticReviewCopy />
        <PrintDownloadButtons blobData={blobData} />
      </LightboxSidebarContent>
    )
  }

  const { orgId, facilityId, id: personId } = person.value
  const isRP = isUserRP({ user, orgId, facilityId, personId })
  const shouldSeeSigners = !isTaskIncomplete.value && !isRP
  const requestSignatureDisabled = shouldDisableCTA({
    currentSigners,
    isRP,
    isTaskIncomplete,
    isRequestingSignature,
    task,
  })

  const adminCtaText = getAdminCtaText({
    currentSigners,
    user,
  })

  const rpCtaText = getRpCtaCtext({
    currentSigners,
    task,
  })

  // CTAs change depending on who is first
  const ctaText = isRP ? rpCtaText : adminCtaText

  return (
    <>
      <LightboxSidebarContent>
        <LightboxSidebarTitle>{taskTitle(task)}</LightboxSidebarTitle>
        <StaticReviewCopy />
        {shouldSeeSigners && (
          <ReviewSignatures
            currentSigners={currentSigners}
            originalConfiguredSigners={originalConfiguredSigners}
            setCurrentSigners={setCurrentSigners}
            person={person.value}
            task={task}
            responsiblePerson={responsiblePerson}
            disableChangeSigner={
              isRequestingSignature || blobData.tag !== 'Complete'
            }
          />
        )}
        <PrintDownloadButtons blobData={blobData} />
      </LightboxSidebarContent>
      <LightboxSidebarFooter className="flex-col">
        {isTaskIncomplete.tag === 'Complete' && isTaskIncomplete.value && (
          <CompleteFormButton
            person={person.value}
            task={task}
            onClose={onClose}
            history={history}
          />
        )}
        {isAwaitingReview(task) && !isRP && (
          <AsyncIconButton
            className={'mb-[16px]'}
            buttonStyle={'secondary-outline'}
            initialIcon={'fa-pen'}
            onClick={onClickMakeChanges}
          >
            Make Changes
          </AsyncIconButton>
        )}
        {isRP &&
          noReviewTaskWithUnassignedSigners({
            currentSigners,
            task,
          }) && (
            <Warning className={'mb-2'}>
              The community needs to choose signers for the document before you
              can proceed.
            </Warning>
          )}
        <AsyncIconButton
          id={'requestSignatureBtn'}
          data-testid={'requestSignatureBtn'}
          isLoading={isRequestingSignature}
          disabled={requestSignatureDisabled}
          buttonStyle={'primary-fill'}
          initialIcon={'fa-pen-nib'}
          onClick={onClickRequestSignature}
        >
          {ctaText}
        </AsyncIconButton>
      </LightboxSidebarFooter>
    </>
  )
}
