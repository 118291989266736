import { VitalType } from '@augusthealth/models/com/august/protos/vital'
import { Controller, useFormContext } from 'react-hook-form'
import { BadgeSelect } from '@shared/components/Selects/BadgeSelect'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { ValidParameterConditional } from '@shared/types/dosage'
import {
  MedicationOrder,
  MedOrderFormData,
  MedOrderFormDoseVital,
} from '@shared/types/medication_order'
import { DosageType } from '@shared/types/medication_statement'
import { VITAL_SCHEDULE_TYPE_OPTIONS } from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import {
  AllVitalTypes,
  VitalEnumToVitalsType,
  VITALS_INPUT_PROPS_MAP,
} from '@shared/utils/vitals'
import { DynamicFrequencyInputs } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DynamicFrequencyInputs'
import { ScheduleFooter } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/ScheduleFooter'
import { ScheduleHeader } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleHeader'
import { SchedulePartWrapper } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/SchedulePartWrapper'

type Props = {
  order: Partial<MedicationOrder>
  index: number
}
export const VitalOrderSchedule = ({ order, index }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { watch, control } = methods
  const selectedType = watch(`doses.${index}.userSetDosageType`)
  const vitalType = watch('vitalType')
  const vitalProps =
    vitalType &&
    VITALS_INPUT_PROPS_MAP<MedOrderFormDoseVital>(
      VitalEnumToVitalsType(vitalType as VitalType) as AllVitalTypes
    )

  const dropdownName = vitalProps?.dropdownName
    ? (`doses.${index}.vitals.${vitalProps.dropdownName}` as const)
    : null

  const vitalHasDropdown =
    !!vitalProps &&
    !!dropdownName &&
    !!vitalProps.options &&
    !!vitalProps.dropdownLabel
  return (
    <>
      <SchedulePartWrapper>
        <ScheduleHeader
          readOnly={false}
          options={VITAL_SCHEDULE_TYPE_OPTIONS}
          order={order}
          forceSelection={selectedType}
          index={index}
        />
        <DynamicFrequencyInputs index={index} readOnly={false} />
        {vitalHasDropdown && (
          <Controller
            name={dropdownName}
            shouldUnregister={true}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <BadgeSelect
                  isClearable
                  name={dropdownName}
                  badgeLabel={vitalProps.dropdownLabel as string}
                  options={vitalProps.options}
                  placeholder={'Select...'}
                  containerClassName={tw`mt-4`}
                  className={tw`max-w-[280px]`}
                  onChange={(e: OptionTypeBase<ValidParameterConditional>) => {
                    onChange(e?.value ?? null)
                  }}
                  value={
                    vitalProps.options?.find((opt) => opt.value === value) ??
                    null
                  }
                />
              )
            }}
          />
        )}
      </SchedulePartWrapper>
      <ScheduleFooter
        readOnly={false}
        mode={{
          tag: `full-${DosageType.DOSAGE_TYPE_ROUTINE}-Vital`,
          noteName: `doses.${index}.vitals.observationActionNote`,
        }}
      />
    </>
  )
}
