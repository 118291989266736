import { Link } from 'react-router-dom'
import { notesPathForPerson } from '@shared/legacy_routes'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Vital } from '@shared/types/vital'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { displayResidentStatus } from '@shared/utils/person'
import {
  getVitalValueForType,
  VITALS_INPUT_PROPS_MAP,
  VitalsType,
} from '@shared/utils/vitals'
import styles from '../styles.module.css'
import { formatVital } from '../HistoricVitalsModal/helpers'
import { FrontendNote } from './helpers'

export default function NoteBody({
  data,
  includePersonLink,
  incident,
  person,
}: {
  data: FrontendNote
  incident: Incident
  includePersonLink?: boolean
  person: Person
}) {
  const { note, date } = data
  const { vital } = incident

  return (
    <div className="mb-[32px] mt-[32px] text-[14px] font-medium leading-[16px] text-gray-05">
      {includePersonLink && (
        <div className={styles.detailsContainer}>
          <div className={styles.details}>
            <div className="w-[144px] font-semibold uppercase text-gray-06">
              {displayResidentStatus(person)}
            </div>
            <div className="font-medium text-gray-04">
              <Link to={notesPathForPerson(person as Required<Person>)}>
                {getFirstAndLastName(person.name)}
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="whitespace-pre-wrap">
        {note} <span className={'text-gray-07'}>– Serviced on {date}</span>
      </div>
      {vital?.panel && (
        <section className={styles.detailsContainer}>
          {Object.keys(VitalsType).map((type) => {
            const vitalType: VitalsType = VitalsType[type]
            const { iconClassName, label, unitsLabel } =
              VITALS_INPUT_PROPS_MAP(vitalType)
            const { optionalLabel } = formatVital({
              vital,
              activeTab: vitalType,
            })
            return (
              <div key={`nb-vp-${vital?.id}-${type}`}>
                <i className={`${iconClassName} mr-[8px] text-primary-light`} />
                <span className="text-gray-06">{label}: </span>
                <span className="font-semibold text-gray-04">
                  {getVitalValueForType({
                    vitalType,
                    vital: vital as Required<Vital>,
                  })}
                  {unitsLabel === '%' ? unitsLabel : ` ${unitsLabel}`}
                </span>
                {optionalLabel && (
                  <span className="text-gray-03"> ({optionalLabel})</span>
                )}
              </div>
            )
          })}
        </section>
      )}
    </div>
  )
}
